import {
    LoginAction,
    LOGOUT,
    LOGIN_BEGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    SET_LOGIN_TOKEN,
    UNAUTHORIZED,
    ILoginState,
} from '..';

const initialState: ILoginState = {
    isLoggedIn: false,
    isLoginInProgress: false,
};

export function reducer(state: ILoginState = initialState, action: LoginAction): ILoginState {
    switch (action.type) {
        case LOGIN_BEGIN:
            return {
                ...state,
                isLoginInProgress: true,
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                currentUserName: action.currentUserName,
                isLoginInProgress: false,
            };

        case LOGIN_ERROR:
            return {
                ...state,
                errorMessage: action.errorMessage,
                isLoginInProgress: false,
                isLoggedIn: false,
            };

        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                loginToken: undefined,
                currentUserName: undefined,
                errorMessage: undefined,
            };

        case SET_LOGIN_TOKEN:
            return {
                ...state,
                loginToken: action.loginToken,
                isLoggedIn: action.loginToken ? state.isLoggedIn : false,
            };

        case UNAUTHORIZED:
            // noinspection SpellCheckingInspection
            return {
                ...state,
                isLoggedIn: false,
                loginToken: undefined,
                currentUserName: undefined,
                errorMessage: 'Session abgelaufen! Bitte neu Anmelden. (401-Unauthorized)',
            };
    }

    return state;
}
