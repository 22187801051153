import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

type TStyles = {
    aplixLoadingIndicator: string;
};

const styles: TStyles = require('./AplixLoadingIndicator.less');

export const AplixLoadingIndicator = () => (
    <div className={styles.aplixLoadingIndicator}>
        <div>
            <CircularProgress
                variant="indeterminate"
                color="primary"
                style={{ verticalAlign: 'middle', marginRight: 5 }}
            />
            <div>Lade...</div>
        </div>
    </div>
);
