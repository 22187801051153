import {
    FINISH_CONTRACT_BEGIN,
    FINISH_CONTRACT_END,
    FINISH_CONTRACT_ERROR,
    FINISH_CONTRACT_SUCCESS,
    FinishContractAction,
} from '../actions';
import { FinishContractStateStatus, IFinishContractState } from '../state';
import { getQuinoContainer } from '../../../../quino/core/ioc';
import APLIX_SERVICE_IDENTIFIER from '../../../../ioc_constants';
import { IFinishContractReducer } from './IFinishContractReducer';
import { injectable } from 'inversify';

const initialState: IFinishContractState = {
    contractNumber: '',
    status: FinishContractStateStatus.New,
};

@injectable()
export class FinishContractReducer implements IFinishContractReducer {
    reducer(
        state: IFinishContractState = initialState,
        action: FinishContractAction
    ): IFinishContractState {
        switch (action.type) {
            case FINISH_CONTRACT_BEGIN:
                return {
                    ...state,
                    status: FinishContractStateStatus.Fetching,
                    contractNumber: action.payload.contractNumber,
                };

            case FINISH_CONTRACT_ERROR:
                return {
                    ...state,
                    status: FinishContractStateStatus.Error,
                    errorMessage: action.payload.errorMessage,
                };

            case FINISH_CONTRACT_SUCCESS:
                return {
                    ...state,
                    status: FinishContractStateStatus.Success,
                };

            case FINISH_CONTRACT_END:
                return initialState;
        }

        return state;
    }
}

export function getReducer() {
    return getQuinoContainer().get<IFinishContractReducer>(
        APLIX_SERVICE_IDENTIFIER.IFINISHCONTRACTREDUCER
    ).reducer;
}
