/**
 * Describes the possible statuses for contracts.
 */
export enum ContractStatus {
    /**
     * Contract has not been started.
     */
    New = 0,

    /**
     * Contract is not cleared in aplix -> invalid.
     */
    InvalidNotCleared = 1,

    /**
     * Contract is already closed in aplix -> invalid.
     */
    InvalidAlreadyClosed = 2,

    /**
     * Unknown3
     */
    Unknown3 = 3,

    /**
     * Unknown4
     */
    Unknown4 = 4,

    /**
     * Contract has been started.
     */
    InProgress = 5,

    /**
     * Contract has been paused and can be picked up by someone else.
     */
    Paused = 6,

    /**
     * Unknown7
     */
    Unknown7 = 7,

    /**
     * Contract has been completed.
     */
    Prepared = 8,

    /**
     * Contract has been synchronized back to the master ERP.
     */
    SyncedToExternalSystem = 9,
}
