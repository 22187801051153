import { IContract } from '../../../core';
import { getPagesState, IState } from '../../../redux';

export const getStartContractState = (state: IState) => getPagesState(state).startContract;

export const getStartContractErrorMessage = (state: IState) =>
    getStartContractState(state).errorMessage;

export interface IStartContractState {
    isFetching: boolean;
    isReviewing: boolean;
    errorMessage?: string;
    contractToStart?: IContract;
}
