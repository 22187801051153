/* Typed value for <see cref="ContractDetail.Status"/>. */
import { IContractDetailDTO } from '../api/DTOs/IContractDetailDTO';

export enum ContractDetailStatus {
    /**
     * Position "Neu".
     */
    New = 0,

    /*
     * Position teilweise gerüstet.
     */
    PartiallyPrepared = 5,

    /**
     * Item is paused. This means, the item is to be prepared later.
     * This state is used at Hosberg, when a product is currently unavailable.
     */
    Paused = 6,

    /*
     * Position fertig gerüstet.
     */
    Prepared = 8,

    /*
     * Mit ERP synchronisiert.
     */
    SyncedToExternalSystem = 9,
}

export const isDetailCompleted = (contractDetail: IContractDetailDTO) =>
    contractDetail.status === ContractDetailStatus.Prepared ||
    contractDetail.status === ContractDetailStatus.SyncedToExternalSystem ||
    contractDetail.istFakultativ;
