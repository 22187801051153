import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide, { SlideProps } from '@material-ui/core/Slide';

type TStyles = {
    aplixErrorDialogRoot: string;
};

const styles: TStyles = require('./AplixErrorDialog.less');

type TProps = {
    title: string;
    errorMessage?: string;
    children?: any;
    onClose: () => void;
};

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
Transition.displayName = 'slideUpTransition';

// noinspection TsLint
export const AplixErrorDialog = ({ title, errorMessage, onClose, children }: TProps) => {
    return (
        <Dialog
            className={styles.aplixErrorDialogRoot}
            open
            TransitionComponent={Transition}
            onClose={onClose}
            aria-labelledby={title}
            onBackdropClick={onClose}
        >
            <audio src={require('../../assets/warning.mp3')} autoPlay={true} />
            <DialogTitle id="error-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {errorMessage}
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};
