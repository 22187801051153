import * as React from 'react';
import { IScannerService } from '../../core/scanner/IScannerService';

interface IProps {
    onScan: (barcode: string) => void;
    scannerService: IScannerService;
    cheatCodeBarcode?: string;
}

interface IState {
    barcodeBuffer: string;
}

export class BarcodeListener extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            barcodeBuffer: '',
        } as IState;
    }

    componentDidMount() {
        document.addEventListener('keypress', this.handleKeyPress, false);
        document.addEventListener('keydown', this.handleKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keypress', this.handleKeyPress, false);
        document.removeEventListener('keydown', this.handleKeyDown, false);
    }

    private handleKeyDown = (e: KeyboardEvent): void => {
        if (e.keyCode === 0 && this.props.scannerService.enabled()) {
            e.preventDefault();
            // unknown key pressed: i.e torch or camera hardware button
            this.props.scannerService.show(this.props.onScan);
        }
    };

    private handleKeyPress = (e: KeyboardEvent): void => {
        if (e.key === 'Enter') {
            this.handleBarcode();
        } else if (e.key === '!') {
            // Shortcut for debugging/testing: 'scan' first detail
            this.applyCheatCode();
        } else if (e.key !== '') {
            this.setBarcode(this.state.barcodeBuffer + e.key);
        }

        e.preventDefault();
    };

    /**
     * This method is a shortcut (Cheat-Code) for debugging and testing purpose. It is invoked
     * if the user types a '!' on the keyboard while the list of details is shown.
     */
    applyCheatCode() {
        const barcode = this.props.cheatCodeBarcode;
        if (!barcode) {
            console.warn('No cheat code barcode found!');
            return;
        }

        this.setBarcode(barcode);
        this.handleBarcode();
    }

    private handleBarcode() {
        const barcode = this.state.barcodeBuffer;
        this.setBarcode('');
        this.props.onScan(barcode);
    }

    private setBarcode = (barcode: string) => {
        this.setState({ barcodeBuffer: barcode });
    };

    public render() {
        return null;
    }
}
