import * as React from 'react';
import { useContext } from 'react';
import { sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { AplixPage } from '../../../../components';
import { RootPage } from '../../../../redux/globals';
import { switchRootPage } from '../../../../redux/globals/actions';
import { getContinuableContractsState } from '../../../../redux/contracts';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { ContractTable } from '../../../../components/ContractTable/ContractTable';
import { ContinueContractContext } from '../../ContinueContractContext';

export const ContinueContractList: React.FunctionComponent = () => {
    const { goToDetailPage, selectedContract } = useContext(ContinueContractContext);

    const { contracts } = useSelector(getContinuableContractsState);
    const dispatch = useDispatch();

    const goToStartPage = () => dispatch(switchRootPage(RootPage.StartPage));

    const sortedContracts = sortBy(contracts, (contract) => contract.nummer);

    return (
        <AplixPage showHomeButton pageTitle="Laufende Aufträge" onHomeClick={goToStartPage}>
            <ContractTable
                additionalColumns={[
                    {
                        label: 'Fortsetzen',
                        render: function additionalColumn(contract) {
                            return (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => goToDetailPage(contract)}
                                >
                                    <Icon>send</Icon>
                                </Button>
                            );
                        },
                    },
                ]}
                onRowClick={goToDetailPage}
                emptyLabel={'Keine laufenden Aufträge vorhanden.'}
                selectedContract={selectedContract}
                contracts={sortedContracts}
            />
        </AplixPage>
    );
};
