import { AppInsights } from 'applicationinsights-js';
import { LogLevel } from '.';
import { IApplicationInsightsLogger } from './IApplicationInsightsLogger';
import { injectable } from 'inversify';

@injectable()
export class ApplicationInsightsLogger implements IApplicationInsightsLogger {
    log(level: LogLevel, name: string, message?: string, properties?: {}, exception?: Error): void {
        switch (level) {
            case LogLevel.Verbose:
                AppInsights.trackTrace(
                    message ? message : '',
                    properties,
                    AI.SeverityLevel.Verbose
                );
                break;
            case LogLevel.Info:
                AppInsights.trackTrace(
                    message ? message : '',
                    properties,
                    AI.SeverityLevel.Information
                );
                break;
            case LogLevel.Event:
                AppInsights.trackEvent(name, properties);
                break;
            case LogLevel.Warning:
                AppInsights.trackTrace(
                    message ? message : '',
                    properties,
                    AI.SeverityLevel.Warning
                );
                break;
            case LogLevel.Error:
                AppInsights.trackTrace(message ? message : '', properties, AI.SeverityLevel.Error);
                break;
            case LogLevel.Critical:
                AppInsights.trackTrace(
                    message ? message : '',
                    properties,
                    AI.SeverityLevel.Critical
                );
                break;
        }

        if (exception) {
            AppInsights.trackException(exception);
        }
    }
}
