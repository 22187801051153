import * as React from 'react';
import Button from '@material-ui/core/Button';

type TStyles = {
    updateAvailableInfoRoot: string;
};

const styles: TStyles = require('./UpdateAvailableInfo.less');

export type TProps = {
    currentVersion: string;
    newVersion: string;
    onUpdate: () => void;
};

// noinspection TsLint
export const UpdateAvailableInfo = ({ currentVersion, newVersion, onUpdate }: TProps) => {
    return (
        <div className={styles.updateAvailableInfoRoot}>
            <div>
                Update von Version <span>{currentVersion}</span> auf <span>{newVersion}</span>{' '}
                verfügbar. Bitte updaten Sie möglichst bald.
            </div>
            <Button onClick={onUpdate} variant="contained" size="large" style={{ float: 'right' }}>
                Update!
            </Button>
        </div>
    );
};
