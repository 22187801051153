import * as React from 'react';
import { useEffect, useState } from 'react';
import * as fromActions from './redux/actions';
import * as fromLogic from './redux/logic';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IState } from '../../redux';
import { IContract } from '../../core';
import { getStartContractState, StartContractAction } from './redux';
import { AplixPage, ContractInformation } from '../../components';
import { GlobalsAction, RootPage, switchRootPage } from '../../redux/globals';
import { ContractStarting } from './components';
import { LoginAction, TPrimaryKey } from '../../quino';
import { ThunkDispatch } from 'redux-thunk';
import { ContractSelector } from './components/ContractSelector';
import { noop } from 'lodash';

type TStyles = {
    startContractPageRoot: string;
    startContractPageForm: string;
    startContractPageContractNumber: string;
    startContractPageSubmit: string;
};

const styles: TStyles = require('./StartContractPage.less');

export type TOwnProps = {};

type TStateProps = {
    isFetching: boolean;
    isReviewing: boolean;
    errorMessage?: string;
    contractToStart?: IContract;
};

type TDispatchProps = {
    startContractInit: () => void;
    startContractStartReview: (contractNumber: string) => void;
    startContractError: (message: string) => void;
    startContractCancelReview: () => void;
    switchRootPage: (targetPage: RootPage) => void;
    startContract: (contractId: TPrimaryKey) => void;
    startContractGoBackToReview: () => void;
};

export type TProps = TOwnProps & TStateProps & TDispatchProps;

const StartContractPageComponent: React.FunctionComponent<TProps> = (props: TProps) => {
    const {
        isReviewing,
        errorMessage,
        contractToStart,
        isFetching,
        startContractCancelReview,
    } = props;

    const [backButtonCallback, setBackButtonCallback] = useState<() => () => void>(() => noop);

    useEffect(() => {
        props.startContractInit();
    }, []);

    const navigateToHome = () => {
        const { switchRootPage, isFetching } = props;

        if (isFetching) {
            return;
        }

        switchRootPage(RootPage.StartPage);
    };

    const handleOnGoBackWhileStarting = (): void => {
        const { startContractGoBackToReview, isFetching } = props;
        startContractGoBackToReview && !isFetching && startContractGoBackToReview();
    };

    const navigateBack = () => {
        const { startContractCancelReview, isFetching, errorMessage } = props;

        if (isFetching) {
            return;
        }

        if (errorMessage) {
            handleOnGoBackWhileStarting();
        } else if (isReviewing) {
            startContractCancelReview();
        } else {
            backButtonCallback();
        }
    };

    const reviewContract = (contractNumber: string) => {
        const { startContractStartReview, startContractError } = props;

        try {
            startContractStartReview && startContractStartReview(contractNumber);
        } catch (ex) {
            const message = ex && ex.message ? ex.message : ex ? ex : 'Ungültige Auftragsnummer!';
            startContractError && startContractError(message);
            console.warn(`Error validating contract number: ${message}`);
        }
    };

    const handleOnConfirm = (): void => {
        const { startContract, contractToStart, isFetching } = props;

        if (isFetching) {
            return;
        }

        if (!contractToStart || !contractToStart.primaryKey) {
            throw new Error(
                'No "primaryKey" set yet! PrimaryKey is required to start the contract.'
            );
        }

        startContract && startContract(contractToStart.primaryKey);
    };

    return (
        <AplixPage
            showBackButton={true}
            pageTitle="Neuen Auftrag starten"
            onBackClick={() => (!isReviewing ? navigateBack() : startContractCancelReview())}
        >
            <div className={styles.startContractPageRoot}>
                <ContractSelector
                    onSubmit={reviewContract}
                    registerOnBackButtonClick={(callback) => setBackButtonCallback(() => callback)}
                    navigateToHome={navigateToHome}
                    visible={!isReviewing}
                />

                {isReviewing && !isFetching && !errorMessage && contractToStart && (
                    <ContractInformation
                        contract={contractToStart}
                        confirmLabel={'Starten'}
                        onConfirm={handleOnConfirm}
                    />
                )}

                {isReviewing && (isFetching || errorMessage) && (
                    <ContractStarting
                        contractNumber={contractToStart!.nummer}
                        isFetching={isFetching}
                        errorMessage={errorMessage}
                        onGoBack={handleOnGoBackWhileStarting}
                    />
                )}
            </div>
        </AplixPage>
    );
};

export function mapStateToProps(state: IState): TStateProps {
    const startContractState = getStartContractState(state);

    return {
        errorMessage: startContractState.errorMessage,
        isFetching: startContractState.isFetching,
        isReviewing: startContractState.isReviewing,
        contractToStart: startContractState.contractToStart,
    };
}

export function mapDispatchToProps(
    dispatch: ThunkDispatch<IState, Dispatch, StartContractAction | GlobalsAction | LoginAction>
): TDispatchProps {
    return {
        startContractInit: () => dispatch(fromActions.startContractInit()),
        startContractStartReview: (contractNumber: string) =>
            dispatch(fromActions.startContractStartReview(contractNumber)),
        startContractError: (message: string) => dispatch(fromActions.startContractError(message)),
        startContractCancelReview: () => dispatch(fromActions.startContractCancelReview()),
        switchRootPage: (targetPage: RootPage) => dispatch(switchRootPage(targetPage)),
        startContract: (contractId: string) => dispatch(fromLogic.startContractAsync(contractId)),
        startContractGoBackToReview: () => dispatch(fromActions.startContractGoBackToReview()),
    };
}

export const StartContractPage = connect<TStateProps, TDispatchProps, TOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(StartContractPageComponent);
