import * as React from 'react';
import Typography from '@material-ui/core/Typography';

export type TProps = {
    pageTitle: string;
};

export const AplixPageTitle = (props: TProps) => (
    <Typography variant="h6" color="primary">
        {props.pageTitle}
    </Typography>
);
