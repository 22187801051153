import { IContract } from '../../../../core/index';

export type StartContractAction =
    | StartContractInit
    | StartContractStartReview
    | StartContractCancelReview
    | StartContractError
    | StartContractPostBegin
    | StartContractPostSuccess
    | StartContractGoBackToReview;

export const STARTCONTRACT_INIT = 'STARTCONTRACT_INIT';
export type STARTCONTRACT_INIT = typeof STARTCONTRACT_INIT;
export type StartContractInit = {
    type: STARTCONTRACT_INIT;
};

export const STARTCONTRACT_START_REVIEW = 'STARTCONTRACT_START_REVIEW';
export type STARTCONTRACT_START_REVIEW = typeof STARTCONTRACT_START_REVIEW;
export type StartContractStartReview = {
    type: STARTCONTRACT_START_REVIEW;
    contract: IContract;
};

export const STARTCONTRACT_CANCEL_REVIEW = 'STARTCONTRACT_CANCEL_REVIEW';
export type STARTCONTRACT_CANCEL_REVIEW = typeof STARTCONTRACT_CANCEL_REVIEW;
export type StartContractCancelReview = {
    type: STARTCONTRACT_CANCEL_REVIEW;
};

export const STARTCONTRACT_ERROR = 'STARTCONTRACT_ERROR';
export type STARTCONTRACT_ERROR = typeof STARTCONTRACT_ERROR;
export type StartContractError = {
    type: STARTCONTRACT_ERROR;
    message: string;
};

export const STARTCONTRACT_POST_BEGIN = 'STARTCONTRACT_POST_BEGIN';
export type STARTCONTRACT_POST_BEGIN = typeof STARTCONTRACT_POST_BEGIN;
export type StartContractPostBegin = {
    type: STARTCONTRACT_POST_BEGIN;
};

export const STARTCONTRACT_POST_SUCCESS = 'STARTCONTRACT_POST_SUCCESS';
export type STARTCONTRACT_POST_SUCCESS = typeof STARTCONTRACT_POST_SUCCESS;
export type StartContractPostSuccess = {
    type: STARTCONTRACT_POST_SUCCESS;
};

export const STARTCONTRACT_GOBACK_TO_REVIEW = 'STARTCONTRACT_GOBACK_TO_REVIEW';
export type STARTCONTRACT_GOBACK_TO_REVIEW = typeof STARTCONTRACT_GOBACK_TO_REVIEW;
export type StartContractGoBackToReview = {
    type: STARTCONTRACT_GOBACK_TO_REVIEW;
};
