import { getQuinoContainer } from '../../quino/core/ioc';
import { ILogger } from './ILogger';
import APLIX_SERVICE_IDENTIFIER from '../../ioc_constants';

export { ILogger } from './ILogger';
export { AplixLogger } from './AplixLogger';
export { LogLevel } from './LogLevel';

export const getLogger = (): ILogger =>
    getQuinoContainer().get<ILogger>(APLIX_SERVICE_IDENTIFIER.ILOGGER);
