import { IContractDetail, QuantityGrade } from '../../../../../../logic';
import { inject, injectable } from 'inversify';
import { IQuantityGradeViewHelper } from './IQuantityGradeViewHelper';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../../../ioc_constants';
import { IEditDetailStoreQueries } from '../../../../../../logic/IEditDetailStoreQueries';

interface IStyles {
    quantityGradeNotAcceptable: string;
    quantityGradeOk: string;
    quantityGradePerfect: string;
}

const styles: IStyles = {
    ...require('./QuantityGrade.less'),
};

@injectable()
export class QuantityGradeViewHelper implements IQuantityGradeViewHelper {
    constructor(
        @inject(APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREQUERIES)
        private detailStoreQueries: IEditDetailStoreQueries
    ) {}

    getGradeClassName(detailStore: IContractDetail): string {
        switch (this.detailStoreQueries.getQuantityGrade(detailStore)) {
            case QuantityGrade.Perfect:
                return styles.quantityGradePerfect;
            case QuantityGrade.Ok:
                return styles.quantityGradeOk;
            default:
                return styles.quantityGradeNotAcceptable;
        }
    }
}
