const SERVICE_IDENTIFIER = {
    IQUINOSERVERSERVICECONFIGURATION: Symbol.for('IQuinoServerServiceConfiguration'),
    IFETCHER: Symbol.for('IFetcher'),
    IURLMANAGER: Symbol.for('IUrlManager'),
    IDATASERVICE: Symbol.for('IDataService'),
    IMETADATASERVICE: Symbol.for('IMetadataService'),
    IAPIMANAGER: Symbol.for('IApiManager'),
    IAUTHENTICATIONTOKENPROVIDER: Symbol.for('IAuthenticationProvider'),
    IQUINOENABLEDSTORE: Symbol.for('IQuinoEnabledStore'),
    ISTORAGE: Symbol.for('IStorage'),
    ILOCALSTORAGESERVICE: Symbol.for('ILocalStorageService'),
    MARKDOWNHTMLRENDERER: Symbol.for('MarkdownHtmlRenderer'),
    IMARKDOWNCONVERTER: Symbol.for('IMarkdownConverter')
};

// tslint:disable-next-line:no-default-export
export default SERVICE_IDENTIFIER;
