import { IGlobalState } from '../index';
import { combineReducers } from 'redux';
import { globalUiReducer } from './globalUiReducer';
import { serverInfoReducer } from './serverInfoReducer';
import { currentUserInfoReducer } from './currentUserInfoReducer';
import { updateReducer } from './updateReducer';
import {bookmarkReducer} from "./bookmarkReducer";

export const reducer = combineReducers<IGlobalState>({
    globalUiState: globalUiReducer,
    serverInfo: serverInfoReducer,
    currentUserInfo: currentUserInfoReducer,
    update: updateReducer,
    bookmark: bookmarkReducer
});
