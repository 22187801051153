import * as React from 'react';
import { FormEvent, useEffect, useState } from 'react';
import { AplixInput } from '../../../../components';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { useSelector } from 'react-redux';
import { getFirstStartableContract } from '../../../../redux/contracts';
import { getStartContractErrorMessage } from '../../redux';
import Box from '@material-ui/core/Box';

type TStyles = {
    startContractPageForm: string;
    startContractPageContractNumber: string;
    startContractPageSendIcon: string;
    starContractPageManualButton: string;
};

const styles: TStyles = require('./ContractInputForm.less');

interface IProps {
    onSubmit: (contractNumber: string) => void;
    showContractList: () => void;
    registerOnBackButtonClick: (callback: () => void) => void;
    navigateToHome: () => void;
    visible: boolean;
}

export const ContractInputForm = ({
    onSubmit,
    showContractList,
    registerOnBackButtonClick,
    navigateToHome,
    visible,
}: IProps) => {
    const [contractNumber, setContractNumber] = useState('');

    const firstStartableContract = useSelector(getFirstStartableContract);
    const errorMessage = useSelector(getStartContractErrorMessage);

    useEffect(() => {
        visible && registerOnBackButtonClick(navigateToHome);
    }, [navigateToHome, visible]);

    const handleSubmit = (e: FormEvent<any>) => {
        e.preventDefault();
        onSubmit(contractNumber);
    };

    const handleChangeContractNumber = (event: any) => {
        const value = event.target.value;

        if (value === '!' && firstStartableContract) {
            setContractNumber(firstStartableContract.barcode);
        } else {
            setContractNumber(value);
        }
    };

    if (!visible) {
        return <></>;
    }

    return (
        <Box display={'flex'} flexDirection={'column'} height={1}>
            <form className={styles.startContractPageForm} onSubmit={handleSubmit}>
                <div className={styles.startContractPageContractNumber}>
                    <AplixInput
                        id="contractNumber"
                        label="Auftragsnummer"
                        autoFocus
                        required
                        showBarCodeAdornment
                        showKeyboardAdornment
                        value={contractNumber}
                        fullWidth
                        errorMessage={errorMessage}
                        helpMessage="Auftragsnummer scannen oder eingeben"
                        onChange={handleChangeContractNumber}
                    />
                </div>

                <Button
                    color="primary"
                    variant="contained"
                    disabled={!contractNumber || contractNumber.length < 1}
                    onClick={() => onSubmit(contractNumber)}
                >
                    Auftrag starten
                    <Icon className={styles.startContractPageSendIcon}>send</Icon>
                </Button>
            </form>

            <Box flexGrow={1} />

            <Button
                className={styles.starContractPageManualButton}
                variant={'contained'}
                onClick={showContractList}
            >
                Manuell wählen
            </Button>
        </Box>
    );
};
