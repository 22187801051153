import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from './scenes';
import { IPrepareContractContext, PrepareContractContext } from './PrepareContractContext';
import { IContractDetail } from './logic';
import { EditDetailWizard } from './scenes/EditDetail/EditDetailWizard';
import { IContractProperty } from '../../core/entities/IContractProperty';
import { EditContractPropertiesPage } from './scenes/EditProperty/EditContractPropertiesPage';
import { switchRootPage } from '../../redux/globals/actions';
import { RootPage } from '../../redux/globals';
import { IState } from '../../redux';
import { LoadContractPage } from './scenes/LoadContractPage';
import { FinishContractPage } from './scenes/FinishContractPage';
import { fetchDetailsAsync, saveDetailAsync } from '../../redux/contracts/logic';
import { PrepareContractErrors } from './scenes/PrepareContractErrors';
import { EditPropertiesPage } from './scenes/EditDetail/EditPropertiesPage';
import { EditDetailContext } from './scenes/EditDetail/EditDetailContext';
import { getService } from '../../ioc_config';
import { IEditDetailStoreActions } from './logic/IEditDetailStoreActions';
import APLIX_SERVICE_IDENTIFIER from '../../ioc_constants';
import { hasEmptyRequiredProperties } from './scenes/List/utils';

enum SubPage {
    Loading,
    PropertiesFirstRound,
    List,
    EditDetail,
    PropertiesSecondRound,
    Saving,
    Reloading,
    ProductProperties,
}

export const PrepareContractPage: React.FunctionComponent = () => {
    const [subPage, setSubPage] = useState<SubPage>(SubPage.Loading);
    const [detailStore, setDetailStore] = useState<IContractDetail | null>(null);
    const [index, setIndex] = useState<number>(0);
    const [showAllContractDetailsEnabled, setShowAllContractDetailsEnabled] = useState<boolean>(true);

    const detailStoreActions = getService<IEditDetailStoreActions>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREACTIONS
    );

    const dispatch = useDispatch();

    const saveDetailStore = (detailStore: IContractDetail) => {
        dispatch(saveDetailAsync(detailStore));
    };

    useEffect(() => {
        if (detailStore && subPage === SubPage.List && !hasEmptyRequiredProperties(detailStore)) {
            saveDetailStore(detailStoreActions.forceAutoAccept(detailStore));
        }
    }, [detailStore]);

    const properties = useSelector<IState, IContractProperty[]>(
        (state) => state.contracts.current.properties
    );

    const goToRootPage = () => {
        dispatch(switchRootPage(RootPage.StartPage));
    };

    const goToListPage = () => {
        setDetailStore(null);
        setSubPage(SubPage.List);
    };

    const goToEditPropertiesPage = (detailStore: IContractDetail) => {
        setDetailStore(detailStore);
        setSubPage(SubPage.ProductProperties);
    };

    const goToDetailPage = (detailStore: IContractDetail) => {
        setDetailStore(detailStore);
        setSubPage(SubPage.EditDetail);
    };

    const setDetailScrollIndex = (index: number) => {
        setIndex(index);
    }
    
    const reload = () => {
        dispatch(fetchDetailsAsync(true));
        setSubPage(SubPage.Reloading);
    };

    const hasProperties = properties.length > 0;

    const getNextStep = (subPage: SubPage): SubPage => {
        switch (subPage) {
            case SubPage.Loading:
                return hasProperties
                    ? SubPage.PropertiesFirstRound
                    : getNextStep(SubPage.PropertiesFirstRound);
            case SubPage.PropertiesFirstRound:
            case SubPage.Reloading:
                return SubPage.List;
            case SubPage.List:
            case SubPage.EditDetail:
                return hasProperties
                    ? SubPage.PropertiesSecondRound
                    : getNextStep(SubPage.PropertiesSecondRound);
            case SubPage.PropertiesSecondRound:
                return SubPage.Saving;
            case SubPage.Saving:
                return SubPage.Saving;
            case SubPage.ProductProperties:
                return SubPage.List;
        }
    };

    const goToNextStep = () => {
        setSubPage((subPage) => getNextStep(subPage));
    };

    const prepareContractContext: IPrepareContractContext = {
        goToEditPropertiesPage: goToEditPropertiesPage,
        saveDetailStore: saveDetailStore,
        goToNextStep: goToNextStep,
        goToDetailPage: goToDetailPage,
        goToListPage: goToListPage,
        detailScrollIndex: index,
        setDetailScrollIndex: setDetailScrollIndex,
        reload: reload,
        showAllContractDetailsEnabled: showAllContractDetailsEnabled,
        setShowAllContractDetailsEnabled: setShowAllContractDetailsEnabled,
    };

    const renderSubPage = () => {
        switch (subPage) {
            case SubPage.Loading:
            case SubPage.Reloading:
                return <LoadContractPage />;
            case SubPage.PropertiesFirstRound:
                return (
                    <EditContractPropertiesPage goBack={goToRootPage} validateRequired={false} />
                );
            case SubPage.EditDetail:
                if (!detailStore) {
                    throw new Error('DetailStore must be set in EditDetailPage');
                }
                return <EditDetailWizard detailStore={detailStore} />;
            case SubPage.List:
                return <List />;
            case SubPage.PropertiesSecondRound:
                return <EditContractPropertiesPage goBack={goToListPage} validateRequired={true} />;
            case SubPage.Saving:
                return <FinishContractPage />;
            case SubPage.ProductProperties:
                if (!detailStore) {
                    throw new Error('DetailStore must be set in ProductProperties');
                }
                return (
                    <EditDetailContext.Provider value={[detailStore, setDetailStore]}>
                        <EditPropertiesPage
                            goToPreviousStep={goToListPage}
                            goToNextStep={goToNextStep}
                            validateRequired={true}
                        />
                    </EditDetailContext.Provider>
                );
        }
    };

    return (
        <PrepareContractContext.Provider value={prepareContractContext}>
            <PrepareContractErrors />
            {renderSubPage()}
        </PrepareContractContext.Provider>
    );
};
