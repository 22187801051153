import * as React from 'react';
import { StartMenuItem } from '..';
import AccountTree from '@material-ui/icons/AccountTree';
import CameraIcon from '@material-ui/icons/Camera';
import FastForwardIcon from '@material-ui/icons/FastForward';

type TStyles = {
    startmenuRoot: string;
};

const styles: TStyles = require('./StartMenu.less');

export type TProps = {
    canStartNewContract: boolean;
    hasCurrentContract: boolean;
    canContinueContract: boolean;
    isFetching: boolean;
    countStartableContracts?: number;
    countContinuableContracts?: number;
    onScanContractClick: () => void;
    onContinueContractClick: () => void;
    onChooseContractClick: () => void;
};

export const StartMenu = (props: TProps) => {
    const {
        canStartNewContract,
        hasCurrentContract,
        canContinueContract,
        isFetching,
        countStartableContracts,
        countContinuableContracts,
        onScanContractClick,
        onContinueContractClick,
        onChooseContractClick,
    } = props;

    const isStartHighlighted = canStartNewContract && !hasCurrentContract && !canContinueContract;
    const isCurrentHighlighted = hasCurrentContract;
    const isContinueHighlighted = canContinueContract && !hasCurrentContract;

    return (
        <div className={styles.startmenuRoot}>
            <StartMenuItem
                title="Scannen"
                id={'startmenu-new-contract'}
                icon={CameraIcon}
                disabled={!canStartNewContract || isFetching}
                badgeNumber={countStartableContracts}
                highlighted={isStartHighlighted}
                onClick={onScanContractClick}
            />
            <StartMenuItem
                title="Auswählen"
                id={'startmenu-current-contract'}
                icon={AccountTree}
                disabled={!canStartNewContract || isFetching}
                highlighted={isCurrentHighlighted}
                onClick={onChooseContractClick}
            />
            <StartMenuItem
                title="In Arbeit"
                id={'startmenu-continue-contract'}
                icon={FastForwardIcon}
                disabled={!canContinueContract || isFetching}
                badgeNumber={countContinuableContracts}
                highlighted={isContinueHighlighted}
                onClick={onContinueContractClick}
            />
        </div>
    );
};
