import { getStartableContractsState, IStartableContractsState } from '../state';
import { getLogger, IContract, LogLevel } from '../../../core';
import { IState } from '../../store';
import { apiManager } from '../../../quino/core';
import { TDispatchableReturn } from '../../../quino/core/redux';
import {
    ContractsAction,
    fetchStartableContractsBegin,
    fetchStartableContractsError,
    fetchStartableContractsSuccess,
} from '../actions';

/**
 * Fetching all startable contracts from the server.
 */
export function fetchStartableContract(
    forceFetch?: boolean
): TDispatchableReturn<ContractsAction, IState> {
    return async function(dispatch, getState) {
        const state = getStartableContractsState(getState());

        if (state.isFetching || (state.isLoaded && !forceFetch)) {
            return Promise.resolve();
        }

        dispatch(fetchStartableContractsBegin());
        try {
            const result = await apiManager().dataService.getListAsync<IContract[]>(
                'contract',
                'NewContracts'
            );
            dispatch(fetchStartableContractsSuccess(result));
        } catch (ex) {
            getLogger().log(LogLevel.Error, 'fetchStartableContract', ex.message, null, ex);
            dispatch(fetchStartableContractsError(ex.message));
        }
    };
}

/**
 * Get's a startable IContract by its barcode.
 */
export const getStartableContractByBarcode = (
    state: IStartableContractsState,
    barcode?: string
): IContract => {
    // TODO Create Unit-Tests for this

    if (!barcode) {
        const message = 'Keine Auftragsnummer/-code gescannt oder eingegeben!';
        getLogger().log(LogLevel.Warning, 'getStartableContractByBarcodeContract', message);
        throw new Error(message);
    }

    if (!state.contracts) {
        const message = `Es gibt keine Aufträge, welche gestartet werden können!`;
        getLogger().log(LogLevel.Warning, 'getStartableContractByBarcodeContract', message);
        throw new Error(message);
    }

    const matchingContracts = state.contracts.filter((c) => c.barcode === barcode);

    if (matchingContracts.length < 1) {
        const message = `Es konnte kein Auftrag mit dem Code ${barcode} gefunden werden!`;
        getLogger().log(LogLevel.Warning, 'getStartableContractByBarcodeContract', message);
        throw new Error(message);
    }

    if (matchingContracts.length > 1) {
        const message = `Es wurden mehrere Aufträge mit dem Code ${barcode} gefunden! Auftrag nicht eindeutig.`;
        getLogger().log(LogLevel.Warning, 'getStartableContractByBarcodeContract', message);
        throw new Error(message);
    }

    return matchingContracts[0];
};
