import * as React from 'react';
import { ReactElement } from 'react';
import { IContract } from '../../core/entities';

export interface IAdditionalContractTableColumn {
    label: string;
    render: (contract: IContract) => ReactElement;
}

export interface IContractTableContext {
    selectedContract: IContract | null;
    onRowClick: (contract: IContract) => void;
    additionalColumns: IAdditionalContractTableColumn[];
    emptyLabel: string;
}

export const ContractTableContext = React.createContext<IContractTableContext>(
    {} as IContractTableContext
);
