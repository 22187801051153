/**
 * Determines, for which detail barcodes to listen
 */
export enum ScannableDetails {
    /**
     * Listen to barcodes of all details
     */
    All = 0,

    /**
     * Only listen to barcodes of not yet prepared items
     */
    Unprepared = 1,

    /**
     * Only listen to the barcode of the first item in the list
     */
    First = 2,
}
