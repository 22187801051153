import * as React from 'react';
import { useContext, useState } from 'react';
import { EditDetailContext } from './EditDetailContext';
import { useService } from '../../../../ioc/hook/useService';
import { IEditDetailStoreActions } from '../../logic/IEditDetailStoreActions';
import APLIX_SERVICE_IDENTIFIER from '../../../../ioc_constants';
import { IPlace } from '../../../../core/entities/IPlace';
import { EditPlaceDetails } from './components/EditPlaces/EditPlaceDetails';
import { ListPlaces } from './components/EditPlaces/ListPlaces';
import { EditPlaceAmount } from './components/EditPlaces/EditPlaceAmount';
import { useEffect } from 'react';

interface IProps extends IWizardStepProps {}

enum SubPage {
    List,
    EditPlaceDetails,
    EditPlaceAmount,
}

export const EditPlacesPage: React.FunctionComponent<IProps> = ({
    goToPreviousStep,
    goToNextStep,
}) => {
    const detailStoreActions = useService<IEditDetailStoreActions>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREACTIONS
    );

    const [detailStore, setDetailStore] = useContext(EditDetailContext);
    const [currentPage, setCurrentPage] = useState<SubPage>(SubPage.List);
    const [selectedPlaceId, setSelectedPlaceId] = useState<string>();
    const [currentPlace, setCurrentPlace] = useState<Partial<IPlace>>({});

    useEffect(() => {
        if (currentPage === SubPage.List) {
            setSelectedPlaceId(undefined);
        }
    }, [currentPage]);

    const openEditor = (placeId?: string) => {
        const place: Partial<IPlace> = detailStore.places.find((p) => p.placeId === placeId) || {
            placeId,
        };
        setSelectedPlaceId(placeId);
        setCurrentPlace(place);
        if (placeId) {
            setCurrentPage(SubPage.EditPlaceAmount);
        } else {
            setCurrentPage(SubPage.EditPlaceDetails);
        }
    };

    const savePlaceDetails = (updatedPlace: Partial<IPlace>) => {
        setCurrentPlace(updatedPlace);
        setCurrentPage(SubPage.EditPlaceAmount);
    };

    const savePlaceAmount = (place: IPlace) => {
        if (selectedPlaceId && selectedPlaceId !== place.placeId) {
            deletePlace(selectedPlaceId);
        }
        setDetailStore((detailStore) => detailStoreActions.savePlace(detailStore, place));
        setCurrentPage(SubPage.List);
    };

    const deletePlace = (placeId: string) => {
        setDetailStore((detailStore) => detailStoreActions.deletePlace(detailStore, placeId));
    };

    switch (currentPage) {
        case SubPage.EditPlaceDetails:
            return (
                <EditPlaceDetails
                    goBack={() => setCurrentPage(SubPage.List)}
                    place={currentPlace}
                    save={savePlaceDetails}
                />
            );
        case SubPage.EditPlaceAmount:
            return (
                <EditPlaceAmount
                    goBack={() => setCurrentPage(SubPage.List)}
                    place={currentPlace}
                    save={savePlaceAmount}
                />
            );
        case SubPage.List:
            return (
                <ListPlaces
                    goBack={goToPreviousStep}
                    openEditor={openEditor}
                    submit={goToNextStep}
                    deletePlace={deletePlace}
                />
            );
    }
};
