import { IState } from '../store';
import { TPrimaryKey } from '../../quino/core/data';
import { ISaveContractDetailDTO } from '../../core/api/DTOs/ISaveContractDetailDTO';

export interface ISaveDetailState {
    [primaryKey: string]: {
        pendingRequest?: ISaveDetailRequest;
        queuedRequest?: ISaveDetailRequest;
        failedRequest?: IFailedSaveDetailRequest;
    };
}

export interface ISaveDetailRequest {
    primaryKey: TPrimaryKey;
    model: ISaveContractDetailDTO;
}

export interface IFailedSaveDetailRequest extends ISaveDetailRequest {
    errorMessage: string;
}

/**
 * @param appState
 * @param primaryKey
 * @returns true, if there is a pending request to save the detail with the given primaryKey
 */
export const isSavingDetail = (appState: IState, primaryKey: TPrimaryKey): boolean => {
    const saveDetailState = getSaveDetailState(appState, primaryKey);
    return !!(saveDetailState && saveDetailState.pendingRequest);
};

/**
 * @param appState
 * @param primaryKey
 * @returns true, if there no queued request to save the detail with the given primaryKey
 */
export const isSaveDetailQueueEmpty = (appState: IState, primaryKey: TPrimaryKey): boolean => {
    const saveDetailState = getSaveDetailState(appState, primaryKey);
    return !(saveDetailState && saveDetailState.queuedRequest);
};

/**
 * @param appState
 * @param primaryKey
 * @returns true, if all save requests have been processed for the detail with the given primary key
 */
export const isDoneSaving = (appState: IState, primaryKey: TPrimaryKey): boolean => {
    const saveDetailState = getSaveDetailState(appState, primaryKey);
    return (
        !saveDetailState ||
        (!saveDetailState.queuedRequest &&
            !saveDetailState.pendingRequest &&
            !saveDetailState.failedRequest)
    );
};

/**
 * @param appState
 * @returns true, if all save requests have been processed for all details
 */
export const isDoneSavingForAll = (appState: IState): boolean => {
    const primaryKeys = Object.keys(appState.contracts.current.saveDetail);
    return primaryKeys.every((primaryKey) => isDoneSaving(appState, primaryKey));
};

export const getFirstSaveDetailError = (state: IState): IFailedSaveDetailRequest | undefined => {
    const saveDetailStates = Object.values(state.contracts.current.saveDetail);
    const result = saveDetailStates.find((saveDetailState) => !!saveDetailState.failedRequest);
    return result && result.failedRequest;
};

/**
 * @param appState
 * @param primaryKey
 * @returns the DTO of the pending save request for the detail with the given primaryKey
 *  or undefined, if there is no pending request
 */
export const getPendingSaveDetailRequest = (
    appState: IState,
    primaryKey: TPrimaryKey
): ISaveDetailRequest | undefined => {
    const saveDetailState = getSaveDetailState(appState, primaryKey);
    return saveDetailState && saveDetailState.pendingRequest;
};

const getSaveDetailState = (appState: IState, primaryKey: TPrimaryKey) =>
    appState.contracts.current.saveDetail[primaryKey];
