import { IEditPropertyProps } from './IEditPropertyProps';
import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import { AplixTimePicker } from '../../../../components/AplixPickers/AplixTimePicker';
import { EditValueGrid } from './EditValueGrid';
import moment from 'moment';

export const EditTimeValue: FunctionComponent<IEditPropertyProps> = ({
    save,
    property,
    validateRequired,
}) => {
    const aplixTimeFormat = 'HH:mm';

    const [value, setValue] = useState<moment.Moment | null>(
        (property.value && moment(property.value, aplixTimeFormat)) || null
    );

    const isValid = !validateRequired || !property.isRequired || (!!value && value.isValid());

    const submit = () => {
        save(value && value.isValid() ? value.format(aplixTimeFormat) : null);
    };

    return (
        <EditValueGrid save={submit} isValid={isValid} property={property}>
            <AplixTimePicker
                label={property.label}
                value={value}
                autoFocus
                required={validateRequired && property.isRequired}
                onChange={setValue}
            />
        </EditValueGrid>
    );
};
