import { inject, injectable } from 'inversify';
import { finishContractBegin, finishContractEnd, finishContractError, finishContractSuccess, } from '../actions';
import { switchRootPage } from '../../../../redux/globals/actions';
import { IState } from '../../../../redux';
import { AnyAction, Store } from 'redux';
import APLIX_SERVICE_IDENTIFIER from '../../../../ioc_constants';
import { IFinishContractService } from './IFinishContractService';
import { IAplixService } from '../../../../core';
import { ICurrentContractService } from '../../../../core/logic';
import { finishCurrentContract } from '../../../../redux/contracts/actions';
import { fetchContinuableContract, fetchStartableContract, } from '../../../../redux/contracts/logic';
import { RootPage } from '../../../../redux/globals';

@injectable()
export class FinishContractService implements IFinishContractService {
    constructor(
        @inject(APLIX_SERVICE_IDENTIFIER.STORE) private store: Store<IState>,
        @inject(APLIX_SERVICE_IDENTIFIER.IAPLIXSERVICE) private aplixService: IAplixService,
        @inject(APLIX_SERVICE_IDENTIFIER.ICURRENTCONTRACTSERVICE)
        private currentContractService: ICurrentContractService
    ) {
    }

    checkForOpenDetails(): boolean {
        const openDetails = this.currentContractService.getCurrentContractDetailsNotDone();
        return openDetails !== null && openDetails.length > 0;
    }
    async finishContractAsync(): Promise<any> {
        this.dispatch(switchRootPage(RootPage.FinishContractPage));

        const primaryKey = this.currentContractService.getPrimaryKey();

        if (primaryKey === null) {
            this.dispatch(finishContractError('Kein aktueller Auftrag vorhanden'));
            return;
        }

        this.dispatch(finishContractBegin(this.currentContractService.getContractNumber()));

        try {
            await this.aplixService.finishContractAsync(primaryKey);
            this.dispatch(fetchStartableContract(true) as any);
            this.dispatch(fetchContinuableContract(true) as any);
            this.dispatch(finishContractSuccess());
            this.dispatch(finishCurrentContract());
        } catch (response) {
            this.dispatch(
                finishContractError(response || 'Ein unbekannter Fehler ist aufgetreten.')
            );
        }
    }

    endFinishContract() {
        this.dispatch(switchRootPage(this.store.getState().globals.bookmark.lastActivePage));
        this.dispatch(finishContractEnd());
    }

    private dispatch(action: AnyAction) {
        this.store.dispatch(action);
    }
}
