import { QuantityGrade } from '../../pages/PrepareContractPage/logic/types';
import { IQuantityHelper } from './IQuantityHelper';
import { injectable } from 'inversify';

@injectable()
export class QuantityHelper implements IQuantityHelper {
    private static readonly QUANTITY_OK_WINDOW = 0.1;

    getBundleCount(currentQuantity: number, bundleSize: number): number {
        return Math.trunc(Math.max(0, currentQuantity) / QuantityHelper.getBundleSize(bundleSize));
    }

    getBundleRemainder(currentQuantity: number, bundleSize: number): number {
        return Math.max(0, currentQuantity) % QuantityHelper.getBundleSize(bundleSize);
    }

    getQuantityGrade(
        currentQuantity: number,
        targetQuantity: number,
        allowedDeviation: number
    ): QuantityGrade {
        if (Math.abs(currentQuantity - targetQuantity) <= allowedDeviation) {
            return QuantityGrade.Perfect;
        }

        if (QuantityHelper.isWithinOkWindow(currentQuantity, targetQuantity)) {
            return QuantityGrade.Ok;
        }

        return QuantityGrade.NotAcceptable;
    }

    private static getBundleSize(bundleSize: number): number {
        return Math.max(bundleSize, 1);
    }

    private static isWithinOkWindow(totalCurrentQty: number, targetQuantity: number): boolean {
        const delta = Math.abs(totalCurrentQty - targetQuantity);
        const maxDelta = this.QUANTITY_OK_WINDOW * targetQuantity;
        return delta <= maxDelta;
    }
}
