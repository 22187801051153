import * as React from 'react';
import { useContext } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { AplixPage } from '../../../../components';
import { EditDetailContext } from './EditDetailContext';

interface IProps extends IWizardStepProps {}

export const ReallyQuitPage: React.FunctionComponent<IProps> = ({
    goToPreviousStep,
    goToNextStep,
}) => {
    const [{ articleNumber }] = useContext(EditDetailContext);

    return (
        <AplixPage
            pageTitle={`Artikel "${articleNumber}": Bearbeiten`}
            onBackClick={goToPreviousStep}
        >
            <div>
                <h1>Prozess verlassen?</h1>
                <p>
                    Wenn Sie den Prozess verlassen, werden die Angaben zum aktuellen Artikel nicht
                    gespeichert. Sind Sie sicher?
                </p>
                <Box display={'inline'} mr={2}>
                    <Button variant="contained" onClick={goToNextStep}>
                        Bearbeitung forzsetzen
                    </Button>
                </Box>
                <Button variant="contained" color="primary" onClick={goToPreviousStep}>
                    Prozess verlassen
                </Button>
            </div>
        </AplixPage>
    );
};
