import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui//icons/Home';
import BackIcon from '@material-ui/icons/ArrowBack';
import { AplixPageTitle } from '..';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

export type TProps = {
    pageTitle: string;
    showHomeButton?: boolean;
    showBackButton?: boolean;
    showLoadingIndicator?: boolean;
    children?: any;
    onHomeClick?: () => void;
    onBackClick?: () => void;
};

export const AplixPageHeader = ({
    pageTitle,
    showHomeButton,
    showBackButton,
    showLoadingIndicator,
    children,
    onHomeClick,
    onBackClick,
}: TProps) => (
    <Box m={-1} mb={2}>
        <AppBar id="AppBar" position="static" color="default">
            <Toolbar>
                {showHomeButton && (
                    <IconButton
                        id={'home-button'}
                        style={{ marginLeft: -12, marginRight: 20 }}
                        color="inherit"
                        aria-label="Home"
                        onClick={onHomeClick}
                    >
                        <HomeIcon />
                    </IconButton>
                )}
                {showBackButton && (
                    <IconButton
                        id={'back-button'}
                        style={{ marginLeft: -12, marginRight: 20 }}
                        color="inherit"
                        aria-label="Back"
                        onClick={onBackClick}
                    >
                        <BackIcon />
                    </IconButton>
                )}
                <AplixPageTitle pageTitle={pageTitle} />
                {children}
            </Toolbar>
        </AppBar>
        {showLoadingIndicator && <LinearProgress />}
    </Box>
);
