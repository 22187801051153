import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { ContractStatus, IContract } from '../../../../core/entities';
import { ContractTableContext } from '../../ContractTableContext';
import moment from 'moment';

type TStyles = {
    listRowNowrap: string;
};

const styles: TStyles = require('./ListRow.less');

export type TProps = {
    contract: IContract;
    selected: boolean;
    selectRow: () => void;
};

export const ListRow: React.FunctionComponent<TProps> = ({ selected, selectRow, contract }) => {
    const scrollToElement = useRef<HTMLTableRowElement>(null);

    useEffect(() => {
        if (scrollToElement.current && selected) {
            scrollToElement.current.scrollIntoView({ block: 'center' });
        }
    }, []);

    const { additionalColumns } = useContext(ContractTableContext);
    const lieferDatum = moment(contract.lieferdatum).isValid()
        ? moment(contract.lieferdatum).format('DD.MM.YYYY, HH:mm')
        : '';

    const contractStatusToColor = (): string => {
        switch (contract.status) {
            case ContractStatus.New:
                return 'none';
            case ContractStatus.InvalidNotCleared:
            case ContractStatus.InvalidAlreadyClosed:
                return 'lightcoral';
            case ContractStatus.Unknown3:
            case ContractStatus.Unknown4:
            case ContractStatus.Unknown7:
                return 'lightgray';
            case ContractStatus.InProgress:
                return 'lightblue';
            case ContractStatus.Paused:
                return 'lightyellow';
            default:
            case ContractStatus.Prepared:
            case ContractStatus.SyncedToExternalSystem:
                return 'lightgreen';
        }
    };

    return (
        <TableRow
            selected={selected}
            onClick={selectRow}
            ref={scrollToElement}
            style={{ backgroundColor: contractStatusToColor() }}
        >
            <TableCell className={styles.listRowNowrap}>{contract.nummer}</TableCell>
            <TableCell>{contract.kunde}</TableCell>
            <TableCell className={styles.listRowNowrap}>
                {contract.region} {contract.lagerplatz}
            </TableCell>
            <TableCell className={styles.listRowNowrap}>{lieferDatum}</TableCell>
            {additionalColumns.map((column, id) => (
                <TableCell key={id}>{column.render(contract)}</TableCell>
            ))}
        </TableRow>
    );
};
