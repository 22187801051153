import { getPagesState, IState } from '../../../redux';

export const getFinishContractState = (state: IState) => getPagesState(state).finishContract;

export enum FinishContractStateStatus {
    New,
    Fetching,
    Success,
    Error,
}

export interface IFinishContractState {
    status: FinishContractStateStatus;
    errorMessage?: string;
    contractNumber: string;
}
