import { IState } from '../../store';
import {
    fetchContinuableContractsBegin,
    fetchContinuableContractsError,
    fetchContinuableContractsSuccess,
} from '../actions';
import { getContinuableContractsState } from '../state';
import { apiManager } from '../../../quino/core';
import { TDispatchableReturn } from '../../../quino/core/redux';
import { ContractsAction } from '../actions';
import { IContract } from '../../../core/entities';
import { getLogger, LogLevel } from '../../../core/logging';

/**
 * Fetching all continuable contracts from the server.
 */
export function fetchContinuableContract(
    forceFetch?: boolean
): TDispatchableReturn<ContractsAction, IState> {
    return async function(dispatch, getState) {
        const state = getContinuableContractsState(getState());

        if (state.isFetching || (state.isLoaded && !forceFetch)) {
            return Promise.resolve();
        }

        dispatch(fetchContinuableContractsBegin());
        try {
            const result = await apiManager().dataService.getListAsync<IContract[]>(
                'contract',
                'PendingContracts'
            );

            dispatch(fetchContinuableContractsSuccess(result));
        } catch (ex) {
            getLogger().log(LogLevel.Error, 'fetchContinuableContract', ex.message, null, ex);
            dispatch(fetchContinuableContractsError(ex.message));
        }
    };
}
