import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { EditPropertiesWizard } from './EditPropertiesWizard';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../../redux';
import { IContractProperty } from '../../../../core/entities/IContractProperty';
import { PrepareContractContext } from '../../PrepareContractContext';
import {
    isSavingCurrentContract,
    updateContractPropertiesAsync,
} from '../../../../redux/contracts';
import { AplixPage } from '../../../../components';

interface IProps {
    goBack: () => void;
    validateRequired: boolean;
}

export const EditContractPropertiesPage: React.FunctionComponent<IProps> = ({
    goBack,
    validateRequired,
}) => {
    const dispatch = useDispatch();
    const { goToNextStep } = useContext(PrepareContractContext);
    const isSaving = useSelector(isSavingCurrentContract);
    const [isSavingInitiated, setIsSavingInitiated] = useState(false);

    const save = (properties: IContractProperty[]) => {
        dispatch(updateContractPropertiesAsync(properties));
    };

    useEffect(() => {
        if (isSaving) {
            setIsSavingInitiated(true);
        }
        if (isSavingInitiated && !isSaving) {
            goToNextStep();
        }
    }, [isSavingInitiated, isSaving]);

    const properties = useSelector<IState, IContractProperty[]>(
        (state) => state.contracts.current.properties
    );

    const contractNumber = useSelector<IState, string>(
        (state) => state.contracts.current.contract!.nummer
    );

    const pageTitle = `Auftrag "${contractNumber}": Eigenschaften bearbeiten`;

    if (isSavingInitiated) {
        return <AplixPage pageTitle={pageTitle}>Speichere ...</AplixPage>;
    }

    return (
        <EditPropertiesWizard
            save={save}
            goBack={goBack}
            pageTitle={pageTitle}
            properties={properties}
            validateRequired={validateRequired}
        />
    );
};
