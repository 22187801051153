import * as React from 'react';
import { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import { AplixPage, BarcodeListener } from '../../../../../../components';
import { EditDetailContext } from '../../EditDetailContext';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { TableHead } from '@material-ui/core';
import moment from 'moment';
import TableBody from '@material-ui/core/TableBody';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useService } from '../../../../../../ioc/hook/useService';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../ioc_constants';
import { IEditDetailStoreQueries } from '../../../../logic/IEditDetailStoreQueries';
import { getService } from '../../../../../../ioc_config';
import { IScannerService } from '../../../../../../core/scanner/IScannerService';

interface IStyles {
    listLotsTableContainer: string;
}

const styles: IStyles = require('./ListLots.less');

interface IProps {
    goBack: () => void;
    openEditor: (lotNumber?: string) => void;
    submit: () => void;
    deleteLot: (lotNumber: string) => void;
}

export const ListLots: React.FunctionComponent<IProps> = ({
    openEditor,
    goBack,
    submit,
    deleteLot,
}) => {
    const [detailStore] = useContext(EditDetailContext);

    const detailStoreQueries = useService<IEditDetailStoreQueries>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREQUERIES
    );
    const scannerService = getService<IScannerService>(APLIX_SERVICE_IDENTIFIER.ISCANNERSERVICE);

    const lotsAmount = detailStoreQueries.getLotsAmount(detailStore);
    const remainingAmount = detailStoreQueries.roundAmount(
        detailStore,
        detailStore.currentQuantity - lotsAmount
    );
    const amountMatches = detailStore.currentQuantity === lotsAmount;

    const { articleNumber, lots, unit, currentQuantity } = detailStore;

    const onScan = (barcode: string) => {
        const lotNumber = String(barcode);
        if (lotNumber != null && lotNumber != '') {
            return openEditor(lotNumber);
        }
    };

    return (
        <AplixPage
            showBackButton
            pageTitle={`Artikel "${articleNumber}": Chargen bearbeiten`}
            onBackClick={goBack}
        >
            <BarcodeListener onScan={onScan} scannerService={scannerService} />
            <Box
                display={'flex'}
                flexGrow={1}
                className={styles.listLotsTableContainer}
                flexDirection={'column'}
                width={1}
                style={{ overflowY: 'auto' }}
            >
                <Table>
                    <TableHead key={'header'}>
                        <TableRow>
                            <TableCell size="small">Nummer</TableCell>
                            <TableCell size="small">Menge</TableCell>
                            <TableCell size="small">Ablaufdatum</TableCell>
                            <TableCell size="small" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lots.map((lot) => (
                            <TableRow key={lot.number} onClick={() => openEditor(lot.number)}>
                                <TableCell>{lot.number}</TableCell>
                                <TableCell>
                                    {lot.amount} {unit}
                                </TableCell>
                                <TableCell>{moment(lot.expiryDate).format('DD.MM.YYYY')}</TableCell>
                                <TableCell align={'right'}>
                                    <Box display={'flex'} justifyContent={'flex-end'}>
                                        <Button
                                            variant="contained"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                deleteLot(lot.number);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                        <Box ml={1}>
                                            <Button variant="contained">
                                                <EditIcon />
                                            </Button>
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            <Box mt={1}>
                {!amountMatches && (
                    <Box mb={1} color={'text.hint'} textAlign={'right'}>
                        Gesamtmenge aller Chargen:{' '}
                        <strong>
                            {lotsAmount} {unit}
                        </strong>
                        . Soll:{' '}
                        <strong>
                            {currentQuantity} {unit}
                        </strong>
                        . Differenz:{' '}
                        <strong>
                            {remainingAmount} {unit}
                        </strong>
                    </Box>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button fullWidth variant={'contained'} onClick={() => openEditor()}>
                            Charge erfassen
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant={'contained'}
                            color={'primary'}
                            onClick={submit}
                            disabled={!amountMatches}
                            id={'edit-lots-submit'}
                        >
                            Weiter
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </AplixPage>
    );
};
