import { combineReducers } from 'redux';
import * as fromStartable from './startable';
import * as fromContinuable from './continuable';
import * as fromCurrent from './current';
import { IContractState } from '../state';

export const reducer = combineReducers<IContractState>({
    startable: fromStartable.reducer,
    continuable: fromContinuable.reducer,
    current: fromCurrent.reducer,
});
