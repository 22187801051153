import { IState } from '../../../../redux';
import { TPrimaryKey } from '../../../../quino/core/data';
import { aplixApiManager } from '../../../../core';
import {
    getStartContractState,
    startContractError,
    startContractPostBegin,
    startContractPostSuccess,
} from '..';
import { openContract } from '../../../../redux/contracts/logic';
import { TAplixAction } from '../../../../redux/store';
import { ThunkAction } from 'redux-thunk';

export function startContractAsync(
    contractId: TPrimaryKey
): ThunkAction<void, IState, {}, TAplixAction> {
    return async function(dispatch, getState) {
        const state = getState();
        const startContractState = getStartContractState(state);

        if (startContractState.isFetching) {
            return Promise.resolve();
        }

        dispatch(startContractPostBegin());

        try {
            const result = await aplixApiManager().aplixService.startContractAsync(contractId);

            if (result) {
                dispatch(startContractPostSuccess());

                const contract = startContractState.contractToStart;
                if (contract) {
                    dispatch(openContract(contract));
                }
            } else {
                dispatch(startContractError(`Unbekannter Fehler beim Starten des Auftrages!`));
                console.warn(`Unknown error while starting contract with primaryKey ${contractId}`);
            }
        } catch (ex) {
            dispatch(
                startContractError(
                    'Auftrag kann nicht gestartet werden! Evtl. wurde er in der Zwischenzeit bereits von jemand anderem gestartet.'
                )
            );
        }
    };
}
