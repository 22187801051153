import * as React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { User } from '../../../../core/entities';

export type TProps = {
    currentUser: User;
};

type TStyles = {
    startpageInfoLabel: string;
};

const styles: TStyles = require('../../StartPage.less');

export const LoggedInUser = function({ currentUser }: TProps) {
    const userDisplayName = currentUser.getDisplayName();

    return (
        <div className={styles.startpageInfoLabel}>
            <AccountCircleIcon style={{ verticalAlign: 'middle' }} titleAccess={userDisplayName} />
            <div style={{ verticalAlign: 'middle' }}>
                <span>{userDisplayName}</span>
            </div>
        </div>
    );
};
