import { IContractDetailDTO } from '../../entities';
import { IAmounts } from './IAmounts';
import { injectable } from 'inversify';
import { IBarcodeParserStrategy } from './IBarcodeParserStrategy';

@injectable()
export class WeightStrategy implements IBarcodeParserStrategy {
    parseBarcode(
        readBarcode: string,
        _: IContractDetailDTO,
        detailBarcode?: string
    ): IAmounts | null {
        const matchWeightBarcode = /0*(\d+)G.*/.exec(detailBarcode || '');

        if (!matchWeightBarcode) {
            return null;
        }

        const productCode = matchWeightBarcode[1];

        if (!readBarcode.startsWith(productCode)) {
            return null;
        }

        const productWeightDivisor = 10 ** 3;
        const amount =
            (parseInt(readBarcode.slice(productCode.length, -1), 10) || 0) / productWeightDivisor;

        return {
            amount: amount,
            factor: 1,
        };
    }
}
