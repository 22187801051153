import { ICurrentContractService } from './ICurrentContractService';
import { inject, injectable } from 'inversify';
import APLIX_SERVICE_IDENTIFIER from '../../ioc_constants';
import { Store } from 'redux';
import { IState } from '../../redux';
import { getCurrentContractState } from '../../redux/contracts';
import { TPrimaryKey } from '../../quino/core/data';
import { IContractDetailDTO, isDetailCompleted } from '../entities';

@injectable()
export class CurrentContractService implements ICurrentContractService {
    constructor(@inject(APLIX_SERVICE_IDENTIFIER.STORE) private store: Store<IState>) {}

    getPrimaryKey() {
        const currentContract = this.getCurrentContract();
        if (currentContract && currentContract.primaryKey) {
            return currentContract.primaryKey;
        } else {
            return null;
        }
    }

    getContractNumber(): string {
        const currentContract = this.getCurrentContract();
        if (currentContract) {
            return currentContract.nummer;
        } else {
            return '';
        }
    }

    getContractDetailByPrimaryKey(primaryKey: TPrimaryKey): IContractDetailDTO | null {
        const matchingDetails = this.queryCurrentContractDetails(
            (d) => d.primaryKey === primaryKey
        );
        if (!matchingDetails || matchingDetails.length < 1) {
            return null;
        }

        return matchingDetails[0];
    }

    getCurrentContractDetailsNotDone(): IContractDetailDTO[] | null {
        const matchingDetails = this.queryCurrentContractDetails(
            (d) => !isDetailCompleted(d)
        );
        if (!matchingDetails || matchingDetails.length < 1) {
            return null;
        }

        return matchingDetails;
    }

    private getCurrentContract() {
        return getCurrentContractState(this.store.getState()).contract;
    }

    private queryCurrentContractDetails(
        predicate: (value: IContractDetailDTO) => boolean
    ): IContractDetailDTO[] | null {
        const state = this.store.getState();
        const contractState = getCurrentContractState(state);
        if (!contractState.details) {
            return null;
        }

        const matchingDetails = contractState.details.filter(predicate);
        if (!matchingDetails || matchingDetails.length < 1) {
            return null;
        }

        return matchingDetails;
    }
}
