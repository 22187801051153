import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../redux';
import { PrepareContractContext } from '../PrepareContractContext';
import { AplixPage } from '../../../components';
import Typography from '@material-ui/core/Typography';
import { switchRootPage } from '../../../redux/globals/actions';
import { RootPage } from '../../../redux/globals';
import { isFetchingCurrentContract } from '../../../redux/contracts';
import Button from '@material-ui/core/Button';

export const LoadContractPage: React.FunctionComponent = () => {
    const { goToNextStep, reload } = useContext(PrepareContractContext);

    const dispatch = useDispatch();

    const isLoaded = useSelector<IState, boolean>((state) => state.contracts.current.isLoaded);
    const isFetching = useSelector(isFetchingCurrentContract);

    useEffect(() => {
        if (isLoaded) {
            goToNextStep();
        }
    }, [isLoaded]);

    return (
        <AplixPage
            pageTitle={`Auftrag: Laden`}
            showHomeButton
            onHomeClick={() => dispatch(switchRootPage(RootPage.StartPage))}
            showLoadingIndicator={isFetching}
        >
            <div>
                {isFetching ? (
                    <Typography color={'primary'}>Lade Auftrag ...</Typography>
                ) : (
                    <Button variant={'contained'} onClick={reload}>
                        Neu laden
                    </Button>
                )}
            </div>
        </AplixPage>
    );
};
