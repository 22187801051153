import { DataAction, IDataState, RECEIVE_VIEW_AND_DATA, REQUEST_VIEW_AND_DATA } from '..';

const initialState: IDataState = {
    entityName: '',
    viewName: '',
    isFetchingView: false,
    isLoaded: false,
    lastUpdated: 0,
};

export function reducer(state: IDataState = initialState, action: DataAction): IDataState {
    switch (action.type) {
        case REQUEST_VIEW_AND_DATA:
            return {
                ...state,
                isFetchingView: true,
                isLoaded: false,
            };
        case RECEIVE_VIEW_AND_DATA:
            return {
                ...state,
                isFetchingView: false,
                isLoaded: true,
                entityName: action.entityName,
                viewName: action.viewName,
                data: action.data,
                view: action.view,
                lastUpdated: action.receivedAt,
            };
    }

    return state;
}
