import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './App';
import { configureIocContainer } from './ioc_config';
import { AppInsights } from 'applicationinsights-js';
import * as ScanditSDK from 'scandit-sdk';
import 'whatwg-fetch';

/* Load polyfill */
require('es6-promise/auto');

AppInsights.downloadAndSetup!({
    instrumentationKey: (window as any).aplixConfig.instrumentationKey,
});

configureIocContainer();

try {
    ScanditSDK.configure((window as any).aplixConfig.scanditLicenseKey, {
        engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk/build/',
    }).finally(() => {
        ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
    });
} catch {
    // NOP
}
