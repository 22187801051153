import { getLoginState, LoginAction, loginBegin, loginError, loginSuccess } from '../../../';
import { apiManager } from '../../../../core';
import { fetchCurrentUserInfoAsync } from '../../../../../redux/globals/logic';
import { ThunkAction } from 'redux-thunk';
import { IState } from '../../../../../redux';

export function loginAsync(
    userName: string,
    password: string
): ThunkAction<void, IState, {}, LoginAction> {
    return async function(dispatch, getState) {
        const loginState = getLoginState(getState());

        if (
            loginState.isLoginInProgress ||
            (loginState.currentUserName && userName === loginState.currentUserName)
        ) {
            return;
        }

        dispatch(loginBegin());
        try {
            await apiManager().metadataService.logInAsync(userName, password);
            dispatch(loginSuccess(userName));
            dispatch(fetchCurrentUserInfoAsync(true));
        } catch (ex) {
            let msg = 'Ungültige Anmeldung!';
            if (ex && ex.message) {
                msg = ex.message;
            }

            dispatch(loginError(msg));
        }
    };
}
