import * as React from 'react';

type TStyles = {
    aplixlogoRoot: string;
    aplixlogoImage: string;
};

const styles: TStyles = require('./AplixLogo.less');

const logo = require('../../assets/logo.png');

export const AplixLogo = () => (
    <div className={styles.aplixlogoRoot}>
        <img src={logo} alt="Aplix-Logo" className={styles.aplixlogoImage} />
    </div>
);
