import * as React from 'react';
import { useContext } from 'react';
import { BarcodeListener } from '../../../../../components';
import { IEditDetailBarcodeListener } from '../../../logic';
import APLIX_SERVICE_IDENTIFIER from '../../../../../ioc_constants';
import { useService } from '../../../../../ioc/hook/useService';
import { EditDetailContext } from '../EditDetailContext';
import { getService } from '../../../../../ioc_config';
import { IEditDetailStoreActions } from '../../../logic/IEditDetailStoreActions';
import { IScannerService } from '../../../../../core/scanner/IScannerService';

interface IProps {
    showError(message: string): void;
    switchToEditMode(): void;
}

export const EditModeBarcodeHandler: React.FunctionComponent<IProps> = (props) => {
    const barcodeHandler = useService<IEditDetailBarcodeListener>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILBARCODELISTENER
    );
    const scannerService = getService<IScannerService>(APLIX_SERVICE_IDENTIFIER.ISCANNERSERVICE);

    const [detailStore, setDetailStore] = useContext(EditDetailContext);
    const storeActions = getService<IEditDetailStoreActions>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREACTIONS
    );

    const scanAmount = (amount: number) => {
        setDetailStore({
            ...storeActions.incrementQuantity(detailStore, amount),
            lastScannedAmount: amount,
        });
    };

    const scanCallbacks = {
        ...props,
        scanAmount,
    };

    const onScan = (barcode: string) => {
        barcodeHandler.onScan(detailStore, barcode, scanCallbacks);
    };

    return <BarcodeListener onScan={onScan} scannerService={scannerService} />;
};
