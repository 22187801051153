import UndoIcon from '@material-ui/icons/Undo'
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import SignatureCanvas from "react-signature-canvas";

interface IProps {
    label: string;
    value: string | null;
    required: boolean;
    onChange: (image: string | null) => void;
}

type TStyles = {
    aplixSignaturePadRoot: string;
};

const styles: TStyles = require('./AplixSignaturePad.less');

export const AplixSignaturePad: React.FunctionComponent<IProps> = (props) => {
    const {onChange, value, label, required,} = props;

    const [image, setImage] = useState<string | null>(value);
    const sigCanvas = useRef<SignatureCanvas>(null);

    const clearCanvas = () => {
        sigCanvas.current && sigCanvas.current.clear();
    }

    const clearImageAndCanvas = () => {
        setImage(null);
        clearCanvas();
    };

    const saveImage = () => {
        if (sigCanvas.current && !sigCanvas.current.isEmpty())
            setImage(sigCanvas.current.toDataURL());
    }

    useEffect(() => onChange(image), [image]);
    useEffect(() => {
        if (sigCanvas.current && image)
            sigCanvas.current.fromDataURL(image, {ratio: 1});
    }, []);

    return <div className={styles.aplixSignaturePadRoot}>
        {label}{required ? '*' : ''}
        <div className={'signature-pad-container'}>
            <SignatureCanvas
                ref={sigCanvas}
                onEnd={saveImage}
                canvasProps={{
                    className: "signatureCanvas",
                    width: 520,
                    height: 200,
                }}
            />
            <div className={'signature-pad-button-container'}>
                <AplixSignaturePadButton onClick={() => clearImageAndCanvas()}>
                    <UndoIcon/>
                </AplixSignaturePadButton>
            </div>
        </div>
    </div>;
}

interface IAplixImagePickerButtonProps {
    children?: any;
    onClick: () => void;
}

const AplixSignaturePadButton: React.FunctionComponent<IAplixImagePickerButtonProps> = (props) => {
    return (
        <Button
            type="submit"
            variant="outlined"
            color="primary"
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
}