import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';

interface IProps {
    show: boolean;
    message: string;
    onConfirm: () => void;
    onClose: () => void;
}

function Transition(props: any) {
    return <Slide direction="up" {...props} />;
}

export const AmountWarningDialogDialog = ({ show, message, onConfirm, onClose }: IProps) => {
    return (
        <Dialog
            open={show}
            TransitionComponent={Transition}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">{'Wollen Sie fortfahren?'}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} color="secondary" autoFocus variant="contained">
                    Fortfahren
                </Button>
                <Button onClick={onClose} color="primary" autoFocus variant="contained">
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
    );
};
