import * as React from 'react';
import {connect} from 'react-redux';
import {getLoginState} from '../../quino/redux';
import {FinishContractPage, LoginPage, PrepareContractPage, StartContractPage, StartPage,} from '..';
import {getGlobalUiState, RootPage} from '../../redux/globals';
import {IState} from '../../redux';
import {ContinueContractPage} from '../ContinueContractPage/ContinueContractPage';
import {AppInsights} from 'applicationinsights-js';
import {ScanContractPage} from '../ScanContractPage';
import {ChooseContractPage} from '../ChooseContractPage';

type TOwnProps = {};

type TStateProps = {
    isLoggedIn: boolean;
    rootPage: RootPage;
};

type TProps = TOwnProps & TStateProps;

class PageSelectorComponent extends React.Component<TProps> {
    render() {
        const { isLoggedIn, rootPage } = this.props;

        if (!isLoggedIn) {
            AppInsights.trackPageView('LoginPage');
            return <LoginPage />;
        }

        switch (rootPage) {
            case RootPage.StartContractPage:
                AppInsights.trackPageView('StartContractPage');
                return <StartContractPage />;
            case RootPage.ContinueContractPage:
                AppInsights.trackPageView('ContinueContractPage');
                return <ContinueContractPage />;
            case RootPage.PrepareContractPage:
                AppInsights.trackPageView('PrepareContractPage');
                return <PrepareContractPage />;
            case RootPage.FinishContractPage:
                AppInsights.trackPageView('FinishContractPage');
                return <FinishContractPage />;
            case RootPage.ScanContractPage:
                AppInsights.trackPageView('ScanContractPage');
                return <ScanContractPage />;
            case RootPage.ChooseContractPage:
                AppInsights.trackPageView('ChooseContractPage');
                return <ChooseContractPage />;
            case RootPage.StartPage:
            default:
                AppInsights.trackPageView('StartPage');
                return <StartPage />;
        }
    }
}

export function mapStateToProps(state: IState) {
    return {
        isLoggedIn: getLoginState(state).isLoggedIn,
        rootPage: getGlobalUiState(state).rootPage,
    };
}

export const PageSelector = connect<TStateProps, null, TOwnProps>(mapStateToProps)(
    PageSelectorComponent
);
