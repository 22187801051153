import { inject, injectable } from 'inversify';
import { IAuthenticationTokenProvider, IFetcher } from '.';
import SERVICE_IDENTIFIER from '../../ioc/constants/identifiers';
import { Store } from 'redux';
import { IQuinoEnabledStore, unauthorized } from '../../../redux';

@injectable()
export class Fetcher implements IFetcher {
    constructor(
        @inject(SERVICE_IDENTIFIER.IAUTHENTICATIONTOKENPROVIDER)
        private authTokenProvider: IAuthenticationTokenProvider,
        @inject(SERVICE_IDENTIFIER.IQUINOENABLEDSTORE) private store: Store<IQuinoEnabledStore>
    ) {
    }

    public async fetchAsync<TResult>(url: string): Promise<TResult> {
        this.checkTimestamp();

        return await fetch(url, {
            cache: 'no-cache',
            headers: this.CreateCustomHeaders(),
        })
            .then((response) => (response.ok ? response.json() : this.handleFetchError(response)))
            .catch((reason) => Promise.reject(reason));
    }

    public async postAsync<TResult>(url: string, data: any): Promise<TResult> {
        return await this.sendToServerAsync<TResult>('POST', url, data);
    }

    public async postWithDynamicResponseAsync<TResult>(
        url: string,
        data: any,
        handleResponse: (r: Response) => Promise<any>
    ): Promise<TResult> {
        return await this.internalSendToServerAsync('POST', url, data, handleResponse);
    }

    public async patchAsync<TResult>(url: string, data: any): Promise<TResult> {
        return await this.sendToServerAsync<TResult>('PATCH', url, data);
    }

    private async sendToServerAsync<TResult>(
        method: string,
        url: string,
        data: any
    ): Promise<TResult> {
        return this.internalSendToServerAsync(method, url, data, (r) => r.json());
    }

    private async internalSendToServerAsync<TResult>(
        method: string,
        url: string,
        data: any,
        handleResponse: (r: Response) => Promise<any>
    ): Promise<TResult> {
        this.checkTimestamp();

        return await fetch(url, {
            body: JSON.stringify(data),
            cache: 'no-cache',
            headers: this.CreateCustomHeaders(),
            method,
            mode: 'cors',
            redirect: 'follow',
            referrer: 'no-referrer',
        })
            .then((response) =>
                response.ok ? handleResponse(response) : this.handleFetchError(response)
            )
            .catch((reason) => Promise.reject(reason));
    }

    private async handleFetchError(response: any): Promise<object> {
        if (response.status === 401) {
            // The authorization is probably expired and therefore we need to re-login
            this.store.dispatch(unauthorized());
        }

        let body = '';
        try {
            body = await response.text();
        } catch {
            if (response.statusText) {
                return Promise.reject(response.statusText);
            }

            return Promise.reject(response);
        }

        try {
            const jsonResult = JSON.parse(body);
            if (jsonResult.message) {
                return Promise.reject(jsonResult.message);
            }
        } catch {
            // NOP
        }

        return Promise.reject(body === '' ? 'Unbekannter Fehler beim Speichern des Auftrages' : body);
    }

    private CreateCustomHeaders(): Headers {
        const headers = new Headers();
        this.authTokenProvider.applyAuthHeaders(headers);

        // NOTE: We must make sure Internet Explorer does not cache the fetch requests.
        headers.append('pragma', 'no-cache');
        headers.append('cache-control', 'no-cache');
        headers.append('cache', 'no-cache');
        headers.append('content-type', 'application/json');

        return headers;
    }

    private static timeoutId: any;

    private checkTimestamp = (): void => {
        window.clearTimeout(Fetcher.timeoutId);

        const loginTimeoutMS = (window as any).aplixConfig.loginTimeoutMS;
        if (loginTimeoutMS > 0) {
            Fetcher.timeoutId = window.setTimeout(() => {
                this.authTokenProvider.unsetToken();
                window.document.location.reload();
            }, loginTimeoutMS);
        }
    };
}
