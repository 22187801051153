import * as React from 'react';
import { AplixPageHeader } from '..';
import Box from '@material-ui/core/Box';

export type TProps = {
    pageTitle: string;
    children?: any;
    showHomeButton?: boolean;
    showBackButton?: boolean;
    showLoadingIndicator?: boolean;
    advancedHeaderElement?: React.ReactNode;
    onHomeClick?: () => void;
    onBackClick?: () => void;
};

export const AplixPage = ({
    pageTitle,
    children,
    showHomeButton,
    showBackButton,
    showLoadingIndicator,
    onHomeClick,
    onBackClick,
    advancedHeaderElement,
}: TProps) => (
    <Box flexDirection={'column'} display={'flex'} flexGrow={1} width={1} m={1}>
        <AplixPageHeader
            pageTitle={pageTitle}
            showHomeButton={showHomeButton}
            showBackButton={showBackButton}
            onHomeClick={onHomeClick}
            onBackClick={onBackClick}
            showLoadingIndicator={showLoadingIndicator}
        >
            {advancedHeaderElement}
        </AplixPageHeader>

        {children}
    </Box>
);
