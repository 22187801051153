import { IEditPropertyProps } from './IEditPropertyProps';
import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import { AplixDatePicker } from '../../../../components/AplixPickers/AplixDatePicker';
import { EditValueGrid } from './EditValueGrid';
import moment, { Moment } from 'moment';

export const EditDateValue: FunctionComponent<IEditPropertyProps> = ({
    save,
    property,
    validateRequired,
}) => {
    const aplixDateFormat = 'DD.MM.YYYY';

    const [value, setValue] = useState<Moment | null>(
        (property.value && moment(property.value, aplixDateFormat)) || null
    );

    const isValid = !validateRequired || !property.isRequired || (!!value && value.isValid());

    const submit = () => {
        save(value && value.isValid() ? value.format(aplixDateFormat) : null);
    };

    return (
        <EditValueGrid save={submit} isValid={isValid} property={property}>
            <AplixDatePicker
                label={property.label}
                value={value}
                required={validateRequired && property.isRequired}
                onChange={setValue}
                autoFocus
            />
        </EditValueGrid>
    );
};
