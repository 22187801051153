import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export type TProps = {
    clientVersion?: string;
    serverVersion?: string;
    database?: string;
    onCloseDialog: () => void;
};

const renderListItem = (name: string, value?: string) => (
    <ListItem>
        <Grid container>
            <Grid item xs={8}>
                <Typography component="span" variant="body1">
                    {name}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography component="span" variant="body2" color="textSecondary">
                    {value}
                </Typography>
            </Grid>
        </Grid>
    </ListItem>
);

// noinspection TsLint
export const InfoDialog = ({ clientVersion, serverVersion, onCloseDialog, database }: TProps) => {
    return (
        <Dialog
            open
            onClose={onCloseDialog}
            aria-labelledby="application-information"
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle id="application-information">Info</DialogTitle>
            <DialogContent>
                <List>
                    {renderListItem('Client-Version', clientVersion)}
                    {renderListItem('Server-Version', serverVersion)}
                    {renderListItem('Datenbank', database)}
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onCloseDialog}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};
