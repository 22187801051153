import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CameraType } from 'react-camera-pro/dist/components/Camera/types';
import { Camera } from 'react-camera-pro';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/Camera';
import BackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import { BrokenImage } from '@material-ui/icons';

interface IProps {
    label: string;
    value: string | null;
    required: boolean;
    onChange: (image: string | null) => void;
}

type TStyles = {
    aplixImagePickerRoot: string;
    cameraControlPanel: string;
};

const styles: TStyles = require('./AplixImagePicker.less');

export const AplixImagePicker: React.FunctionComponent<IProps> = (props) => {
    const {onChange, value, label, required,} = props;
    
    const camera = useRef<CameraType>(null);
    
    const [cameraVisible, setCameraVisible] = useState<boolean>(false);
    const [controlPanelVisible, setControlPanelVisible] = useState<boolean>(false);
    const [image, setImage] = useState<string | null>(value);

    useEffect(() => onChange(image), [ image ]);

    useEffect(() => {
        if(!cameraVisible) {
            setControlPanelVisible(false);
        }
    }, [cameraVisible]);
    
    const TakePictureView = useMemo(() => {
        if(!cameraVisible){
            return <></>;
        }
        
        return (
            <>
                <Camera ref={camera} facingMode={'environment'} videoReadyCallback={() => setControlPanelVisible(true)}  errorMessages={{
                    noCameraAccessible: 'Keine Kamera verfügbar. Bitte verbinden Sie ihre Kamera oder versuchen sie es mit einem anderem Browser. Es werden nur sichere Verbindungen (https) unterstützt.',
                    permissionDenied: 'Zugriff verweigert. Bitte neuladen und Kamera Zugriff erlauben.',
                    switchCamera: 'Es ist nicht möglich die Kamera zu wechseln da nur ein Gerät verfügbar ist.',
                    canvas: 'Canvas wird nicht unterstützt.'
                }}/>
                {controlPanelVisible && <div className={styles.cameraControlPanel}>
                    <AplixCameraButton onClick={() => {
                        camera.current != null && setImage(camera.current.takePhoto());
                        setCameraVisible(false);
                    }}>
                        <CameraIcon/>
                    </AplixCameraButton>
                    <AplixCameraButton onClick={() => setCameraVisible(false)}>
                        <BackIcon/>
                    </AplixCameraButton>
                </div>}
            </>
    )}, [cameraVisible, controlPanelVisible, camera.current]);
    
    return (
        <div className={styles.aplixImagePickerRoot}>
            {TakePictureView}
            {label}{required ? '*' : ''}
            {!controlPanelVisible && <div className={'image-container'}>
                <div className={'image'}>
                    {image && <img src={image} alt="Taken photo"/> || <div className={'broken-image-container'}><BrokenImage fontSize={'large'}/></div>}
                </div>
                <div className={'image-buttons'}>
                    <AplixImagePickerButton onClick={() => setCameraVisible(true)}>
                        <CameraIcon/>
                    </AplixImagePickerButton>
                    {image && !required && <AplixImagePickerButton onClick={() => setImage(null)}>
                        <DeleteIcon/>
                    </AplixImagePickerButton>}
                </div>
            </div>}
        </div>
    );
};

interface IAplixImagePickerButtonProps {
    children?: any;
    onClick: () => void;
}
const AplixImagePickerButton: React.FunctionComponent<IAplixImagePickerButtonProps> = (props) => {
    return (
        <Button
            type="submit"
            variant="outlined"
            color="primary"
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
}

interface IAplixCameraButtonProps {
    children?: any;
    onClick: () => void;
}
const AplixCameraButton: React.FunctionComponent<IAplixCameraButtonProps> = (props) => {
    return (
        <Button
            variant="contained"
            size="medium"
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
}