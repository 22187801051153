import * as React from 'react';
import { useState } from 'react';
import { IContractDetail } from '../../../../logic';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Decrement, EditModeToggle, Increment, Value, ValueInput } from './components';
import { EditModeBarcodeHandler } from '../EditModeBarcodeHandler';
import { AplixErrorDialog } from '../../../../../../components';
import { IEditDetailStoreQueries } from '../../../../logic/IEditDetailStoreQueries';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../ioc_constants';
import { useService } from '../../../../../../ioc/hook/useService';
import { IEditQuantityContext } from './IEditQuantityContext';

interface IStyles {
    editQuantityRoot: string;
    editQuantityColumn: string;
}

const styles: IStyles = require('./EditQuantity.less');

interface IProps {
    detailStore: IContractDetail;
    onAcceptQuantity: () => void;
}

interface IState {
    detailStore: IContractDetail;
    mode: EditQuantityMode;
    saveEnabled: boolean;
    error?: {
        title: string;
        message: string;
    };
}

export enum EditQuantityMode {
    Readonly,
    Edit,
}

export const EditQuantity: React.FunctionComponent<IProps> = (props) => {
    const detailStoreQueries = useService<IEditDetailStoreQueries>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREQUERIES
    );

    const startInEditMode =
        props.detailStore.currentQuantity === 0 &&
        !detailStoreQueries.isUnitLoad(props.detailStore);

    const [state, setState] = useState<IState>({
        detailStore: props.detailStore,
        mode: startInEditMode ? EditQuantityMode.Edit : EditQuantityMode.Readonly,
        saveEnabled: true,
    });

    const startEdit = () => {
        setState({
            ...state,
            mode: EditQuantityMode.Edit,
        });
    };

    const cancelEdit = () => {
        setState({
            ...state,
            mode: EditQuantityMode.Readonly,
            saveEnabled: true,
        });
    };

    const editQuantityContext: IEditQuantityContext = {
        getMode: () => state.mode,
        startEdit: startEdit,
        cancelEdit: cancelEdit,
        setSaveEnabled: (flag: boolean) => {
            setState({
                ...state,
                saveEnabled: flag,
            });
        },
    };

    const showError = (message: string) => {
        console.warn(message);
        setState({
            ...state,
            error: {
                title: 'Fehler',
                message: message,
            },
        });
    };

    const hideError = () => {
        setState({
            ...state,
            error: undefined,
        });
    };

    const renderColumn1 = () => {
        switch (state.mode) {
            case EditQuantityMode.Edit:
                return <ValueInput context={editQuantityContext} />;
            case EditQuantityMode.Readonly:
                return (
                    <>
                        <Value context={editQuantityContext} />
                        {detailStoreQueries.isUnitLoad(props.detailStore) && (
                            <EditModeToggle context={editQuantityContext} />
                        )}
                    </>
                );
        }
    };

    const renderColumn2 = () => {
        if (
            detailStoreQueries.isUnitLoad(props.detailStore) &&
            state.mode === EditQuantityMode.Readonly
        ) {
            return (
                <React.Fragment>
                    <Increment />
                    <Decrement />
                </React.Fragment>
            );
        } else {
            return <EditModeToggle context={editQuantityContext} />;
        }
    };

    const renderColumn3 = () => {
        return (
            <Button
                size="medium"
                variant="contained"
                color="primary"
                disabled={!state.saveEnabled}
                onClick={props.onAcceptQuantity}
                id={'edit-quantity-submit'}
            >
                <Icon>done</Icon>
            </Button>
        );
    };

    const renderError = () => {
        const { error } = state;
        if (!error) {
            return null;
        }
        return (
            <AplixErrorDialog title={error.title} onClose={hideError}>
                {error.message}
            </AplixErrorDialog>
        );
    };

    const isListeningToBarcode = state.mode === EditQuantityMode.Readonly && !state.error;
    return (
        <div className={styles.editQuantityRoot}>
            <div className={styles.editQuantityColumn}>{renderColumn1()}</div>
            <div className={styles.editQuantityColumn}>{renderColumn2()}</div>
            <div className={styles.editQuantityColumn}>{renderColumn3()}</div>
            {isListeningToBarcode && (
                <EditModeBarcodeHandler showError={showError} switchToEditMode={startEdit} />
            )}
            {renderError()}
        </div>
    );
};
