import * as React from 'react';
import { getService } from '../../../../../../../../ioc_config';
import { IMarkdownConverter } from '../../../../../../../../quino';
import SERVICE_IDENTIFIER from '../../../../../../../../quino/core/ioc/constants/identifiers';

interface IProps {
    information: string;
}

type TStyles = {
    contractDetailInformationRoot: string;
};

const styles: TStyles = require('./ContractDetailInformation.less');

export const ContractDetailInformation = ({ information }: IProps) => {
    const markdownConverter = getService<IMarkdownConverter>(
        SERVICE_IDENTIFIER.IMARKDOWNCONVERTER
    );

    return markdownConverter.isMarkdown(information) ? (
        <div className={styles.contractDetailInformationRoot} dangerouslySetInnerHTML={{__html: markdownConverter.convertToHtml(information)}} />
    ) : (
        <div className={styles.contractDetailInformationRoot}>{information}</div>        
    );
};
