import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { inject, injectable } from 'inversify';
import { IScannerService } from './IScannerService';
import { IStorageService } from '../../quino/core/storage';
import SERVICE_IDENTIFIER from '../../quino/core/ioc/constants/identifiers';

require('./Scanner.less');

const scanItLocalStorageKey = 'scanIT_enabled';

@injectable()
export class ScannerService implements IScannerService {
    constructor(
        @inject(SERVICE_IDENTIFIER.ILOCALSTORAGESERVICE)
        private localStorageService: IStorageService
    ) {
        this.container = document.getElementById('scannerOverlay')!;
        this.root = document.getElementById('root')!;
    }

    licensePresent(): boolean {
        if (
            window == null ||
            (window as any).aplixConfig == null ||
            (window as any).aplixConfig.scanditLicenseKey == null
        ) {
            return false;
        }

        return true;
    }

    enabled(): boolean {
        const scanItEnabled = this.localStorageService.get(scanItLocalStorageKey);

        return (
            this.licensePresent() && (scanItEnabled !== null ? (scanItEnabled as boolean) : false)
        );
    }

    setEnabled(enabled: boolean): void {
        this.localStorageService.set(scanItLocalStorageKey, enabled);
    }

    show(callback: (code: string) => void) {
        if (this.portal == null) {
            this.wrapper = ScannerService.createElement();
            this.container.appendChild(this.wrapper);
            this.root.style.display = 'none';

            // Lazy import the scanner to keep tests running. The Scandit sdk is not working with jest or any test framework.
            import('./AplixScanner').then((result) => {
                this.portal = ReactDOM.render(
                    <result.AplixScanner
                        close={() => this.removeOverlay()}
                        success={(code: string) => {
                            this.removeOverlay();
                            callback(code);
                        }}
                    />,
                    this.wrapper
                );
            });
        } else {
            console.warn('Scanner already present.');
        }
    }

    private removeOverlay(): void {
        ReactDOM.unmountComponentAtNode(this.wrapper);
        this.container.removeChild(this.wrapper);
        this.portal = null;
        this.root.style.display = 'flex';
    }

    private static createElement(): HTMLElement {
        const element = document.createElement('div');
        element.className = 'scannerOverlay';

        return element;
    }

    private portal?: any;
    private wrapper: HTMLElement;
    private readonly container: HTMLElement;
    private readonly root: HTMLElement;
}
