import * as fromData from './reducers/data';
import * as fromLogin from './reducers/login';

export const getQuinoState = (appState: IQuinoEnabledStore) => appState.quino;

export interface IQuinoEnabledStore {
    quino: IQuinoState;
}

export interface IQuinoState {
    data: fromData.IDataState;
    login: fromLogin.ILoginState;
}
