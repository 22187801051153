import * as React from 'react';
import { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useService } from '../../../../../../ioc/hook/useService';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../ioc_constants';
import { IBarcodeParser, ICurrentContractService } from '../../../../../../core/logic';
import { AplixErrorDialog, AplixPage, BarcodeListener } from '../../../../../../components';
import { EditDetailContext } from '../../EditDetailContext';
import { CameraAlt } from '@material-ui/icons';
import { getService } from '../../../../../../ioc_config';
import { IScannerService } from '../../../../../../core/scanner/IScannerService';
import { IEditDetailStoreActions } from '../../../../logic/IEditDetailStoreActions';

interface IProps extends IWizardStepProps {
    saveAndQuit: () => void;
}

const DEV_SHORTCUT = '!' as const;

export const VerifyDetailPage: React.FunctionComponent<IProps> = ({
    goToPreviousStep,
    goToNextStep,
    saveAndQuit,
}) => {
    const [detailStore, setDetailStore] = useContext(EditDetailContext);
    const [invalidBarcode, setInvalidBarcode] = useState<string>();

    const storeActions = getService<IEditDetailStoreActions>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREACTIONS
    );

    const currentContractService = useService<ICurrentContractService>(
        APLIX_SERVICE_IDENTIFIER.ICURRENTCONTRACTSERVICE
    );
    const barcodeParser = useService<IBarcodeParser>(APLIX_SERVICE_IDENTIFIER.IBARCODEPARSER);
    const scannerService = getService<IScannerService>(APLIX_SERVICE_IDENTIFIER.ISCANNERSERVICE);

    const notInStock = () => {
        setDetailStore(storeActions.setNotInStock(detailStore));
        saveAndQuit();
    };

    const onScan = (barcode: string) => {
        if (barcode === DEV_SHORTCUT) {
            goToNextStep();
            return;
        }

        const contractDetail = currentContractService.getContractDetailByPrimaryKey(
            detailStore.primaryKey
        );

        if (contractDetail && barcodeParser.parse([contractDetail], barcode)) {
            goToNextStep();
            return;
        }

        setInvalidBarcode(barcode);
    };

    return (
        <AplixPage
            pageTitle={`Artikel "${detailStore.articleNumber}": Bearbeiten`}
            onBackClick={goToPreviousStep}
        >
            {invalidBarcode && (
                <AplixErrorDialog
                    title="Ungültiger Barcode"
                    onClose={() => setInvalidBarcode(undefined)}
                >
                    Der gescannte Barcode &lsquo;{invalidBarcode}&rsquo; gehört nicht zum gewählten
                    Produkt. Bitte versuchen Sie es erneut.
                </AplixErrorDialog>
            )}
            <div>
                <h1>Produkt scannen</h1>
                <p>
                    Bitte scannen Sie das Produkt &lsquo;{detailStore.itemName}&rsquo;, um es zu
                    bearbeiten.
                </p>
                <Button variant="contained" onClick={goToPreviousStep}>
                    Abbrechen
                </Button>
                <Button variant="contained" style={{ marginLeft: '10px' }} onClick={notInStock}>
                    Nicht auf Lager
                </Button>
                {scannerService.enabled() && (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: '10px' }}
                        onClick={() => scannerService.show(onScan)}
                    >
                        Scan
                        <CameraAlt style={{ marginLeft: '10px' }} />
                    </Button>
                )}
            </div>

            <BarcodeListener
                onScan={onScan}
                cheatCodeBarcode={DEV_SHORTCUT}
                scannerService={scannerService}
            />
        </AplixPage>
    );
};
