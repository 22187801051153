import * as React from 'react';
import { IContract } from '../../core/entities';

export interface IContinueContractContext {
    selectedContract: IContract | null;
    errorMessage?: string;
    goToOverviewPage: () => void;
    goToDetailPage: (contract: IContract) => void;
    continueContract: (contract: IContract) => void;
    acceptError: () => void;
}

export const ContinueContractContext = React.createContext<IContinueContractContext>(
    {} as IContinueContractContext
);
