import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { PrepareContractContext } from '../../PrepareContractContext';
import { EditDetailContext } from './EditDetailContext';
import { AplixPage } from '../../../../components';
import { useSelector } from 'react-redux';
import { IState } from '../../../../redux';
import { isDoneSaving as isDoneSavingSelector } from '../../../../redux/contracts/saveDetailState';

interface IProps extends IWizardStepProps {}

export const SavePage: React.FunctionComponent<IProps> = ({ goToPreviousStep, goToNextStep }) => {
    const prepareContractContext = useContext(PrepareContractContext);

    const [detailStore] = useContext(EditDetailContext);

    const [savingInitiated, setSavingInitiated] = useState(false);

    const isDoneSaving: boolean = useSelector((state: IState) =>
        isDoneSavingSelector(state, detailStore.primaryKey)
    );

    useEffect(() => {
        prepareContractContext.saveDetailStore(detailStore);
        setSavingInitiated(true);
    }, []);

    useEffect(() => {
        if (savingInitiated && isDoneSaving) {
            goToNextStep();
        }
    }, [isDoneSaving]);

    return (
        <AplixPage
            pageTitle={`Artikel "${detailStore.articleNumber}": Speichern`}
            onBackClick={goToPreviousStep}
            showLoadingIndicator={true}
        >
            <div>
                <Typography color={'primary'}>Speichere...</Typography>
            </div>
        </AplixPage>
    );
};
