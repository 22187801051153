import { getService } from '../../../../ioc_config';
import APLIX_SERVICE_IDENTIFIER from '../../../../ioc_constants';

export interface IFinishContractService {
    checkForOpenDetails(): boolean;
    finishContractAsync(): Promise<any>;
    endFinishContract(): void;
}

export const getFinishContractService = (): IFinishContractService =>
    getService<IFinishContractService>(APLIX_SERVICE_IDENTIFIER.IFINISHCONTRACTSERVICE);
