import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import { AplixInput, AplixPage } from '../../../../../components';
import { useContext } from 'react';
import { EditDetailContext } from '../EditDetailContext';
import { AmountWarningDialogDialog } from './AmountWarningDialog/AmountWarningDialogDialog';

interface IProps {
    amountType: string;
    initialValue: number;
    goBack: () => void;
    save: (amount: number) => void;
    maxAmount?: number;
    capacity?: number;
}

export const EditAmount: React.FunctionComponent<IProps> = ({ initialValue, goBack, ...props }) => {
    const [detailStore] = useContext(EditDetailContext);
    const [amount, setAmount] = useState(initialValue.toString());
    const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false);

    let nonStrictValidationWarning = false;

    const { unit, articleNumber } = detailStore;

    const setAmountFromEvent = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event.target.value;
        setAmount(value);
    };

    const amountNumber = Number(amount);

    const getErrorMessage = () => {
        if (isNaN(amountNumber)) {
            return 'Muss eine Zahl sein';
        }

        if (amountNumber < 0) {
            return `Muss eine positive Zahl sein`;
        }

        if (
            (props.maxAmount !== undefined && amountNumber > props.maxAmount) ||
            (props.capacity !== undefined && amountNumber > props.capacity)
        ) {
            if (props.maxAmount !== undefined && amountNumber <= props.maxAmount) {
                nonStrictValidationWarning = true;
                return `${props.amountType} darf höchstens ${props.capacity} ${unit} sein`;
            }

            return `Darf höchstens ${props.maxAmount} ${unit} sein`;
        }

        return undefined;
    };

    const errorMessage = getErrorMessage();
    const isValid = !errorMessage || nonStrictValidationWarning;

    const save = () => {
        props.save(amountNumber);
    };

    const trySave = () => {
        if (!errorMessage) {
            save();
        } else {
            setShowWarningDialog(true);
        }
    };

    return (
        <>
            {errorMessage && (
                <AmountWarningDialogDialog
                    show={showWarningDialog}
                    onConfirm={save}
                    onClose={() => setShowWarningDialog(false)}
                    message={errorMessage}
                />
            )}
            <AplixPage
                showBackButton
                pageTitle={`Artikel "${articleNumber}": "${props.amountType}" bearbeiten`}
                onBackClick={goBack}
            >
                <Box width={1}>
                    <Grid container spacing={2} alignItems={'center'}>
                        <Grid item xs={10}>
                            <AplixInput
                                id="lotAmount"
                                label={`Menge (${unit})`}
                                type={'number'}
                                value={amount}
                                fullWidth
                                autoFocus
                                required
                                showKeyboardAdornment
                                onChange={setAmountFromEvent}
                                errorMessage={!isValid ? errorMessage : undefined}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                disabled={!isValid}
                                onClick={trySave}
                                size={'medium'}
                            >
                                <Icon>done</Icon>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </AplixPage>
        </>
    );
};
