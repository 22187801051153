import * as React from 'react';
import { useState } from 'react';
import { IContract } from '../../core/entities';
import { ContinueContractSingle } from './scenes/ContinueContractSingle/ContinueContractSingle';
import { ContinueContractList } from './scenes/ContinueContractList/ContinueContractList';
import { ContinueContractContext } from './ContinueContractContext';
import { IContinueContractService } from './redux/logic/IContinueContractService';
import APLIX_SERVICE_IDENTIFIER from '../../ioc_constants';
import { useService } from '../../ioc/hook/useService';
import { connect, useDispatch, useSelector } from 'react-redux';
import { IState } from '../../redux';
import { clearContinuableError } from '../../redux/contracts/actions';
import { AplixLoadingIndicator } from '../../components/AplixLoadingIndicator/AplixLoadingIndicator';
import { getContinuableContractsState } from '../../redux/contracts';
import { ThunkDispatch } from 'redux-thunk';
import { Dispatch } from 'redux';
import { GlobalsAction } from '../../redux/globals';
import { LoginAction } from '../../quino';

enum ContinueContractSubPage {
    Overview = 'Overview',
    Detail = 'Detail',
}

export type TOwnProps = {};

type TStateProps = {
    isContractsFetching: boolean;
};

type TDispatchProps = {};

export type TProps = TOwnProps & TStateProps & TDispatchProps;

const ContinueContractPageComponent: React.FunctionComponent<TProps> = (props: TProps) => {
    const {
        isContractsFetching,
    } = props;


    const continueContractService = useService<IContinueContractService>(
        APLIX_SERVICE_IDENTIFIER.ICONTINUECONTRACTSERVICE
    );

    const [selectedPage, setSelectedPage] = useState<ContinueContractSubPage>(
        ContinueContractSubPage.Overview
    );
    const [selectedContract, setSelectedContract] = useState<IContract>();
    const errorMessage = useSelector((state: IState) => state.contracts.continuable.errorMessage);
    const dispatch = useDispatch();

    const goToOverviewPage = () => {
        setSelectedPage(ContinueContractSubPage.Overview);
    };

    const goToDetailPage = (contract: IContract) => {
        setSelectedContract(contract);
        setSelectedPage(ContinueContractSubPage.Detail);
    };

    const acceptError = () => {
        dispatch(clearContinuableError());
    };

    const continueContract = (contract: IContract): void => {
        continueContractService.continueContractAsync(contract);
    };

    const context = {
        selectedContract: selectedContract || null,
        errorMessage,
        goToDetailPage: goToDetailPage,
        goToOverviewPage: goToOverviewPage,
        continueContract,
        acceptError: acceptError,
    };

    const renderChildren = () => {
        switch (selectedPage) {
            case ContinueContractSubPage.Detail:
                return <ContinueContractSingle />;
            case ContinueContractSubPage.Overview:
            default:
                return <ContinueContractList />;
        }
    };

    return (
        <ContinueContractContext.Provider value={context}>
            {isContractsFetching && <AplixLoadingIndicator/> || renderChildren()}
        </ContinueContractContext.Provider>
    );
};

export function mapStateToProps(state: IState): TStateProps {
    const continuableContractsState = getContinuableContractsState(state);

    return {
        isContractsFetching: continuableContractsState.isFetching,
    };
}

export function mapDispatchToProps(
    dispatch: ThunkDispatch<IState, Dispatch, GlobalsAction | LoginAction>
): TDispatchProps {
    return {};
}


export const ContinueContractPage = connect<TStateProps, TDispatchProps, TOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(ContinueContractPageComponent);
