import * as React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { uniq } from 'lodash';
import Box from '@material-ui/core/Box';
import { SalesType } from '../../../../core/entities/SalesType';
import { IContract } from '../../../../core';
import { useEffect } from 'react';

type TStyles = {
    salesTypeButton: string;
};

const styles: TStyles = require('./SalesTypeFilter.less');

interface IProps {
    contracts: IContract[];
    selectSalesType: (salesType: SalesType) => void;
}

export const SalesTypeFilter: React.FunctionComponent<IProps> = ({
    contracts,
    selectSalesType,
}) => {
    const availableSalesTypes: SalesType[] = uniq(contracts.map((contract) => contract.umsatzart));

    useEffect(() => {
        if (availableSalesTypes.length === 1) {
            selectSalesType(availableSalesTypes[0]);
        }
    }, [availableSalesTypes]);

    function hasContractsWith(salesType: SalesType) {
        return availableSalesTypes.includes(salesType);
    }

    function renderButton(salesType: SalesType, label: string) {
        return (
            <Box flexGrow={1} py={1}>
                <Button
                    className={styles.salesTypeButton}
                    variant={'contained'}
                    color={'primary'}
                    size={'large'}
                    onClick={() => selectSalesType(salesType)}
                    disabled={!hasContractsWith(salesType)}
                >
                    {label}
                </Button>
            </Box>
        );
    }

    return (
        <Box display={'flex'} flexDirection={'column'} height={1}>
            <Typography variant={'h5'}>Umsatzart wählen</Typography>

            {renderButton(SalesType.Purchase, 'Einkauf')}
            {renderButton(SalesType.Sales, 'Verkauf')}
            {renderButton(SalesType.Production, 'Produktion')}
        </Box>
    );
};
