import * as React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useContext } from 'react';
import { ContractTableContext } from '../../ContractTableContext';

type TStyles = {
    listHeaderRoot: string;
};

const styles: TStyles = require('./ListHeader.less');

export const ListHeader = () => {
    const { additionalColumns } = useContext(ContractTableContext);

    return (
        <TableHead className={styles.listHeaderRoot}>
            <TableRow>
                <TableCell>Nummer</TableCell>
                <TableCell>Kunde</TableCell>
                <TableCell>Region</TableCell>
                <TableCell>Datum</TableCell>
                {additionalColumns.map((column, id) => (
                    <TableCell key={id}>{column.label}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
