export enum PropertyType {
    Text = 'Text',
    MultilineText = 'Memo',
    Date = 'Datum',
    Time = 'Zeit',
    Boolean = 'JaNein',
    Decimal = 'Dezimal',
    Integer = 'Ganzzahl',
    Image = 'Bild',
    Signature = 'Signatur'
}
