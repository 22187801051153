import { IView } from '../../../../core/meta';

export type DataAction = TRequestViewAndData | TReceiveViewAndData | TFetchViewAndData;

export const REQUEST_VIEW_AND_DATA = 'REQUEST_VIEW_AND_DATA';
export type REQUEST_VIEW_AND_DATA = typeof REQUEST_VIEW_AND_DATA;
export type TRequestViewAndData = {
    type: REQUEST_VIEW_AND_DATA;
};

export const RECEIVE_VIEW_AND_DATA = 'RECEIVE_VIEW_AND_DATA';
export type RECEIVE_VIEW_AND_DATA = typeof RECEIVE_VIEW_AND_DATA;
export type TReceiveViewAndData = {
    type: RECEIVE_VIEW_AND_DATA;
    entityName: string;
    viewName: string;
    data: any;
    view: IView;
    receivedAt: number;
};

export const FETCH_VIEW_AND_DATA = 'FETCH_VIEW_AND_DATA';
export type FETCH_VIEW_AND_DATA = typeof FETCH_VIEW_AND_DATA;
export type TFetchViewAndData = {
    type: FETCH_VIEW_AND_DATA;
    entityName: string;
    viewName: string;
    detailViewName: string;
};
