import { IEditDetailStoreActions } from './IEditDetailStoreActions';
import * as _ from 'lodash';
import { QtyValidationState, QuantityGrade } from './types';
import { IEditDetailStoreQueries } from './IEditDetailStoreQueries';
import { inject, injectable } from 'inversify';
import APLIX_SERVICE_IDENTIFIER from '../../../ioc_constants';
import { ILot } from '../../../core/entities/ILot';
import { IContractDetail } from '../../../core/entities/IContractDetail';
import { IPlace } from '../../../core/entities/IPlace';
import { TPrimaryKey } from '../../../quino/core/data';
import { IContractDetailProperty } from '../../../core/entities/IContractDetailProperty';
import { PropertyType } from '../../../core/entities/PropertyType';

@injectable()
export class EditDetailStoreActions implements IEditDetailStoreActions {
    constructor(
        @inject(APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREQUERIES)
        private detailStoreQueries: IEditDetailStoreQueries
    ) {}

    incrementQuantity(detailStore: IContractDetail, amount: number): IContractDetail {
        const newAmount = detailStore.currentQuantity + amount;
        const roundedNewAmount = _.round(newAmount, detailStore.decimalPlaces);

        return {
            ...detailStore,
            currentQuantity: roundedNewAmount,
            validation: QtyValidationState.None,
        };
    }

    decrementQuantity(detailStore: IContractDetail): IContractDetail {
        if (!this.detailStoreQueries.canDecrementQuantity(detailStore)) {
            return { ...detailStore };
        }
        return {
            ...detailStore,
            currentQuantity: detailStore.currentQuantity - 1,
            validation: QtyValidationState.None,
        };
    }

    setQuantity(detailStore: IContractDetail, quantity: number) {
        const roundedQuantity = _.floor(quantity, detailStore.decimalPlaces);
        const isValid = quantity >= 0;
        const isChanged = roundedQuantity !== detailStore.currentQuantity;

        if (isChanged && isValid) {
            return {
                ...detailStore,
                currentQuantity: roundedQuantity,
                validation: QtyValidationState.None,
            };
        }

        return { ...detailStore };
    }

    setNotInStock(detailStore: IContractDetail) {
        // add default values for detail properties
        detailStore.properties.forEach((property) => {
            if (!property.value) {
                if (property.type === PropertyType.Boolean) {
                    property.value = 'Nein';
                } else if (
                    property.type === PropertyType.Time ||
                    property.type === PropertyType.Date ||
                    property.type === PropertyType.Integer ||
                    property.type === PropertyType.Decimal
                ) {
                    property.value = '0';
                } else {
                    property.value = '';
                }
            }
        });

        return {
            ...detailStore,
            currentQuantity: 0,
            validation: QtyValidationState.Completed,
        };
    }

    saveLot(detailStore: IContractDetail, lot: ILot) {
        const oldLots = detailStore.lots;

        let updatedLots: ILot[];

        if (oldLots.find((oldLot) => oldLot.number === lot.number)) {
            updatedLots = oldLots.map((oldLot) => (oldLot.number === lot.number ? lot : oldLot));
        } else {
            updatedLots = [...oldLots, lot];
        }

        return {
            ...detailStore,
            lots: updatedLots,
        };
    }

    deleteLot(detailStore: IContractDetail, lotNumber: string): IContractDetail {
        return {
            ...detailStore,
            lots: detailStore.lots.filter((lot) => lot.number !== lotNumber),
        };
    }

    savePlace(detailStore: IContractDetail, place: IPlace) {
        const oldPlaces = detailStore.places;

        let updatedPlaces: IPlace[];

        if (oldPlaces.find((oldPlace) => oldPlace.placeId === place.placeId)) {
            updatedPlaces = oldPlaces.map((oldPlace) =>
                oldPlace.placeId === place.placeId ? place : oldPlace
            );
        } else {
            updatedPlaces = [...oldPlaces, place];
        }

        return {
            ...detailStore,
            places: updatedPlaces,
        };
    }

    deletePlace(detailStore: IContractDetail, placeId: string): IContractDetail {
        return {
            ...detailStore,
            places: detailStore.places.filter((place) => place.placeId !== placeId),
        };
    }

    userAcceptsQtyIsComplete(detailStore: IContractDetail): IContractDetail {
        return {
            ...detailStore,
            validation: QtyValidationState.Completed,
        };
    }

    userAcceptsQtyAsPartialComplete(detailStore: IContractDetail): IContractDetail {
        return {
            ...detailStore,
            validation: QtyValidationState.NotCompleted,
        };
    }

    userRefuseQty(detailStore: IContractDetail): IContractDetail {
        return {
            ...detailStore,
            validation: QtyValidationState.None,
        };
    }

    tryAutoAccept(detailStore: IContractDetail): IContractDetail {
        if (detailStore.validation === QtyValidationState.None) {
            if (this.detailStoreQueries.getQuantityGrade(detailStore) === QuantityGrade.Perfect) {
                return {
                    ...detailStore,
                    validation: QtyValidationState.Completed,
                };
            } else {
                return {
                    ...detailStore,
                    validation: QtyValidationState.Pending,
                };
            }
        }
        return { ...detailStore };
    }

    forceAutoAccept(detailStore: IContractDetail): IContractDetail {
        const isPerfect =
            this.detailStoreQueries.getQuantityGrade(detailStore) === QuantityGrade.Perfect;
        const validation = isPerfect
            ? QtyValidationState.Completed
            : QtyValidationState.NotCompleted;

        return {
            ...detailStore,
            validation,
        };
    }

    saveProperty(
        detailStore: IContractDetail,
        propertyId: TPrimaryKey,
        newValue: string
    ): IContractDetail {
        const updateProperty = (property: IContractDetailProperty) => {
            return {
                ...property,
                value: property.primaryKey === propertyId ? newValue : property.value,
            };
        };

        return {
            ...detailStore,
            properties: detailStore.properties.map(updateProperty),
        };
    }
}
