import * as React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { AplixErrorBox, AplixPage } from '../../components';
import { IState } from '../../redux';
import { connect } from 'react-redux';
import {
    FinishContractStateStatus,
    getFinishContractService,
    getFinishContractState,
} from './redux';

type TStyles = {
    contractFinishingRoot: string;
    contractFinishingProgress: string;
    contractFinishInfoText: string;
};

const styles: TStyles = require('./FinishContractPage.less');

type TStateProps = {
    contractNumber: string;
    status: FinishContractStateStatus;
    errorMessage?: string;
};

type TProps = TStateProps;

class FinishContractPageComponent extends React.Component<TProps> {
    constructor(props: TProps) {
        super(props);
    }

    private static onGoBack = (): void => {
        getFinishContractService().endFinishContract();
    };

    render() {
        return (
            <AplixPage
                pageTitle="Auftrag abschliessen"
                showHomeButton={true}
                onHomeClick={FinishContractPageComponent.onGoBack}
            >
                <div className={styles.contractFinishingRoot}>
                    {this.props.status === FinishContractStateStatus.Fetching && (
                        <div className={styles.contractFinishingProgress}>
                            <CircularProgress
                                variant="indeterminate"
                                color="primary"
                                style={{ verticalAlign: 'middle', marginRight: 5 }}
                            />
                            <p>
                                Auftrag &lsquo;<em>{this.props.contractNumber}</em>&rsquo; wird
                                abgeschlossen ...
                            </p>
                        </div>
                    )}

                    {this.props.status === FinishContractStateStatus.Error && (
                        <AplixErrorBox>
                            <h2>Auftrag konnte nicht abgeschlossen werden!</h2>
                            <p>
                                <strong>Meldung: </strong>
                                {this.props.errorMessage}
                            </p>
                            <Button
                                className={styles.contractFinishInfoText}
                                color="default"
                                variant="contained"
                                onClick={FinishContractPageComponent.onGoBack}
                            >
                                Ok
                            </Button>
                        </AplixErrorBox>
                    )}

                    {this.props.status === FinishContractStateStatus.Success && (
                        <div className={styles.contractFinishingProgress}>
                            <h2>Auftrag abgeschlossen</h2>
                            <Typography component="p" color="primary">
                                Auftrag &lsquo;<em>{this.props.contractNumber}</em>&rsquo; wurde
                                abgeschlossen.
                            </Typography>
                            <Button
                                className={styles.contractFinishInfoText}
                                color="default"
                                variant="contained"
                                onClick={FinishContractPageComponent.onGoBack}
                                id={'finish-contract-confirm'}
                            >
                                Ok
                            </Button>
                        </div>
                    )}
                </div>
            </AplixPage>
        );
    }
}

export function mapStateToProps(state: IState): TStateProps {
    const finishContractState = getFinishContractState(state);

    return {
        errorMessage: finishContractState.errorMessage,
        status: finishContractState.status,
        contractNumber: finishContractState.contractNumber,
    };
}

export const FinishContractPage = connect<TStateProps>(mapStateToProps)(
    FinishContractPageComponent
);
