import { IContractDetailDTO } from '../../entities';
import { IAmounts } from './IAmounts';
import { injectable } from 'inversify';
import { IBarcodeParserStrategy } from './IBarcodeParserStrategy';

export interface IExactStrategy extends IBarcodeParserStrategy {}

@injectable()
export class ExactStrategy implements IBarcodeParserStrategy {
    parseBarcode(
        readBarcode: string,
        detail: IContractDetailDTO,
        detailBarcode?: string,
        factor?: number
    ): IAmounts | null {
        if (readBarcode === detailBarcode) {
            return {
                amount: null,
                factor: factor || 1,
            };
        }

        return null;
    }
}
