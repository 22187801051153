import { IBarcodeParserResult } from '../../../core/logic';
import { IEditDetailStoreBuilder } from './IEditDetailStoreBuilder';
import { inject, injectable } from 'inversify';
import APLIX_SERVICE_IDENTIFIER from '../../../ioc_constants';
import { IContractDetailDTO } from '../../../core/entities';
import { ScanType } from '../../../core/entities/ScanType';
import { IAmounts } from '../../../core/logic/BarcodeParser/IAmounts';
import { IEditDetailStoreQueries } from './IEditDetailStoreQueries';
import { QtyValidationState } from './types';
import { flow } from 'lodash';
import { IContractDetail } from '../../../core/entities/IContractDetail';
import { IEditDetailStoreActions } from './IEditDetailStoreActions';

@injectable()
export class EditDetailStoreBuilder implements IEditDetailStoreBuilder {
    constructor(
        @inject(APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREQUERIES)
        private detailStoreQueries: IEditDetailStoreQueries,
        @inject(APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREACTIONS)
        private detailStoreActions: IEditDetailStoreActions
    ) {}

    fromBarcodeParserResult(barcodeParserResult: IBarcodeParserResult): IContractDetail {
        const { amount, factor, contractDetail } = barcodeParserResult;

        return flow([
            EditDetailStoreBuilder.buildFromDetail,
            EditDetailStoreBuilder.updateContractDetail({
                bundleSize: factor,
                lastScannedAmount: amount,
            }),
            this.initCurrentAmountFromBarcode(barcodeParserResult),
        ])(contractDetail);
    }

    fromContractDetail(detailDTO: IContractDetailDTO): IContractDetail {
        return flow([
            EditDetailStoreBuilder.buildFromDetail,
            EditDetailStoreBuilder.updateContractDetail({ bundleSize: 1 }),
            this.initCurrentAmount,
        ])(detailDTO);
    }

    private static updateContractDetail = (update: Partial<IContractDetail>) => (
        contractDetail: IContractDetail
    ) => ({
        ...contractDetail,
        ...update,
    });

    private initCurrentAmountFromBarcode = ({ factor, amount }: IAmounts) => (
        contractDetail: IContractDetail
    ): IContractDetail => {
        if (contractDetail.scanType === ScanType.Product) {
            return this.detailStoreActions.incrementQuantity(
                contractDetail,
                amount === null ? factor : factor * amount
            );
        } else if (!this.detailStoreQueries.isUnitLoad(contractDetail)) {
            return this.detailStoreActions.incrementQuantity(
                contractDetail,
                amount === null ? 0 : factor * amount
            );
        } else {
            return this.initCurrentAmount(contractDetail);
        }
    };

    private initCurrentAmount = (contractDetail: IContractDetail): IContractDetail => {
        if (contractDetail.currentQuantity === 0) {
            return this.detailStoreActions.setQuantity(
                contractDetail,
                contractDetail.targetQuantity
            );
        }

        return { ...contractDetail };
    };

    private static buildFromDetail = (detail: IContractDetailDTO): IContractDetail => ({
        primaryKey: detail.primaryKey,
        bundleSize: 1,
        lastScannedAmount: null,
        validation: QtyValidationState.None,
        hasLots: detail.hasLots || false,
        hasPlaces: detail.hasPlaces || false,
        properties: [...(detail.properties || [])],
        targetQuantity: detail.menge || 0,
        itemName: detail.bezeichnung || '',
        information: detail.information || '',
        articleNumber: detail.nummer || '',
        unit: detail.einheit || '',
        decimalPlaces: detail.kommastellen || 0,
        currentQuantity: detail.mengeGeruestet || 0,
        allowedDeviation: detail.erlaubteAbweichung || 0,
        scanType: detail.scantyp,
        lots: [...(detail.lots || [])],
        places: [...(detail.places || [])],
        amountPerPlace: detail.amountPerPlace,
    });
}
