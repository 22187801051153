import { IContinuableContractsState } from '../state';
import { ContractsAction } from '..';

const initial: IContinuableContractsState = {
    contracts: [],
    isLoaded: false,
    isFetching: false,
    errorMessage: '',
};

export function reducer(
    state: IContinuableContractsState = initial,
    action: ContractsAction
): IContinuableContractsState {
    switch (action.type) {
        case 'CONTINUE_CONTRACT_ERROR':
            return {
                ...state,
                errorMessage: action.errorMessage,
            };

        case 'FETCH_CONTINUABLE_CONTRACTS_BEGIN':
            return {
                ...state,
                isLoaded: false,
                isFetching: true,
            };

        case 'FETCH_CONTINUABLE_CONTRACTS_SUCCESS':
            return {
                ...state,
                isLoaded: true,
                isFetching: false,
                errorMessage: undefined,
                contracts: action.continuableContracts ? action.continuableContracts : [],
            };

        case 'FETCH_CONTINUABLE_CONTRACTS_ERROR':
            return {
                ...state,
                isLoaded: true,
                isFetching: false,
                errorMessage: action.errorMessage,
            };

        case 'CLEAR_CONTINUABLE_ERROR':
            return {
                ...state,
                errorMessage: undefined,
            };

        case 'UNLOAD_CONTINUABLE_CONTRACTS':
            return {
                ...state,
                isLoaded: false,
                contracts: []
            };

        default:
            return state;
    }

    return state;
}
