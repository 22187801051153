import { inject, injectable } from 'inversify';
import APLIX_SERVICE_IDENTIFIER from '../../../../ioc_constants';
import { IContinueContractService } from './IContinueContractService';
import { IContract } from '../../../../core/entities';
import { continueContractError } from '../../../../redux/contracts/actions';
import { IAplixService } from '../../../../core';
import { openContract } from '../../../../redux/contracts/logic';
import { TAplixAction, TAplixStore } from '../../../../redux/store';

@injectable()
export class ContinueContractService implements IContinueContractService {
    constructor(
        @inject(APLIX_SERVICE_IDENTIFIER.STORE) private store: TAplixStore,
        @inject(APLIX_SERVICE_IDENTIFIER.IAPLIXSERVICE) private aplixService: IAplixService
    ) {}

    async continueContractAsync(contract: IContract) {
        if (!contract.primaryKey) {
            return;
        }

        try {
            await this.aplixService.continueContractAsync(contract.primaryKey);

            // TODO: This is unnecessary in my opinion. A request should never return a 200 code AND an error message.
            // if (result.errors) {
            //     const errorMessage = result.errors.map((e) => e.message).join(' ');
            //     this.dispatch(continueContractError(errorMessage));
            // }
        } catch (ex) {
            const errorMessage = ex.message ? ex.message : ex.toString();
            this.dispatch(continueContractError(errorMessage));
        }

        this.store.dispatch(openContract(contract));
    }

    private dispatch(action: TAplixAction) {
        this.store.dispatch(action);
    }
}
