import { IState } from '../index';
import {SalesType} from "../../core/entities/SalesType";

export const getGlobalsState = (state: IState): IGlobalState => state.globals;
export const getGlobalUiState = (state: IState): IGlobalUiState =>
    getGlobalsState(state).globalUiState;
export const getServerInfoState = (state: IState): IServerInfoState =>
    getGlobalsState(state).serverInfo;
export const getCurrentUserInfoState = (state: IState): ICurrentUserInfoState =>
    getGlobalsState(state).currentUserInfo;
export const getUpdateState = (state: IState): IUpdateState => getGlobalsState(state).update;

export const getBookmarkContractFilters = (state: IState): IContractFilterState => getGlobalsState(state).bookmark.contractFilters;

export enum RootPage {
    FinishContractPage,
    PrepareContractPage,
    StartContractPage,
    ContinueContractPage,
    StartPage,
    ScanContractPage,
    ChooseContractPage,
}

export interface IGlobalState {
    globalUiState: IGlobalUiState;
    serverInfo: IServerInfoState;
    currentUserInfo: ICurrentUserInfoState;
    update: IUpdateState;
    bookmark: IBookmarkState
}

export interface IGlobalUiState {
    rootPage: RootPage;
}

export interface IServerInfoState {
    serverVersion: string;
    database: string;
    isTestingEnvironment: boolean;
    isFetching: boolean;
    isServerInfoLoaded: boolean;
    errorMessage?: string;
}

export interface ICurrentUserInfoState {
    aplixEmployeeId?: number;
    currentUserLastName?: string;
    currentUserFirstName?: string;
    isFetching: boolean;
    isUserInfoLoaded: boolean;
    errorMessage?: string;
}

export interface IUpdateState {
    isUpdateAvailable: boolean;
    newVersion: string;
}

export interface IBookmarkState {
    lastActivePage: RootPage;
    contractFilters: IContractFilterState;
}

export interface IContractFilterState {
    region?: string;
    salesType?: SalesType;
    choiceID?: string | null;
    choiceHistory?: Array<string | null>;
}