import * as React from 'react';
import { useState } from 'react';
import { AplixPage } from '../../../../components';
import { EditTextValue } from './EditTextValue';
import { IGenericProperty } from '../../../../core/entities/IGenericProperty';
import { sortBy } from 'lodash';
import { PropertyType } from '../../../../core/entities/PropertyType';
import { IContractProperty } from '../../../../core/entities/IContractProperty';
import { TPrimaryKey } from '../../../../quino/core/data';
import { EditBooleanValue } from './EditBooleanValue';
import { EditDateValue } from './EditDateValue';
import { EditDecimalValue } from './EditDecimalValue';
import { EditIntegerValue } from './EditIntegerValue';
import { EditMultilineTextValue } from './EditMultilineTextValue';
import { EditTimeValue } from './EditTimeValue';
import { EditImageValue } from './EditImageValue';
import { EditSignatureValue } from "./EditSignatureValue";

interface IProps {
    goBack: () => void;
    save: (updatedProperties: IGenericProperty[]) => void;
    properties: IContractProperty[];
    pageTitle: string;
    validateRequired: boolean;
}

function updateProperty(
    allProperties: IContractProperty[],
    filteredProperties: IContractProperty[],
    propertyId: TPrimaryKey,
    newValue: string | null
): IContractProperty[] {
    const updateProperty = (property: IContractProperty) => {
        return {
            ...property,
            value: property.primaryKey === propertyId ? newValue : property.value,
        };
    };

    const mergeResults = (list1: IContractProperty[], list2: IContractProperty[]) => {
        list1.forEach((element) => {
            const existing = list2.find((p) => p.primaryKey === element.primaryKey);
            if (existing) {
                element.value = existing.value;
            }
        });

        return list1;
    };

    const mergeResult = mergeResults(allProperties, filteredProperties);

    return mergeResult.map(updateProperty);
}

export const EditPropertiesWizard: React.FunctionComponent<IProps> = (props) => {
    const [currentPropertyIndex, setCurrentPropertyIndex] = useState(0);

    const filterPropertiesByFillAtStart = (allProps: IContractProperty[]) => {
        let startProperties;
        if (!props.validateRequired) {
            startProperties = allProps.filter(
                (prop) => prop.fillAtStart == null || prop.fillAtStart
            );
        } else {
            startProperties = allProps.filter(
                (prop) =>
                    prop.fillAtStart == null ||
                    !prop.fillAtStart ||
                    (prop.value === null && prop.isRequired)
            );
        }

        return startProperties;
    };

    const [properties, setProperties] = useState(() => {
        const startProperties = filterPropertiesByFillAtStart(props.properties);

        return sortBy(startProperties, (property) => property.order);
    });

    if (properties.length === 0) {
        props.save(props.properties);
        return <></>;
    }

    const goToPreviousProperty = () => {
        if (currentPropertyIndex <= 0) {
            props.goBack();
        } else {
            setCurrentPropertyIndex(currentPropertyIndex - 1);
        }
    };

    const property = properties[currentPropertyIndex];

    const saveProperty = (value: string | null) => {
        const updatedProperties = updateProperty(
            props.properties,
            properties,
            property.primaryKey,
            value
        );
        const isLastStep = currentPropertyIndex + 1 >= properties.length;

        if (isLastStep) {
            props.save(updatedProperties);
        } else {
            setProperties(filterPropertiesByFillAtStart(updatedProperties));
            setCurrentPropertyIndex(currentPropertyIndex + 1);
        }
    };

    const renderValueEditor = () => {
        const editProps = {
            key: property.primaryKey,
            property,
            save: saveProperty,
            validateRequired: props.validateRequired,
        };

        switch (property.type) {
            case PropertyType.Boolean:
                return <EditBooleanValue {...editProps} />;
            case PropertyType.Date:
                return <EditDateValue {...editProps} />;
            case PropertyType.Decimal:
                return <EditDecimalValue {...editProps} />;
            case PropertyType.Integer:
                return <EditIntegerValue {...editProps} />;
            case PropertyType.MultilineText:
                return <EditMultilineTextValue {...editProps} />;
            case PropertyType.Text:
                return <EditTextValue {...editProps} />;
            case PropertyType.Time:
                return <EditTimeValue {...editProps} />;
            case PropertyType.Image:
                return <EditImageValue {...editProps} />;
            case PropertyType.Signature:
                return <EditSignatureValue {...editProps} />;
            default:
                return (
                    <>
                        Fehler: Eigenschaften vom Typ &ldquo;{property.type}&rdquo; werden nicht
                        unterstützt
                    </>
                );
        }
    };

    return (
        <AplixPage showBackButton pageTitle={props.pageTitle} onBackClick={goToPreviousProperty}>
            {renderValueEditor()}
        </AplixPage>
    );
};
