import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import { IContractDetail } from '../../../../../../core/entities/IContractDetail';

export type TEditConfirmationModel = {
    type: 'NotInOrder' | 'AlreadyPrepared' | 'Paused';
    detail: IContractDetail;
};

interface IProps {
    show: boolean;
    onConfirm: () => void;
    onAbort: () => void;
    viewModel: TEditConfirmationModel;
}

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
Transition.displayName = 'slideUpTransition';

export const EditConfirmationDialog = ({ show, onConfirm, onAbort, viewModel }: IProps) => {
    const getTitle = () => {
        switch (viewModel.type) {
            case 'AlreadyPrepared':
                return 'Produkt bereits gerüstet';
            case 'NotInOrder':
                return 'Produkt ist nicht an der Reihe';
            case 'Paused':
                return 'Produkt ist Pausiert';
        }
    };

    const getMessage = () => {
        switch (viewModel.type) {
            case 'AlreadyPrepared':
                return (
                    <>
                        Das gescannte Produkt ({viewModel.detail.itemName}) wurde beretis fertig
                        gerüstet. <strong>Möchten Sie es trotzdem bearbeiten?</strong>
                    </>
                );
            case 'NotInOrder':
                return (
                    <>
                        Das gescannte Produkt ({viewModel.detail.itemName}) ist nicht das oberste
                        auf der Liste. <strong>Möchten Sie es trotzdem bearbeiten?</strong>
                    </>
                );
            case 'Paused':
                return (
                    <>
                        Das gescannte Produkt ({viewModel.detail.itemName}) ist pausiert.
                        <strong>Möchten Sie es trotzdem bearbeiten?</strong>
                    </>
                );
        }
    };

    return (
        <Dialog
            open={show}
            TransitionComponent={Transition}
            onClose={onAbort}
            aria-labelledby="alert-dialog-title"
        >
            <audio src={require('../../../../../../assets/warning.mp3')} autoPlay={true} />
            <DialogTitle id="alert-dialog-title">{getTitle()}</DialogTitle>
            <DialogContent>
                <DialogContentText>{getMessage()}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} variant="contained">
                    Ja
                </Button>
                <Button onClick={onAbort} variant="contained" color="primary">
                    Nein
                </Button>
            </DialogActions>
        </Dialog>
    );
};
