/**
 * Describes, what happens when the user scans a barcode.
 * @see entity ContractDetail.Scantyp
 */
export enum ScanType {
    /**
     * The barcode represents the item type
     * To prepare multiple items, the user may scan one item and enter the amount afterwards
     */
    Position = 0,

    /**
     * The barcode represents the item
     * To prepare multiple items, the user scans every single item.
     */
    Product = 1,
}
