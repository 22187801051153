import {IContractFilterState, RootPage} from '..';
import { IServerInfo } from '../../../core/api/IServerInfo';
import { IEnvironmentInfoDTO } from '../../../core/api/DTOs/IEnvironmentInfoDTO';

export type GlobalsAction =
    | SwitchRootPage
    | FetchServerInfoBegin
    | FetchServerInfoSuccess
    | FetchServerInfoError
    | FetchCurrentUserInfoBegin
    | FetchCurrentUserInfoSuccess
    | FetchCurrentUserInfoError
    | UpdateAvailable
    | SetBookmarkLastPage
    | SetBookmarkContractFilters;

export const SWITCH_ROOT_PAGE = 'SWITCH_ROOT_PAGE';
export type SWITCH_ROOT_PAGE = typeof SWITCH_ROOT_PAGE;
export type SwitchRootPage = {
    type: SWITCH_ROOT_PAGE;
    targetPage: RootPage;
};

export const FETCH_SERVER_INFO_BEGIN = 'FETCH_SERVER_INFO_BEGIN';
export type FETCH_SERVER_INFO_BEGIN = typeof FETCH_SERVER_INFO_BEGIN;
export type FetchServerInfoBegin = {
    type: FETCH_SERVER_INFO_BEGIN;
};

export const FETCH_SERVER_INFO_SUCCESS = 'FETCH_SERVER_INFO_SUCCESS';
export type FETCH_SERVER_INFO_SUCCESS = typeof FETCH_SERVER_INFO_SUCCESS;
export type FetchServerInfoSuccess = {
    type: FETCH_SERVER_INFO_SUCCESS;
    serverInfo: IServerInfo & IEnvironmentInfoDTO;
};

export const FETCH_SERVER_INFO_ERROR = 'FETCH_SERVER_INFO_ERROR';
export type FETCH_SERVER_INFO_ERROR = typeof FETCH_SERVER_INFO_ERROR;
export type FetchServerInfoError = {
    type: FETCH_SERVER_INFO_ERROR;
    errorMessage: string;
};

export const FETCH_CURRENT_USER_INFO_BEGIN = 'FETCH_CURRENT_USER_INFO_BEGIN';
export type FETCH_CURRENT_USER_INFO_BEGIN = typeof FETCH_CURRENT_USER_INFO_BEGIN;
export type FetchCurrentUserInfoBegin = {
    type: FETCH_CURRENT_USER_INFO_BEGIN;
};

export const FETCH_CURRENT_USER_INFO_SUCCESS = 'FETCH_CURRENT_USER_INFO_SUCCESS';
export type FETCH_CURRENT_USER_INFO_SUCCESS = typeof FETCH_CURRENT_USER_INFO_SUCCESS;
export type FetchCurrentUserInfoSuccess = {
    type: FETCH_CURRENT_USER_INFO_SUCCESS;
    aplixmitarbeiterid?: number;
    currentUserFirstName?: string;
    currentUserLastName?: string;
};

export const FETCH_CURRENT_USER_INFO_ERROR = 'FETCH_CURRENT_USER_INFO_ERROR';
export type FETCH_CURRENT_USER_INFO_ERROR = typeof FETCH_CURRENT_USER_INFO_ERROR;
export type FetchCurrentUserInfoError = {
    type: FETCH_CURRENT_USER_INFO_ERROR;
    message: string;
};

export const UPDATE_AVAILABLE = 'UPDATE_AVAILABLE';
export type UPDATE_AVAILABLE = typeof UPDATE_AVAILABLE;
export type UpdateAvailable = {
    type: UPDATE_AVAILABLE;
    newVersion: string;
};


export const SET_BOOKMARK_LAST_PAGE = 'SET_BOOKMARK_LAST_PAGE';
export type SET_BOOKMARK_LAST_PAGE = typeof SET_BOOKMARK_LAST_PAGE;
export type SetBookmarkLastPage = {
    type: SET_BOOKMARK_LAST_PAGE;
    lastActivePage: RootPage;
}

export const SET_BOOKMARK_CONTRACT_FILTERS = 'SET_BOOKMARK_CONTRACT_FILTERS';
export type SET_BOOKMARK_CONTRACT_FILTERS = typeof SET_BOOKMARK_CONTRACT_FILTERS;
export type SetBookmarkContractFilters = {
    type: SET_BOOKMARK_CONTRACT_FILTERS;
    contractFilters: IContractFilterState;
}