import { ILogger, LogLevel } from '.';
import { injectable } from 'inversify';

@injectable()
export abstract class MultiplexLogger implements ILogger {
    protected loggers: ILogger[] = [];

    log(level: LogLevel, name: string, message?: string, properties?: {}, exception?: Error): void {
        for (const logger of this.loggers) {
            logger.log(level, name, message, properties, exception);
        }
    }
}
