import * as React from 'react';
import { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../../../ioc_constants';
import { IEditDetailStoreQueries } from '../../../../../../logic/IEditDetailStoreQueries';
import { useService } from '../../../../../../../../ioc/hook/useService';
import { EditDetailContext } from '../../../../EditDetailContext';
import { getService } from '../../../../../../../../ioc_config';
import { IEditDetailStoreActions } from '../../../../../../logic/IEditDetailStoreActions';

export const Decrement: React.FunctionComponent = () => {
    const detailStoreQueries = useService<IEditDetailStoreQueries>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREQUERIES
    );

    const [detailStore, setDetailStore] = useContext(EditDetailContext);
    const storeActions = getService<IEditDetailStoreActions>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREACTIONS
    );

    const decrementQuantity = () => {
        setDetailStore(storeActions.decrementQuantity(detailStore));
    };

    const decrementButtonDisabled = () => {
        return !detailStoreQueries.canDecrementQuantity(detailStore);
    };

    return (
        <Button
            size="medium"
            variant="contained"
            disabled={decrementButtonDisabled()}
            onClick={decrementQuantity}
        >
            <Icon>keyboard_arrow_down</Icon>
        </Button>
    );
};
