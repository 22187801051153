import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { WithStyles, withStyles } from '@material-ui/styles';
import { getLoginState } from '../../quino/redux';
import * as fromLogin from '../../quino/redux/reducers/login/';
import { AplixInput, AplixLogo, AplixPageTitle, AplixSwitch } from '../../components';
import { IState } from '../../redux';
import { IUpdater } from '../../core/updater';
import { ClientVersion, IAplixService, ILogger, LogLevel } from '../../core';
import { useService } from '../../ioc/hook/useService';
import APLIX_SERVICE_IDENTIFIER from '../../ioc_constants';
import { IEnvironmentInfoDTO } from '../../core/api/DTOs/IEnvironmentInfoDTO';
import classNames from 'classnames';
import { IScannerService } from '../../core/scanner/IScannerService';

const style = withStyles(({ palette, spacing }) => ({
    root: {
        flexGrow: 1,
        margin: 8,
    },
    verticalSpacing: {
        display: 'flex',
        flex: '1 1 0',
    },
    logoColumn: {},
    header: {
        marginBottom: spacing(1),
    },
    fields: {
        marginTop: spacing(1),
    },
    buttonBar: {
        marginTop: spacing(2),
    },
    rightIcon: {
        marginLeft: spacing(1),
    },
    versionInfo: {
        marginTop: spacing(1),
        fontSize: 'small',
        textAlign: 'right',
        color: palette.secondary.light,
    } as any,
    environmentIndicator: {
        backgroundColor: '#F2B535',
        float: 'right',
        display: 'inline',
        borderRadius: '4px',
        borderWidth: '8px',
        borderColor: '#F2B535',
        borderStyle: 'solid',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
}));

type TOwnProps = {};

type TStateProps = {
    errorMessage?: string;
    loginoutInProgress: boolean;
    isUpdateAvailable: boolean;
    currentVersion: string;
};

type TDispatchProps = {
    loginAsync: (userName: string, password: string) => void;
};

export type TProps = TOwnProps & TStateProps & TDispatchProps;

interface IProps
    extends WithStyles<
        | 'root'
        | 'verticalSpacing'
        | 'logoColumn'
        | 'header'
        | 'rightIcon'
        | 'fields'
        | 'buttonBar'
        | 'versionInfo'
        | 'environmentIndicator'
        | 'footer'
    > {}

const LoginPageComponent: React.FunctionComponent<IProps> = (props) => {
    const updateChecker = useService<IUpdater>(APLIX_SERVICE_IDENTIFIER.IUPDATER);
    const logger = useService<ILogger>(APLIX_SERVICE_IDENTIFIER.ILOGGER);
    const aplixService = useService<IAplixService>(APLIX_SERVICE_IDENTIFIER.IAPLIXSERVICE);
    const scannerService = useService<IScannerService>(APLIX_SERVICE_IDENTIFIER.ISCANNERSERVICE);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [scanITEnabled, setScanITEnabled] = useState(scannerService.enabled());

    const [environmentInfo, setEnvironmentInfo] = useState<IEnvironmentInfoDTO>();

    useEffect(() => {
        aplixService.currentEnvironmentInfoAsync().then((value) => setEnvironmentInfo(value));
    }, []);

    const dispatch = useDispatch();

    const loginAsync = (userName: string, password: string) => {
        return dispatch(fromLogin.loginAsync(userName, password));
    };

    useEffect(() => {
        void updateChecker.checkAndExecuteUpdateAsync();
        const intervalHandle = window.setInterval(
            () => updateChecker.checkAndExecuteUpdateAsync(),
            60000
        );
        return () => {
            window.clearInterval(intervalHandle);
        };
    }, []);

    const login = () => {
        if (loginAsync) {
            loginAsync(username ? username : '', password ? password : '');
            logger.log(LogLevel.Event, 'LOGGED_IN', `User ${username} requested to log in`, {
                username: username,
            });
        }
    };

    const handleChangeUsername = (event: any) => {
        setUsername(event.target.value || '');
    };

    const handleChangePassword = (event: any) => {
        setPassword(event.target.value || '');
    };

    const loginoutInProgress = useSelector(
        (state: IState) => getLoginState(state).isLoginInProgress
    );
    const errorMessage = useSelector((state: IState) => getLoginState(state).errorMessage);
    const currentVersion = ClientVersion;

    const { classes } = props;
    const sendDisabled = loginoutInProgress || !username || !password;

    return (
        <Grid container direction={'row'}>
            <Grid className={classes.verticalSpacing} />
            <Grid
                id="loginpage-root"
                container
                className={classes.root}
                spacing={2}
                alignItems="center"
            >
                <Grid id="column-logo" item={true} xs={6} className={classes.logoColumn}>
                    <AplixLogo />
                    <div className={classes.versionInfo}>Version: {currentVersion}</div>
                    <div className={classes.versionInfo}>
                        Datenbank:{' '}
                        {environmentInfo && environmentInfo.resource
                            ? environmentInfo.resource
                            : 'unbekannt'}
                    </div>
                    {environmentInfo && environmentInfo.isTestingEnvironment && (
                        <div
                            className={classNames(
                                classes.environmentIndicator,
                                classes.versionInfo
                            )}
                        >
                            TEST-Umgebung
                        </div>
                    )}
                </Grid>
                <Grid id="column-form" item={true} xs={6}>
                    <form autoComplete="off">
                        <div id="header" className={classes.header}>
                            <AplixPageTitle pageTitle="Anmelden" />
                        </div>

                        {errorMessage && <Typography color="error">{errorMessage}</Typography>}

                        <div>
                            <AplixInput
                                id="userName"
                                label="Benutzername"
                                value={username}
                                fullWidth
                                autoFocus
                                required
                                showKeyboardAdornment
                                showBarCodeAdornment
                                className={classes.fields}
                                onChange={handleChangeUsername}
                            />
                        </div>

                        <div>
                            <AplixInput
                                id="password"
                                label="Passwort"
                                value={password}
                                type="password"
                                fullWidth
                                required
                                showKeyboardAdornment
                                showBarCodeAdornment
                                className={classes.fields}
                                onChange={handleChangePassword}
                            />
                        </div>

                        <div className={classes.buttonBar}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={login}
                                disabled={sendDisabled}
                            >
                                Anmelden
                                {!loginoutInProgress && (
                                    <Icon className={classes.rightIcon}>send</Icon>
                                )}
                                {loginoutInProgress && (
                                    <CircularProgress size={24} style={{ marginLeft: 7 }} />
                                )}
                            </Button>
                        </div>
                    </form>
                </Grid>
            </Grid>
            <Grid className={`${classes.footer} ${classes.verticalSpacing}`}>
                {scannerService.licensePresent() && (
                    <AplixSwitch
                        id={'scanit-switch'}
                        value={scanITEnabled}
                        label={'Barcode-Scannereingabe'}
                        onChange={(event, checked) => {
                            scannerService.setEnabled(checked);
                            setScanITEnabled(checked);
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export const LoginPage = style(LoginPageComponent);
