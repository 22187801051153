import { TDispatchableReturn } from '../../../quino/core/redux';
import { IState } from '../../store';
import { getCurrentUserInfoState, getServerInfoState } from '../state';
import {
    fetchCurrentUserInfoBegin,
    fetchCurrentUserInfoError,
    fetchServerInfoBegin,
    fetchServerInfoError,
    fetchServerInfoSuccess,
    GlobalsAction,
} from '../actions';
import { aplixApiManager, getLogger, LogLevel } from '../../../core';
import { fetchCurrentUserInfoSuccess } from '../index';
import { LoginAction, loginError } from '../../../quino/redux';

/**
 * Queries the current logged in users information like firstname, lastname etc.
 */
export function fetchCurrentUserInfoAsync(
    forceFetch?: boolean
): TDispatchableReturn<
    GlobalsAction | LoginAction,
    IState
> {
    return async function(dispatch, getState) {
        const currentUserInfoState = getCurrentUserInfoState(getState());
        if (currentUserInfoState.isFetching || (currentUserInfoState.isUserInfoLoaded && !forceFetch)) {
            return Promise.resolve();
        }

        dispatch(fetchCurrentUserInfoBegin());
        try {
            const currentUserInfoResponse = await aplixApiManager().aplixService.currentUserInfoAsync();
            if (currentUserInfoResponse) {
                dispatch(
                    fetchCurrentUserInfoSuccess(
                        currentUserInfoResponse.aplixEmployeeId,
                        currentUserInfoResponse.firstName,
                        currentUserInfoResponse.lastName
                    )
                );
            }
        } catch (ex) {
            const msg =
                ex && ex.message
                    ? ex.message
                    : 'Fehler beim Laden der aktuellen Benutzerinformationen!';
            getLogger().log(LogLevel.Error, 'fetchCurrentUserInfoAsync', msg, null, ex);
            dispatch(fetchCurrentUserInfoError(msg));
            dispatch(loginError(msg));
        }
    };
}

export function fetchServerInfo(forceFetch?: boolean): TDispatchableReturn<GlobalsAction, IState> {
    return async function(dispatch, getState) {
        const serverInfoState = getServerInfoState(getState());
        if (serverInfoState.isFetching || (serverInfoState.isServerInfoLoaded && !forceFetch)) {
            return Promise.resolve();
        }

        dispatch(fetchServerInfoBegin());
        try {
            const serverInfo = await aplixApiManager().aplixService.serverInfoAsync();
            const environmentInfo = await aplixApiManager().aplixService.currentEnvironmentInfoAsync();
            if (serverInfo || environmentInfo) {
                dispatch(fetchServerInfoSuccess({ ...serverInfo, ...environmentInfo }));
            }
        } catch (ex) {
            const msg = ex && ex.message ? ex.message : 'Fehler beim Laden der Server Infos!';
            dispatch(fetchServerInfoError(msg));
        }
    };
}
