import * as React from 'react';
import { useContext, useState } from 'react';
import { AplixInput } from '../../../../../../../../components';
import { IEditQuantityContext } from '../../IEditQuantityContext';
import { QuantityInformation } from './QuantityInformation';
import { textTotalGeruestet } from './ValueLabel';
import { EditDetailContext } from '../../../../EditDetailContext';
import { useService } from '../../../../../../../../ioc/hook/useService';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../../../ioc_constants';
import { IEditDetailStoreActions } from '../../../../../../logic/IEditDetailStoreActions';

interface IProps {
    context: IEditQuantityContext;
}

interface IStyles {
    input: string;
}

const styles: IStyles = require('./ValueInput.less');

export const ValueInput: React.FunctionComponent<IProps> = (props) => {
    const [detailStore, setDetailStore] = useContext(EditDetailContext);

    const detailActions = useService<IEditDetailStoreActions>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREACTIONS
    );

    const [currentQuantity, setCurrentQuantity] = useState(detailStore.currentQuantity.toString());
    const [currentQuantityError, setCurrentQuantityError] = useState<string>();

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const rawValue = event.target.value;
        const value = parseFloat(rawValue);
        const isValid = value >= 0;

        props.context.setSaveEnabled(isValid);
        setCurrentQuantityError(isValid ? undefined : 'Ungültige Eingabe');

        setCurrentQuantity(rawValue);

        if (isValid) {
            const newDetailStore = detailActions.setQuantity(detailStore, value);
            setDetailStore(newDetailStore);
        }
    };

    return (
        <div>
            <AplixInput
                id="editQty"
                label={textTotalGeruestet}
                value={currentQuantity}
                type="number"
                className={styles.input}
                autoFocus
                inputProps={{ onFocus: (e: any) => e.target.select() }}
                showKeyboardAdornment
                onChange={onChangeInput}
                errorMessage={currentQuantityError}
            />
            <QuantityInformation detailStore={detailStore} />
        </div>
    );
};
