import { Container, ContainerModule, interfaces } from 'inversify';
import {
    ApiManager,
    AuthenticationTokenProvider,
    DEFAULT_SERVER_BASEURL,
    Fetcher,
    IApiManager,
    IAuthenticationTokenProvider,
    IDataService,
    IFetcher, IMarkdownConverter,
    IMetadataService,
    IQuinoServerServiceConfiguration,
    IUrlManager, MarkdownConverter,
    QuinoServerService,
    QuinoUrlManager,
} from '..';
import SERVICE_IDENTIFIER from './constants/identifiers';
import { Store } from 'redux';
import { IQuinoEnabledStore } from '../../redux';
import { IStorageService, LocalStorageService } from '../storage';
import { Converter } from 'showdown';

let quinoContainer: Container;

/**
 * Sets the global container to use for quino related IoC.
 * @see getQuinoContainer
 */
export function setQuinoContainer(container: Container) {
    quinoContainer = container;
}

/**
 * Returns the global container related for quino stuff.
 * @see setQuinoContainer
 */
export function getQuinoContainer(): Container {
    if (!quinoContainer) {
        throw new Error(
            'There is no QuinoContainer initialized yet! Use the function setQuinoContainer() to do so.'
        );
    }

    return quinoContainer;
}

/**
 * This is a InversifyJS container module to use by the application to get the default Quino
 */
export const quinoCoreModule = (getStore: () => Store<IQuinoEnabledStore>) => {
    return new ContainerModule((bind: interfaces.Bind) => {
        bind<Converter>(SERVICE_IDENTIFIER.MARKDOWNHTMLRENDERER).toDynamicValue(() => new Converter());
        bind<IMarkdownConverter>(SERVICE_IDENTIFIER.IMARKDOWNCONVERTER)
            .to(MarkdownConverter)
            .inSingletonScope();
        bind<Store<IQuinoEnabledStore>>(SERVICE_IDENTIFIER.IQUINOENABLEDSTORE).toDynamicValue(
            () => {
                return getStore();
            }
        );
        bind<IApiManager>(SERVICE_IDENTIFIER.IAPIMANAGER)
            .to(ApiManager)
            .inSingletonScope();
        
    });
};

/**
 * This is a InversifyJS container module to use by the application to get the Quino-Server connection registred properly with the IoC container.
 */
export const quinoServerModule = (baseUrl: string = DEFAULT_SERVER_BASEURL) =>
    new ContainerModule((bind: interfaces.Bind) => {
        bind<IQuinoServerServiceConfiguration>(
            SERVICE_IDENTIFIER.IQUINOSERVERSERVICECONFIGURATION
        ).toConstantValue({ baseUrl });
        bind<QuinoUrlManager>(QuinoUrlManager).toConstantValue(new QuinoUrlManager({ baseUrl }));
        bind<IFetcher>(SERVICE_IDENTIFIER.IFETCHER).to(Fetcher);
        bind<IUrlManager>(SERVICE_IDENTIFIER.IURLMANAGER).to(QuinoUrlManager);
        bind<QuinoServerService>(QuinoServerService)
            .toSelf()
            .inSingletonScope();
        bind<IDataService>(SERVICE_IDENTIFIER.IDATASERVICE).to(QuinoServerService);
        bind<IMetadataService>(SERVICE_IDENTIFIER.IMETADATASERVICE).to(QuinoServerService);
        bind<IAuthenticationTokenProvider>(SERVICE_IDENTIFIER.IAUTHENTICATIONTOKENPROVIDER)
            .to(AuthenticationTokenProvider)
            .inSingletonScope();
        bind<Storage>(SERVICE_IDENTIFIER.ISTORAGE).toConstantValue(localStorage);
        bind<IStorageService>(SERVICE_IDENTIFIER.ILOCALSTORAGESERVICE)
            .to(LocalStorageService)
            .inSingletonScope();
    });
