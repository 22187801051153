import { inject, injectable } from 'inversify';
import {
    IAuthenticationTokenProvider,
    IFetcher,
    IUrlManager,
    QuinoServerService,
    TPrimaryKey,
} from '../../quino/core';
import SERVICE_IDENTIFIER from '../../quino/core/ioc/constants/identifiers';
import { IAplixService, IAplixUrlManager } from '..';
import APLIX_SERVICE_IDENTIFIER from '../../ioc_constants';
import { IServerInfo } from './IServerInfo';
import { IUserInfo } from './IUserInfo';
import { ISaveContractDetailDTO } from './DTOs/ISaveContractDetailDTO';
import { IFullContractDTO } from './DTOs/IFullContractDTO';
import { ISaveContractPropertyDTO } from './DTOs/ISaveContractPropertyDTO';
import { IEnvironmentInfoDTO } from './DTOs/IEnvironmentInfoDTO';

@injectable()
export class AplixServerService extends QuinoServerService implements IAplixService {
    aplixUrlManager: IAplixUrlManager;

    constructor(
        @inject(SERVICE_IDENTIFIER.IURLMANAGER) urlManager: IUrlManager,
        @inject(SERVICE_IDENTIFIER.IFETCHER) fetcher: IFetcher,
        @inject(APLIX_SERVICE_IDENTIFIER.IAPLIXURLMANAGER) aplixUrlManager: IAplixUrlManager,
        @inject(SERVICE_IDENTIFIER.IAUTHENTICATIONTOKENPROVIDER)
        authTokenProvider: IAuthenticationTokenProvider
    ) {
        super(urlManager, fetcher, authTokenProvider);
        this.aplixUrlManager = aplixUrlManager;
    }

    async currentEnvironmentInfoAsync(): Promise<IEnvironmentInfoDTO> {
        const url = this.aplixUrlManager.getEnvironmentInfoUrl();
        return await this.fetcher.fetchAsync<IEnvironmentInfoDTO>(url);
    }

    async currentUserInfoAsync(): Promise<IUserInfo> {
        const url = this.aplixUrlManager.getCurrentUserInfoUrl();
        return await this.fetcher.fetchAsync<IUserInfo>(url);
    }

    async serverInfoAsync(): Promise<IServerInfo> {
        const url = this.aplixUrlManager.getServerInfoUrl();
        return await this.fetcher.fetchAsync<IServerInfo>(url);
    }

    async getContractAsync(contractId: TPrimaryKey): Promise<IFullContractDTO> {
        const url = this.aplixUrlManager.getContractUrl(contractId);
        return this.fetcher.fetchAsync<IFullContractDTO>(url);
    }

    async startContractAsync(contractId: TPrimaryKey): Promise<boolean> {
        const url = this.aplixUrlManager.getStartContractUrl(contractId);
        return await this.fetcher.postAsync<any>(url, {});
    }

    async pauseContractAsync(contractId: TPrimaryKey): Promise<boolean> {
        const url = this.aplixUrlManager.getPauseContractUrl(contractId);
        return await this.fetcher.postAsync<any>(url, {});
    }

    async continueContractAsync(contractId: TPrimaryKey): Promise<boolean> {
        const url = this.aplixUrlManager.getContinueContractUrl(contractId);
        return await this.fetcher.postAsync<any>(url, {});
    }

    async finishContractAsync(contractId: TPrimaryKey): Promise<boolean> {
        const url = this.aplixUrlManager.getFinishContractUrl(contractId);
        return await this.fetcher.postAsync<any>(url, {});
    }

    async updateContractProperties(
        contractId: TPrimaryKey,
        model: ISaveContractPropertyDTO[]
    ): Promise<void> {
        const url = this.aplixUrlManager.getContractPropertiesUrl(contractId);
        await this.fetcher.patchAsync<any>(url, model);
    }

    async preparePositionAsync(
        contractDetailId: TPrimaryKey,
        model: ISaveContractDetailDTO
    ): Promise<boolean> {
        const url = this.aplixUrlManager.getPreparePositionUrl(contractDetailId);
        return await this.fetcher.patchAsync<any>(url, model);
    }

    pauseDetailAsync(contractDetailId: string): Promise<void> {
        const url = this.aplixUrlManager.getPauseDetailUrl(contractDetailId);
        return this.fetcher.patchAsync<any>(url, {});
    }

    unpauseDetailAsync(contractDetailId: string): Promise<void> {
        const url = this.aplixUrlManager.getUnpauseDetailUrl(contractDetailId);
        return this.fetcher.patchAsync<any>(url, {});
    }
}
