import { TDispatchableReturn } from '../../../quino/core/redux';
import { IState, TAplixAction } from '../../store';
import { ContractsAction, fetchContinuableContract, getCurrentContractState } from '..';
import {
    fetchDetailsBegin,
    fetchDetailsError,
    fetchDetailsSuccess,
    pauseBegin,
    pauseDetail,
    pauseDetailError,
    pauseError,
    pauseSuccess,
    queueSaveDetailRequest,
    saveBegin,
    saveDetailError,
    saveDetailStart,
    saveDetailSuccess,
    saveError,
    saveSuccess,
    selectCurrentContract,
    setContractDetails,
    setContractProperties,
    unpauseDetail,
    unselectCurrentContract,
    updateDetail,
} from '../actions';
import { getLogger, LogLevel } from '../../../core/logging';
import { aplixApiManager, IContract } from '../../../core';
import { GlobalsAction, switchRootPage } from '../../globals/actions';
import { RootPage } from '../../globals';
import { ThunkAction } from 'redux-thunk';
import { IContractProperty } from '../../../core/entities/IContractProperty';
import { ISaveContractPropertyDTO } from '../../../core/api/DTOs/ISaveContractPropertyDTO';
import { IContractDetail } from '../../../core/entities/IContractDetail';
import { QtyValidationState } from '../../../pages/PrepareContractPage/logic';
import { TPrimaryKey } from '../../../quino/core/data';
import { getPendingSaveDetailRequest, isSaveDetailQueueEmpty, isSavingDetail, } from '../saveDetailState';

/**
 * Fetches the contract details for the currently selected contract.
 * @param {boolean} forceFetch - Force a new fetch even if 'isLoaded' is loaded already.
 */
export function fetchDetailsAsync(
    forceFetch?: boolean
): TDispatchableReturn<ContractsAction, IState> {
    return async function(dispatch, getState) {
        const state = getCurrentContractState(getState());

        if (state.isFetching || (state.isLoaded && !forceFetch)) {
            return Promise.resolve();
        }

        if (!state.contract) {
            getLogger().log(
                LogLevel.Error,
                'fetchDetailsAsync',
                'No current contract found in state!'
            );
            throw Error('No current contract found in state!');
        }

        if (!state.contract.primaryKey) {
            getLogger().log(
                LogLevel.Error,
                'fetchDetailsAsync',
                'No primaryKey set on the current contract in state!'
            );
            throw Error('No primaryKey set on the current contract in state!');
        }

        dispatch(fetchDetailsBegin());
        try {
            const result = await aplixApiManager().aplixService.getContractAsync(
                state.contract.primaryKey
            );
            dispatch(fetchDetailsSuccess(result));
        } catch (ex) {
            getLogger().log(LogLevel.Error, 'fetchDetailsAsync', ex.message, null, ex);
            dispatch(fetchDetailsError(ex.message));
        }
    };
}

export const openContract = (contract: IContract): ThunkAction<void, IState, {}, TAplixAction> => {
    return (dispatch) => {
        dispatch(selectCurrentContract(contract));
        dispatch(fetchDetailsAsync());
        dispatch(switchRootPage(RootPage.PrepareContractPage));
    };
};

/**
 * Pause the current contract.
 */
export function pauseAsync(): TDispatchableReturn<ContractsAction | GlobalsAction, IState> {
    return async function(dispatch, getState) {
        const state = getCurrentContractState(getState());

        if (state.isPausing) {
            return Promise.resolve();
        }

        if (!state.contract) {
            getLogger().log(
                LogLevel.Error,
                'fetchDetailsAsync',
                'No current contract found in state!'
            );
            throw Error('No current contract found in state!');
        }

        if (!state.contract.primaryKey) {
            getLogger().log(
                LogLevel.Error,
                'fetchDetailsAsync',
                'No primaryKey set on the current contract in state!'
            );
            throw Error('No primaryKey set on the current contract in state!');
        }

        dispatch(pauseBegin());
        try {
            await aplixApiManager().aplixService.pauseContractAsync(state.contract.primaryKey);

            // TODO: This is unnecessary in my opinion. A request should never return a 200 code AND an error message.
            // if (result.errors) {
            //     dispatch(pauseError(result.errors.map((e) => e.message).join(' ')));
            // } else {
            dispatch(pauseSuccess());
            dispatch(unselectCurrentContract(getState()));
            dispatch(fetchContinuableContract(true) as any);
            dispatch(switchRootPage(getState().globals.bookmark.lastActivePage));
            // }
        } catch (ex) {
            const msg = typeof ex === 'string' ? ex : ex.message;
            getLogger().log(LogLevel.Error, 'pauseContractAsync', msg, null, ex);
            dispatch(pauseError(msg));
        }
    };
}

export const updateContractPropertiesAsync = (
    properties: IContractProperty[]
): ThunkAction<void, IState, {}, TAplixAction> => {
    return async (dispatch, getState) => {
        const state = getCurrentContractState(getState());

        if (!state.contract) {
            getLogger().log(
                LogLevel.Error,
                'fetchDetailsAsync',
                'No current contract found in state!'
            );
            throw Error('No current contract found in state!');
        }

        if (!state.contract.primaryKey) {
            getLogger().log(
                LogLevel.Error,
                'fetchDetailsAsync',
                'No primaryKey set on the current contract in state!'
            );
            throw Error('No primaryKey set on the current contract in state!');
        }

        const dto: ISaveContractPropertyDTO[] = properties.map((property) => ({
            primaryKey: property.primaryKey,
            value: property.value,
        }));

        try {
            dispatch(saveBegin());
            await aplixApiManager().aplixService.updateContractProperties(
                state.contract.primaryKey,
                dto
            );
            dispatch(setContractProperties(properties));
            dispatch(saveSuccess());
        } catch (ex) {
            const msg = typeof ex === 'string' ? ex : ex.message;
            getLogger().log(LogLevel.Error, 'saveContractProperties', msg, null, ex);
            dispatch(saveError(msg));
        }
    };
};

export const processSaveDetailQueue = (
    primaryKey: TPrimaryKey
): ThunkAction<void, IState, {}, TAplixAction> => {
    return async (dispatch, getState) => {
        if (
            isSaveDetailQueueEmpty(getState(), primaryKey) ||
            isSavingDetail(getState(), primaryKey)
        ) {
            return;
        }

        dispatch(saveDetailStart(primaryKey));

        const pendingRequest = getPendingSaveDetailRequest(getState(), primaryKey);

        if (!pendingRequest) {
            throw Error('Internal error: Pending request must not be null');
        }

        try {
            await aplixApiManager().aplixService.preparePositionAsync(
                primaryKey,
                pendingRequest.model
            );

            const currentContractState = getCurrentContractState(getState());
            if (currentContractState.contract && currentContractState.contract.primaryKey) {
                const reloadedContract = await aplixApiManager().aplixService.getContractAsync(currentContractState.contract.primaryKey);
                
                const newContractDetailsIds = reloadedContract.details.map((d) => d.primaryKey);
                const currentContractDetailsIds = (currentContractState.details ? currentContractState.details : []).map((d) => d.primaryKey);
                
                const hasNewDetails = newContractDetailsIds.some((id) => !currentContractDetailsIds.includes(id));
                if (hasNewDetails) {
                    dispatch(setContractDetails(reloadedContract.details));
                }
            }

            dispatch(saveDetailSuccess(primaryKey));
        } catch (exception) {
            const errorMessage = `Konnte Detail '${primaryKey}' nicht speichern. ${exception}`;
            dispatch(saveDetailError(primaryKey, errorMessage));
        }

        dispatch(processSaveDetailQueue(primaryKey));
    };
};

/**
 * Saves a detail on the server
 * @param detail
 */
export const saveDetailAsync = (
    detail: IContractDetail
): ThunkAction<void, IState, {}, TAplixAction> => {
    return (dispatch) => {
        dispatch(updateDetail(detail));

        const { primaryKey, currentQuantity, validation, lots, places, properties } = detail;

        const model = {
            currentQuantity,
            isComplete: validation === QtyValidationState.Completed,
            lots,
            places,
            properties,
        };

        dispatch(queueSaveDetailRequest(primaryKey, model));
        dispatch(processSaveDetailQueue(primaryKey));
    };
};

export const pauseContractDetailAsync = (
    primaryKey: TPrimaryKey
): ThunkAction<void, IState, {}, TAplixAction> => {
    return async (dispatch) => {
        dispatch(pauseDetail(primaryKey));
        try {
            await aplixApiManager().aplixService.pauseDetailAsync(primaryKey);
        } catch (exception) {
            const errorMessage = `Konnte Detail '${primaryKey}' nicht pausieren. ${exception}`;
            dispatch(pauseDetailError(errorMessage));
            // Rollback
            dispatch(unpauseDetail(primaryKey));
        }
    };
};

export const unpauseContractDetailAsync = (
    primaryKey: TPrimaryKey
): ThunkAction<void, IState, {}, TAplixAction> => {
    return async (dispatch) => {
        dispatch(unpauseDetail(primaryKey));
        try {
            await aplixApiManager().aplixService.unpauseDetailAsync(primaryKey);
        } catch (exception) {
            const errorMessage = `Konnte Detail '${primaryKey}' nicht fortsetzen. ${exception}`;
            dispatch(pauseDetailError(errorMessage));
            // Rollback
            dispatch(pauseDetail(primaryKey));
        }
    };
};
