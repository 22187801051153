import * as React from 'react';
import { IContractDetail } from './logic';

export interface IPrepareContractContext {
    /**
     * Dispatches saving the detail to the backend
     * @param detailStore: The detailStore to save
     */
    saveDetailStore: (detailStore: IContractDetail) => void;

    /**
     * Proceed with the workflow
     */
    goToNextStep: () => void;
    goToListPage: () => void;
    goToDetailPage: (store: IContractDetail) => void;
    goToEditPropertiesPage: (store: IContractDetail) => void;
    detailScrollIndex: number;
    setDetailScrollIndex: (index: number) => void;
    showAllContractDetailsEnabled: boolean, 
    setShowAllContractDetailsEnabled: (enable: boolean) => void,

    /**
     * Reload the details and properties from the API,
     * possibly overwriting local changes
     */
    reload: () => void;
}

export const PrepareContractContext = React.createContext<IPrepareContractContext>(
    {} as IPrepareContractContext
);
