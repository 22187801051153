import * as React from 'react';

type TStyles = {
    aplixErrorBoxRoot: string;
};

const styles: TStyles = require('./AplixErrorBox.less');

export type TProps = {
    message?: string;
    children?: any;
};

export const AplixErrorBox = (props: TProps) => (
    <div className={styles.aplixErrorBoxRoot}>
        {props.message && <p>{props.message}</p>}
        {props.children}
    </div>
);
