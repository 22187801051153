import { IMarkdownConverter } from './IMarkdownConverter';
import { inject, injectable } from 'inversify';
import { Converter } from 'showdown';
import SERVICE_IDENTIFIER from '../../ioc/constants/identifiers';

const markdownPrefix = '[markdown]';

@injectable()
export class MarkdownConverter implements IMarkdownConverter {
    constructor(@inject(SERVICE_IDENTIFIER.MARKDOWNHTMLRENDERER) private readonly htmlRenderer: Converter) {
        htmlRenderer.setFlavor('github');
        htmlRenderer.setOption('tables', true);
    }

    convertToHtml(text: string): string {
        return this.htmlRenderer.makeHtml(text.replace(markdownPrefix, ''));
    }

    isMarkdown(text: any): boolean {
        return typeof text === 'string' ? text.toLocaleLowerCase().startsWith(markdownPrefix) : false;
    }
}
