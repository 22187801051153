import { IStartableContractsState } from '../state';
import { ContractsAction } from '..';

const initial: IStartableContractsState = {
    contracts: [],
    isLoaded: false,
    isFetching: false,
    errorMessage: '',
};

export function reducer(
    state: IStartableContractsState = initial,
    action: ContractsAction
): IStartableContractsState {
    switch (action.type) {
        case 'SELECT_CURRENT_CONTRACT':
            return {
                ...state,
                contracts: state.contracts.filter(
                    (c) => c.primaryKey !== action.currentContract.primaryKey
                ),
            };

        case 'FETCH_STARTABLE_CONTRACTS_BEGIN':
            return {
                ...state,
                errorMessage: undefined,
                isLoaded: false,
                isFetching: true,
            };

        case 'FETCH_STARTABLE_CONTRACTS_SUCCESS':
            return {
                ...state,
                isLoaded: true,
                isFetching: false,
                errorMessage: undefined,
                contracts: action.startableContracts ? action.startableContracts : [],
            };

        case 'FETCH_STARTABLE_CONTRACTS_ERROR':
            return {
                ...state,
                isLoaded: true,
                isFetching: false,
                errorMessage: action.errorMessage,
            };

        case 'UNLOAD_STARTABLE_CONTRACTS':
            return {
                ...state,
                isLoaded: false,
                contracts: []
            };

    }

    return state;
}
