import { IContractDetailDTO } from '../../../../core/entities';
import { IContractDetail } from '../../../../core/entities/IContractDetail';

export const getFirstAvailableBarcode = (details: IContractDetailDTO[]): string | undefined => {
    const detailsWithBarcodes = details.filter((d) => d.barcode001 || d.barcode002 || d.barcode003);

    if (detailsWithBarcodes.length > 0) {
        const detail = detailsWithBarcodes[0];
        return detail.barcode001 || detail.barcode002 || detail.barcode003;
    }

    return undefined;
};

export const hasEmptyRequiredProperties = (detail: IContractDetail): boolean => {
    return detail.properties.filter(prop => prop.isRequired != null && prop.isRequired).filter(x => x.value === '' || x.value == null).length > 0    
}
