import { StartContractAction } from '../actions/index';
import {
    STARTCONTRACT_INIT,
    STARTCONTRACT_START_REVIEW,
    STARTCONTRACT_CANCEL_REVIEW,
    STARTCONTRACT_ERROR,
    STARTCONTRACT_POST_BEGIN,
    STARTCONTRACT_POST_SUCCESS,
    STARTCONTRACT_GOBACK_TO_REVIEW,
} from '../actions/types';
import { IStartContractState } from '../state';

const initialState: IStartContractState = {
    isFetching: false,
    isReviewing: false,
};

export function reducer(
    state: IStartContractState = initialState,
    action: StartContractAction
): IStartContractState {
    switch (action.type) {
        case STARTCONTRACT_INIT:
            return {
                ...state,
                isFetching: false,
                isReviewing: false,
                errorMessage: undefined,
                contractToStart: undefined,
            };

        case STARTCONTRACT_START_REVIEW:
            return {
                ...state,
                isReviewing: true,
                errorMessage: undefined,
                contractToStart: action.contract,
            };

        case STARTCONTRACT_ERROR:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.message,
            };

        case STARTCONTRACT_CANCEL_REVIEW:
            return {
                ...state,
                isReviewing: false,
            };

        case STARTCONTRACT_POST_BEGIN:
            return {
                ...state,
                isFetching: true,
                errorMessage: undefined,
            };

        case STARTCONTRACT_POST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                errorMessage: undefined,
            };

        case STARTCONTRACT_GOBACK_TO_REVIEW:
            return {
                ...state,
                isFetching: false,
                errorMessage: undefined,
                isReviewing: true,
            };
    }

    return state;
}
