import {
    FETCH_CURRENT_USER_INFO_BEGIN,
    FETCH_CURRENT_USER_INFO_ERROR,
    FETCH_CURRENT_USER_INFO_SUCCESS,
    GlobalsAction,
    ICurrentUserInfoState,
} from '..';

const initial: ICurrentUserInfoState = {
    isUserInfoLoaded: false,
    isFetching: false,
};

export function currentUserInfoReducer(
    state: ICurrentUserInfoState = initial,
    action: GlobalsAction
): ICurrentUserInfoState {
    switch (action.type) {
        case FETCH_CURRENT_USER_INFO_BEGIN:
            return {
                ...state,
                errorMessage: undefined,
                isUserInfoLoaded: false,
                isFetching: true,
            };

        case FETCH_CURRENT_USER_INFO_SUCCESS:
            return {
                ...state,
                errorMessage: undefined,
                isUserInfoLoaded: true,
                isFetching: false,
                currentUserFirstName: action.currentUserFirstName,
                currentUserLastName: action.currentUserLastName,
                aplixEmployeeId: action.aplixmitarbeiterid,
            };

        case FETCH_CURRENT_USER_INFO_ERROR:
            return {
                ...state,
                errorMessage: action.message,
                isUserInfoLoaded: false,
                isFetching: false,
                currentUserFirstName: undefined,
                currentUserLastName: undefined,
            };
    }

    return state;
}
