import * as React from 'react';
import Button from '@material-ui/core/Button';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

require('../../../../quino/styles/mui_tweaks/badge.less');

const styles = {
    root: {
        height: '100%',
        fontSize: 18,
    },
    buttonText: {
        marginLeft: 5,
    },
};

interface IProps extends WithStyles<keyof typeof styles> {
    id: string;
    title: string;
    icon?: React.ComponentType<SvgIconProps>;
    disabled: boolean;
    highlighted: boolean;
    badgeNumber?: number;
    onClick?: () => void;
}

const style = withStyles(() => styles);

export const StartMenuItem = style((props: IProps) => {
    return (
        <Button
            id={props.id}
            size="large"
            fullWidth
            variant="contained"
            disabled={props.disabled}
            color={props.highlighted ? 'primary' : 'default'}
            onClick={props.onClick}
            className={props.classes.root}
        >
            {props.icon && React.createElement(props.icon)}
            <span className={props.classes.buttonText}>{props.title}</span>
        </Button>
    );
});
