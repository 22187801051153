import { DatePicker } from '@material-ui/pickers';
import * as React from 'react';
import { DatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker';

interface IProps
    extends Pick<DatePickerProps, 'label' | 'required' | 'value' | 'onChange' | 'autoFocus'> {}

export const AplixDatePicker: React.FunctionComponent<IProps> = (props) => {
    return (
        <DatePicker
            label={props.label}
            value={props.value}
            required={props.required}
            onChange={props.onChange}
            clearable={!props.required}
            autoFocus={props.autoFocus}
            fullWidth
            format={'DD.MM.YYYY'}
            variant={'dialog'}
            cancelLabel={'Abbrechen'}
            invalidLabel={'Ungültig'}
            okLabel={'Ok'}
            todayLabel={'Heute'}
            invalidDateMessage={'Ungültiges Datum'}
            clearLabel={'Zurücksetzen'}
        />
    );
};
