import {
    FINISH_CONTRACT_BEGIN,
    FINISH_CONTRACT_END,
    FINISH_CONTRACT_ERROR,
    FINISH_CONTRACT_SUCCESS,
    FinishContractBegin,
    FinishContractEnd,
    FinishContractError,
    FinishContractSuccess,
} from './types';

export function finishContractBegin(contractNumber: string): FinishContractBegin {
    return {
        type: FINISH_CONTRACT_BEGIN,
        payload: { contractNumber },
    };
}

export function finishContractSuccess(): FinishContractSuccess {
    return {
        type: FINISH_CONTRACT_SUCCESS,
    };
}

export function finishContractError(errorMessage: string): FinishContractError {
    return {
        type: FINISH_CONTRACT_ERROR,
        payload: { errorMessage },
    };
}

export function finishContractEnd(): FinishContractEnd {
    return {
        type: FINISH_CONTRACT_END,
    };
}
