import * as React from 'react';
import moment from 'moment';
import 'reflect-metadata';
import { ThemeProvider } from '@material-ui/styles';
import createMuiTheme from '@material-ui/core/styles/createTheme';
import { Provider } from 'react-redux';
import { getStore } from './redux/store';
import { PageSelector } from './pages';
import { ContainerContext } from './ioc/hook/ContainerContext';
import { getQuinoContainer } from './quino/core/ioc';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialPickerUtils } from './MaterialPickerUtils';

require('./App.less');

// Note: Ignore the ResizeObserver common errors
window.addEventListener('error', e => {
    if (e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
        e.message === 'ResizeObserver loop limit exceeded') {
        e.stopImmediatePropagation();
    }
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#509E2F',
            contrastText: '#fff',
        },
        secondary: {
            main: '#333333',
            contrastText: '#fff',
        },
    },
    overrides: {
        MuiTableCell: {
            head: {
                fontWeight: 'bold',
            },
        },
    },
});

moment.locale('de');

export class App extends React.Component {
    render() {
        return (
            <ContainerContext.Provider value={{ container: getQuinoContainer() }}>
                <Provider store={getStore()}>
                    <ThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={MaterialPickerUtils} locale="de-CH">
                            <PageSelector />
                        </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </Provider>
            </ContainerContext.Provider>
        );
    }
}
