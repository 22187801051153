import { ICurrentContractState } from '../state';
import { ContractsAction } from '..';
import { ContractDetailStatus, IContractDetailDTO } from '../../../core/entities';
import { QtyValidationState } from '../../../pages/PrepareContractPage/logic';
import { TPrimaryKey } from '../../../quino/core/data';
import { ISaveDetailState } from '../saveDetailState';

const initial: ICurrentContractState = {
    isLoaded: false,
    isPausing: false,
    isFetching: false,
    isSaving: false,
    properties: [],
    saveDetail: {},
};

function updateSaveDetailState(
    state: ICurrentContractState,
    primaryKey: TPrimaryKey,
    update: ISaveDetailState[string]
) {
    return {
        ...state,
        saveDetail: {
            ...state.saveDetail,
            [primaryKey]: {
                ...state.saveDetail[primaryKey],
                ...update,
            },
        },
    };
}

function getSaveDetailState(state: ICurrentContractState, primaryKey: TPrimaryKey) {
    const saveDetailState = state.saveDetail[primaryKey];
    if (!saveDetailState) {
        throw Error(`Internal error: No saveDetailState set for detail '${primaryKey}'`);
    }
    return saveDetailState;
}

function getQueuedRequest(state: ICurrentContractState, primaryKey: TPrimaryKey) {
    return getSaveDetailState(state, primaryKey).queuedRequest;
}

function getPendingRequest(state: ICurrentContractState, primaryKey: TPrimaryKey) {
    return getSaveDetailState(state, primaryKey).pendingRequest;
}

function updateDetail(
    state: ICurrentContractState,
    primaryKey: TPrimaryKey,
    update: Partial<IContractDetailDTO>
): ICurrentContractState {
    return {
        ...state,
        details:
            state.details &&
            state.details.map((detail: IContractDetailDTO) => {
                if (detail.primaryKey !== primaryKey) {
                    return detail;
                }
                return {
                    ...detail,
                    ...update,
                };
            }),
    };
}

export function reducer(
    state: ICurrentContractState = initial,
    action: ContractsAction
): ICurrentContractState {
    switch (action.type) {
        case 'SELECT_CURRENT_CONTRACT':
            return {
                contract: action.currentContract,
                isLoaded: false,
                isPausing: false,
                isFetching: false,
                isSaving: false,
                properties: [],
                saveDetail: {},
            };

        case 'UNSELECT_CURRENT_CONTRACT':
            return {
                ...state,
                contract: undefined,
            };

        case 'FINISH_CURRENT_CONTRACT':
            return {
                ...state,
                contract: undefined,
            };

        case 'FETCH_DETAILS_BEGIN':
            return {
                ...state,
                isLoaded: false,
                isFetching: true,
                errorMessage: undefined,
                details: undefined,
                properties: [],
                saveDetail: {},
            };

        case 'FETCH_DETAILS_SUCCESS':
            return {
                ...state,
                isFetching: false,
                isLoaded: true,
                details: action.details,
                properties: action.properties,
            };

        case 'FETCH_DETAILS_ERROR':
            return {
                ...state,
                isFetching: false,
                errorMessage: action.errorMessage,
            };

        case 'PAUSE_BEGIN':
            return {
                ...state,
                isPausing: true,
                errorMessage: undefined,
            };

        case 'PAUSE_SUCCESS':
            return {
                ...state,
                isPausing: false,
                errorMessage: undefined,
            };

        case 'PAUSE_ERROR':
            return {
                ...state,
                isPausing: false,
                errorMessage: action.errorMessage,
            };

        case 'ACCEPT_ERROR':
            return {
                ...state,
                errorMessage: undefined,
            };

        case 'SAVE_BEGIN':
            return {
                ...state,
                isSaving: true,
                errorMessage: undefined,
            };

        case 'SAVE_SUCCESS':
            return {
                ...state,
                isSaving: false,
            };

        case 'SAVE_ERROR':
            return {
                ...state,
                isSaving: false,
                errorMessage: action.errorMessage,
            };

        case 'SET_CONTRACT_PROPERTIES':
            return {
                ...state,
                properties: action.contractProperties,
            };

        case 'SET_CONTRACT_DETAILS':
            return {
                ...state,
                details: action.contractDetails,
            }
            
        case 'UPDATE_DETAIL': {
            const newDetail = action.contractDetail;
            return updateDetail(state, newDetail.primaryKey, {
                mengeGeruestet: newDetail.currentQuantity,
                status:
                    newDetail.validation === QtyValidationState.Completed
                        ? ContractDetailStatus.Prepared
                        : ContractDetailStatus.PartiallyPrepared,
                lots: [...newDetail.lots],
                places: [...newDetail.places],
                properties: [...newDetail.properties],
            });
        }
        case 'QUEUE_SAVE_DETAIL_REQUEST':
            return updateSaveDetailState(state, action.primaryKey, {
                queuedRequest: {
                    primaryKey: action.primaryKey,
                    model: action.model,
                },
            });
        case 'SAVE_DETAIL_START': {
            const queuedRequest = getQueuedRequest(state, action.primaryKey);

            if (!queuedRequest) {
                throw Error('Internal error: No queued request found');
            }

            if (getPendingRequest(state, action.primaryKey)) {
                throw Error('Internal error: Cannot start saving, a request is still pending');
            }

            return updateSaveDetailState(state, action.primaryKey, {
                queuedRequest: undefined,
                pendingRequest: queuedRequest,
            });
        }
        case 'SAVE_DETAIL_SUCCESS':
            if (!getPendingRequest(state, action.primaryKey)) {
                throw Error('Internal error: No request pending');
            }
            return updateSaveDetailState(state, action.primaryKey, {
                pendingRequest: undefined,
            });

        case 'SAVE_DETAIL_ERROR': {
            const pendingRequest = getPendingRequest(state, action.primaryKey);
            if (!pendingRequest) {
                throw Error('Internal error: No request pending');
            }

            if (getQueuedRequest(state, action.primaryKey)) {
                return updateSaveDetailState(state, action.primaryKey, {
                    pendingRequest: undefined,
                    failedRequest: undefined,
                });
            }

            return updateSaveDetailState(state, action.primaryKey, {
                pendingRequest: undefined,
                failedRequest: {
                    ...pendingRequest,
                    errorMessage: action.errorMessage,
                },
            });
        }
        case 'PAUSE_DETAIL':
            return updateDetail(state, action.primaryKey, {
                status: ContractDetailStatus.Paused,
            });
        case 'UNPAUSE_DETAIL':
            return updateDetail(state, action.primaryKey, {
                status: ContractDetailStatus.PartiallyPrepared,
            });
        case 'PAUSE_DETAIL_ERROR': {
            return {
                ...state,
                errorMessage: action.errorMessage,
            };
        }
    }

    return state;
}
