import * as React from 'react';
import { useContext, useMemo } from 'react';
import { EditDetailContext } from '../../EditDetailContext';
import { ILot } from '../../../../../../core/entities/ILot';
import { useService } from '../../../../../../ioc/hook/useService';
import { IEditDetailStoreQueries } from '../../../../logic/IEditDetailStoreQueries';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../ioc_constants';
import { EditAmount } from '../EditAmount';

interface IProps {
    lot: Partial<ILot>;
    goBack: () => void;
    save: (lot: ILot) => void;
}

export const EditLotAmount: React.FunctionComponent<IProps> = ({ lot, goBack, ...props }) => {
    const [detailStore] = useContext(EditDetailContext);

    const detailStoreQueries = useService<IEditDetailStoreQueries>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREQUERIES
    );

    const { currentQuantity } = detailStore;

    const remainingAmount = useMemo(() => {
        const lotsAmount = detailStoreQueries.getLotsAmount(detailStore);
        const result = currentQuantity - lotsAmount + (lot.amount || 0);
        return detailStoreQueries.roundAmount(detailStore, result);
    }, [detailStore, lot.amount]);

    const save = (amount: number) => {
        props.save({ ...lot, amount } as ILot);
    };

    return (
        <EditAmount
            goBack={goBack}
            initialValue={lot.amount || remainingAmount}
            amountType={'Charge'}
            save={save}
            maxAmount={remainingAmount}
            capacity={lot.amount}
        />
    );
};
