import * as React from 'react';
import { ContractTable } from '../../../../components/ContractTable/ContractTable';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { IContract } from '../../../../core/entities';
import { sortBy } from 'lodash';

interface IProps {
    contracts: IContract[];
    onSubmit: (contractNumber: string) => void;
}

export const List: React.FunctionComponent<IProps> = (props) => {
    const startContract = (contract: IContract) => props.onSubmit(contract.barcode);

    const sortedContracts = sortBy(
        sortBy(props.contracts, (contract) => contract.kunde.toLowerCase()),
        (contract) => contract.lieferdatum
    );

    return (
        <ContractTable
            onRowClick={startContract}
            contracts={sortedContracts}
            additionalColumns={[
                {
                    label: 'Starten',
                    render: function additionalColumn(contract) {
                        return (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => startContract(contract)}
                            >
                                <Icon>send</Icon>
                            </Button>
                        );
                    },
                },
            ]}
        />
    );
};
