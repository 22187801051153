import * as React from 'react';
import { useState } from 'react';
import { ContractInputForm } from './ContractInputForm/ContractInputForm';
import { StartableContractList } from './StartableContractList/StartableContractList';

interface IProps {
    onSubmit: (contractNumber: string) => void;
    registerOnBackButtonClick: (callback: () => void) => void;
    navigateToHome: () => void;
    visible: boolean;
}

enum SubPage {
    ContractList,
    ContractInputForm,
}

export const ContractSelector: React.FunctionComponent<IProps> = ({
    onSubmit,
    registerOnBackButtonClick,
    navigateToHome,
    visible,
}) => {
    const [subPage, setSubPage] = useState<SubPage>(SubPage.ContractInputForm);

    const showContractList = () => setSubPage(SubPage.ContractList);
    const showContractInputForm = () => setSubPage(SubPage.ContractInputForm);

    return (
        <>
            <StartableContractList
                onSubmit={onSubmit}
                registerOnBackButtonClick={registerOnBackButtonClick}
                navigateToContractInputForm={showContractInputForm}
                visible={visible && subPage === SubPage.ContractList}
            />
            <ContractInputForm
                onSubmit={onSubmit}
                showContractList={showContractList}
                registerOnBackButtonClick={registerOnBackButtonClick}
                navigateToHome={navigateToHome}
                visible={visible && subPage === SubPage.ContractInputForm}
            />
        </>
    );
};
