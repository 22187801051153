import * as React from 'react';
import { useContext } from 'react';
import { EditDetailContext } from '../../EditDetailContext';
import { useState } from 'react';
import { ChangeEvent } from 'react';
import { AplixInput, AplixPage } from '../../../../../../components';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { IPlace } from '../../../../../../core/entities/IPlace';

interface IProps {
    place: Partial<IPlace>;
    goBack: () => void;
    save: (place: IPlace) => void;
}

export const EditPlaceDetails: React.FunctionComponent<IProps> = ({ place, goBack, save }) => {
    const [{ articleNumber }] = useContext(EditDetailContext);

    const [placeId, setPlaceId] = useState(place.placeId ? place.placeId : '');

    const setPlaceIdValue = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPlaceId(event.target.value);
    };

    const data: Pick<IPlace, 'placeId'> = {
        placeId,
    };

    const isValid = !!data.placeId;

    const saveIfValid = () => {
        if (isValid) {
            save({ ...place, ...data } as IPlace);
        }
    };

    return (
        <AplixPage
            showBackButton
            pageTitle={`Artikel "${articleNumber}": Lagerplatz bearbeiten`}
            onBackClick={goBack}
        >
            <Box width={1}>
                <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={10}>
                        <AplixInput
                            id="placeId"
                            label="Lagerplatz ID"
                            type={'text'}
                            value={placeId}
                            fullWidth
                            autoFocus={!place.placeId}
                            required
                            showKeyboardAdornment
                            showBarCodeAdornment={true}
                            onChange={setPlaceIdValue}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            disabled={!isValid}
                            onClick={saveIfValid}
                        >
                            <Icon>done</Icon>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </AplixPage>
    );
};
