import { IAuthenticationTokenProvider, TToken } from '.';
import { inject, injectable } from 'inversify';
import SERVICE_IDENTIFIER from '../../ioc/constants/identifiers';
import { Store } from 'redux';
import { getLoginState, IQuinoEnabledStore, setLoginToken } from '../../../redux';

@injectable()
export class AuthenticationTokenProvider implements IAuthenticationTokenProvider {
    private quinoAuthTokenSchema = 'Bearer';

    constructor(
        @inject(SERVICE_IDENTIFIER.IQUINOENABLEDSTORE) private store: Store<IQuinoEnabledStore>
    ) {}

    setToken(token: TToken): void {
        this.store.dispatch(setLoginToken(token));
    }

    unsetToken(): void {
        this.store.dispatch(setLoginToken(undefined));
    }

    applyAuthHeaders(headers: Headers): void {
        const token = this.getTokeWithTokenType();
        if (token) {
            headers.append('Authorization', token);
        }
    }

    getTokeWithTokenType(): string | undefined {
        const token = getLoginState(this.store.getState()).loginToken;
        if (!token) {
            return undefined;
        }

        if (token.startsWith(this.quinoAuthTokenSchema)) {
            return token;
        }

        return `${this.quinoAuthTokenSchema} ${token}`;
    }
}
