import * as React from 'react';
import { useContext } from 'react';
import TableBody from '@material-ui/core/TableBody';
import { IContract } from '../../../../core/entities';
import { ListRow } from './ListRow';
import { ContractTableContext } from '../../ContractTableContext';

export type TProps = {
    contracts: IContract[];
};

export const ListBody: React.FunctionComponent<TProps> = ({ contracts }) => {
    const { onRowClick, selectedContract } = useContext(ContractTableContext);

    return (
        <TableBody>
            {contracts.map((contract, i) => (
                <ListRow
                    key={i}
                    contract={contract}
                    selected={contract === selectedContract}
                    selectRow={() => onRowClick(contract)}
                />
            ))}
        </TableBody>
    );
};
