import { IEditPropertyProps } from './IEditPropertyProps';
import { FunctionComponent, useState } from 'react';
import * as React from 'react';
import { EditValueGrid } from './EditValueGrid';
import { AplixInput } from '../../../../components';

export const EditDecimalValue: FunctionComponent<IEditPropertyProps> = ({
    save,
    property,
    validateRequired,
}) => {
    const floatPrefix = '#';

    const [value, setValue] = useState(
        (property.value && property.value.replace(floatPrefix, '')) || ''
    );
    const [errorMessage, setErrorMessage] = useState();

    const trySaveValue = () => {
        const floatValue = parseFloat(value);

        if (!isNaN(floatValue)) {
            const floatString = floatPrefix + parseFloat(floatValue.toFixed(4)).toString();
            save(floatString);
        } else {
            setErrorMessage('Bitte geben sie eine gültige Zahl ein');
        }
    };

    const isValid = !validateRequired || !property.isRequired || !!value;

    return (
        <EditValueGrid save={trySaveValue} isValid={isValid} property={property}>
            <AplixInput
                id="value"
                label={property.label}
                value={value}
                fullWidth
                autoFocus
                required={property.isRequired}
                showKeyboardAdornment
                showBarCodeAdornment
                onChange={(event) => setValue(event.target.value)}
                type={'number'}
                errorMessage={errorMessage}
            />
        </EditValueGrid>
    );
};
