// noinspection SpellCheckingInspection

const APLIX_SERVICE_IDENTIFIER = {
    IAPLIXAPIMANAGER: Symbol.for('IAplixApiManager'),
    IAPLIXSERVICE: Symbol.for('IAplixService'),
    IAPLIXURLMANAGER: Symbol.for('IAplixUrlManager'),
    IAPPLICATIONINSIGHTSLOGGER: Symbol.for('IApplicationInsightsLogger'),
    IBARCODESTRATEGYRUNNER: Symbol.for('IBarcodeStrategyRunner'),
    ICURRENTCONTRACTSERVICE: Symbol.for('ICurrentContractService'),
    ICONTINUECONTRACTSERVICE: Symbol.for('IContinueContractService'),
    IEDITDETAILBARCODELISTENER: Symbol.for('IEditDetailBarcodeListener'),
    IEDITDETAILSTOREBUILDER: Symbol.for('IEditDetailStoreBuilder'),
    IEXACTSTRATEGY: Symbol.for('IExactStrategy'),
    IFINISHCONTRACTREDUCER: Symbol.for('IFinishContractReducer'),
    IFINISHCONTRACTSERVICE: Symbol.for('IFinishContractService'),
    IPRICESTRATEGY: Symbol.for('IPriceStrategy'),
    IPRODUCTINFOVIEWHELPER: Symbol.for('IProductInfoViewHelper'),
    IQUANTITYHELPER: Symbol.for('IQuantityHelper'),
    IQUANTITYGRADEVIEWHELPER: Symbol.for('IQuantityGradeViewHelper'),
    IWEIGHTSTRATEGY: Symbol.for('IWeightStrategy'),
    STORE: Symbol.for('STORE'),
    IBARCODEPARSER: Symbol.for('IBarcodeParser'),
    IMANIFESTDOWNLOADER: Symbol.for('IManifestDownloader'),
    IUPDATER: Symbol.for('IUpdater'),
    ILOGGER: Symbol.for('ILogger'),
    IEDITDETAILSTOREQUERIES: Symbol.for('IEditDetailStoreQueries'),
    IEDITDETAILSTOREACTIONS: Symbol.for('IEditDetailStoreActions'),
    ISCANNERSERVICE: Symbol.for('IScannerService'),
};

// tslint:disable-next-line:no-default-export
export default APLIX_SERVICE_IDENTIFIER;
