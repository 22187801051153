import * as React from 'react';
import { useContext } from 'react';
import { ConfirmQtyNotOkDialog, EditQuantity, ProductInfo } from './components';
import { IContractDetail, QtyValidationState } from '../../logic';
import { getService } from '../../../../ioc_config';
import { IEditDetailStoreActions } from '../../logic/IEditDetailStoreActions';
import APLIX_SERVICE_IDENTIFIER from '../../../../ioc_constants';
import { EditDetailContext } from './EditDetailContext';
import { AplixPage } from '../../../../components';

type TStyles = {
    editDetailsRoot: string;
    editDetailsLeftColumn: string;
    editDetailsRightColumn: string;
};

interface IProps extends IWizardStepProps {}

const styles: TStyles = require('./EditDetail.less');

export const EditQuantityPage: React.FunctionComponent<IProps> = (props) => {
    const storeActions = getService<IEditDetailStoreActions>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREACTIONS
    );

    const [detailStore, setDetailStore] = useContext(EditDetailContext);

    const handleAccepted = (detailStore: IContractDetail) => {
        setDetailStore(detailStore);
        if (
            detailStore.errorMessage ||
            !(
                detailStore.validation === QtyValidationState.Completed ||
                detailStore.validation === QtyValidationState.NotCompleted
            )
        ) {
            return;
        }

        props.goToNextStep();
    };

    const handleUserConfirmQty = (isComplete: boolean): void => {
        if (isComplete) {
            handleAccepted(storeActions.userAcceptsQtyIsComplete(detailStore));
        } else {
            handleAccepted(storeActions.userAcceptsQtyAsPartialComplete(detailStore));
        }
    };

    const acceptQuantity = (): void => {
        handleAccepted(storeActions.tryAutoAccept(detailStore));
    };

    const showQtyConfirmDialog = detailStore.validation === QtyValidationState.Pending;

    return (
        <AplixPage
            showBackButton
            pageTitle={`Artikel "${detailStore.articleNumber}": Menge erfassen`}
            onBackClick={props.goToPreviousStep}
        >
            {showQtyConfirmDialog && (
                <ConfirmQtyNotOkDialog
                    onAcceptCompleted={() => handleUserConfirmQty(true)}
                    onAcceptNotCompleted={() => handleUserConfirmQty(false)}
                    onCancel={() => setDetailStore(storeActions.userRefuseQty(detailStore))}
                />
            )}

            <div id="content-root" className={styles.editDetailsRoot}>
                <div id="left-column" className={styles.editDetailsLeftColumn}>
                    <ProductInfo detailStore={detailStore} />
                </div>
                <div id="right-column" className={styles.editDetailsRightColumn}>
                    <EditQuantity detailStore={detailStore} onAcceptQuantity={acceptQuantity} />
                </div>
            </div>
        </AplixPage>
    );
};
