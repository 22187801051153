import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import { AplixInput } from '../../../../components';
import { IEditPropertyProps } from './IEditPropertyProps';
import { EditValueGrid } from './EditValueGrid';

export const EditTextValue: FunctionComponent<IEditPropertyProps> = ({
    save,
    property,
    validateRequired,
}) => {
    const [value, setValue] = useState(property.value || '');

    const isValid = !validateRequired || !property.isRequired || !!value;

    const submit = () => {
        save(value || null);
    };

    return (
        <EditValueGrid save={submit} isValid={isValid} property={property}>
            <AplixInput
                id="value"
                label={property.label}
                value={value}
                fullWidth
                autoFocus
                required={validateRequired && property.isRequired}
                showKeyboardAdornment
                showBarCodeAdornment
                onChange={(event) => setValue(event.target.value)}
            />
        </EditValueGrid>
    );
};
