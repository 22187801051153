import { injectable } from 'inversify';
import { QuinoUrlManager, TPrimaryKey } from '../../quino/core';
import { IAplixUrlManager } from '..';

@injectable()
export class AplixUrlManager extends QuinoUrlManager implements IAplixUrlManager {
    private aplixControllerName = 'aplix';

    getEnvironmentInfoUrl = () => this.combineUrlParts(this.aplixControllerName, 'environmentinfo');

    getCurrentUserInfoUrl = () => this.combineUrlParts(this.aplixControllerName, 'userinfo');

    getServerInfoUrl = () => this.combineUrlParts(this.aplixControllerName, 'info');

    getContractUrl = (contractId: TPrimaryKey) =>
        this.combineUrlParts(this.aplixControllerName, 'contracts', contractId);

    getStartContractUrl = (contractId: TPrimaryKey) =>
        this.combineUrlParts(this.aplixControllerName, 'contracts', contractId, 'start');

    getPauseContractUrl = (contractId: TPrimaryKey) =>
        this.combineUrlParts(this.aplixControllerName, 'contracts', contractId, 'pause');

    getContinueContractUrl = (contractId: TPrimaryKey) =>
        this.combineUrlParts(this.aplixControllerName, 'contracts', contractId, 'continue');

    getContractPropertiesUrl = (contractId: TPrimaryKey) =>
        this.combineUrlParts(this.aplixControllerName, 'contracts', contractId, 'properties');

    getPreparePositionUrl = (contractDetailId: TPrimaryKey) =>
        this.combineUrlParts(this.aplixControllerName, 'contractdetails', contractDetailId);

    getPauseDetailUrl = (contractDetailId: TPrimaryKey) =>
        this.combineUrlParts(
            this.aplixControllerName,
            'contractdetails',
            contractDetailId,
            'pause'
        );

    getUnpauseDetailUrl = (contractDetailId: TPrimaryKey) =>
        this.combineUrlParts(
            this.aplixControllerName,
            'contractdetails',
            contractDetailId,
            'unpause'
        );

    getFinishContractUrl = (contractId: TPrimaryKey) =>
        this.combineUrlParts(this.aplixControllerName, 'contracts', contractId, 'finish');

    getManifestUrl = () => this.combineFrontendUrlParts('manifest.json');

    getReloadUrl = () =>
        this.combineFrontendUrlParts() +
        QuinoUrlManager.combineQueryParams({ nocache: new Date().getTime() });

    getFrontendBaseUrl = () => {
        return (
            window.location.protocol + '//' + window.location.hostname + ':' + window.location.port
        );
    };

    combineFrontendUrlParts(...parts: string[]) {
        return [this.getFrontendBaseUrl(), ...parts].join('/');
    }
}
