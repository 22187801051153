import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { ChoiceFilter } from './ChoiceFilter';
import { List } from './List';
import { connect, useSelector } from 'react-redux';
import { ContractsAction, getStartableContracts } from '../../../../redux/contracts';
import { apiManager, LoginAction } from '../../../../quino';
import { IChoice } from '../../../../core/entities/IChoice';
import { SalesType } from '../../../../core/entities/SalesType';
import { RegionFilter } from './RegionFilter';
import { SalesTypeFilter } from './SalesTypeFilter';
import { uniq } from 'lodash';
import { IState } from '../../../../redux';
import {
    getBookmarkContractFilters,
    GlobalsAction,
    IContractFilterState,
    RootPage,
    setBookmarkContractFilters,
    switchRootPage
} from '../../../../redux/globals';
import { ThunkDispatch } from 'redux-thunk';
import { Dispatch } from 'redux';

type IProps = {
    onSubmit: (contractNumber: string) => void;
    registerOnBackButtonClick: (callback: () => void) => void;
    navigateToContractInputForm: () => void;
    visible: boolean;
}

type TStateProps = {
    bookmarkContractFilters: IContractFilterState;
};

type TDispatchProps = {
    setBookmarkContractFilters: (filter: IContractFilterState) => void;
    switchRootPage: (rootPage: RootPage) => void;
};

export function mapStateToProps(state: IState): TStateProps {
    const bookmarkContractFilters = getBookmarkContractFilters(state);

    return {
        bookmarkContractFilters: bookmarkContractFilters,
    } as TStateProps;
}

export function mapDispatchToProps(
    dispatch: ThunkDispatch<IState, Dispatch, GlobalsAction | ContractsAction | LoginAction>
): TDispatchProps {
    return {
        setBookmarkContractFilters: (filters: IContractFilterState) => dispatch(setBookmarkContractFilters(filters)),
        switchRootPage: (rootPage: RootPage) => dispatch(switchRootPage(rootPage)),
    };
}

const InternalStartableContractList: FC<IProps & TStateProps & TDispatchProps> = (props) => {
        const [selectedChoiceID, setSelectedChoiceID] = useState<string | null>(null);
        const [regionFilter, setRegionFilter] = useState<string>();
        const [salesTypeFilter, setSalesTypeFilter] = useState<SalesType>();
        const [choices, setChoices] = useState<IChoice[]>();
        const [choiceHistory, setChoiceHistory] = useState<(string | null)[]>([null]);

        const startableContracts = useSelector(getStartableContracts);
        const useChoiceFiltering = choices && choices.length > 0;

        const navigateBack = () => {
            if (!useChoiceFiltering) {
                if (salesTypeFilter != undefined) {
                    props.setBookmarkContractFilters({...props.bookmarkContractFilters, salesType: undefined});
                    setSalesTypeFilter(undefined);
                    const availableSalesTypes: SalesType[] = uniq(
                        startableContracts
                            .filter(
                                (contract) =>
                                    regionFilter === undefined || contract.region === regionFilter
                            )
                            .map((contract) => contract.umsatzart)
                    );
                    // skip sales type filtering if there is just one option and return directly to region filter (Bug #10555)
                    if (availableSalesTypes.length === 1) {
                        setRegionFilter(undefined);
                    }
                } else if (regionFilter != undefined) {
                    props.setBookmarkContractFilters({region: undefined});
                    setRegionFilter(undefined);
                } else {
                    props.navigateToContractInputForm();
                }
            } else {
                if (choiceHistory.length > 1) {
                    const newChoiceHistory = choiceHistory;
                    newChoiceHistory.pop();

                    const previousChoice = newChoiceHistory[newChoiceHistory.length - 1]

                    props.setBookmarkContractFilters({choiceID: previousChoice, choiceHistory: newChoiceHistory});

                    setSelectedChoiceID(previousChoice);
                    setChoiceHistory(newChoiceHistory);
                } else {
                    props.navigateToContractInputForm();
                }
            }
        };

        useEffect(() => {
            props.visible && props.registerOnBackButtonClick(navigateBack);
        }, [choices, choiceHistory, regionFilter, salesTypeFilter, props.visible]);

        useEffect(() => {
            (async () => {
                setChoices(
                    await apiManager().dataService.getListAsync<IChoice[]>('choice', 'ActiveChoices')
                );
            })();
        }, []);

        useEffect(() => {
            const bookmarkContractFilterState = props.bookmarkContractFilters;

            setRegionFilter(bookmarkContractFilterState.region)
            setSalesTypeFilter(bookmarkContractFilterState.salesType)
            setSelectedChoiceID(bookmarkContractFilterState.choiceID ? bookmarkContractFilterState.choiceID : null);
            setChoiceHistory(bookmarkContractFilterState.choiceHistory ? bookmarkContractFilterState.choiceHistory : [null])
        }, [])

        const choiceHasActiveLeaves = (choices: IChoice[], choice: IChoice): boolean => {
            const subChoices = choices.filter((c) => c.parent === choice.primaryKey);

            if (subChoices.length < 1) {
                return startableContracts.some((contract) => contract.choice === choice.primaryKey);
            }

            return subChoices.some((c) => choiceHasActiveLeaves(choices, c));
        };

        if (!props.visible) {
            return <></>;
        }

        if (!choices) {
            return <div>Auswahlliste wird geladen...</div>;
        }

        if (!useChoiceFiltering) {
            const filteredContracts = startableContracts
                .filter((contract) => regionFilter === undefined || contract.region === regionFilter)
                .filter(
                    (contract) =>
                        salesTypeFilter === undefined || contract.umsatzart === salesTypeFilter
                );

            if (regionFilter === undefined) {
                return (
                    <RegionFilter
                        contracts={filteredContracts}
                        selectRegion={(region) => {
                            props.setBookmarkContractFilters({region: region});
                            setRegionFilter(region);
                        }}
                    />
                )
            }

            if (salesTypeFilter === undefined) {
                return (
                    <SalesTypeFilter
                        contracts={filteredContracts}
                        selectSalesType={(salesType) => {
                            props.setBookmarkContractFilters({...props.bookmarkContractFilters, salesType: salesType});
                            setSalesTypeFilter(salesType)
                        }}
                    />
                );
            }

            if (filteredContracts.length < 1) {
                props.switchRootPage(RootPage.StartPage);
                return <></>;
            }

            return <List contracts={filteredContracts} onSubmit={props.onSubmit}/>;
        } else {
            const filteredChoices = choices.filter(
                (choice) =>
                    choice.isActive &&
                    choice.parent === selectedChoiceID &&
                    choiceHasActiveLeaves(choices, choice)
            );
            if (filteredChoices.length > 0) {
                const selectChoiceID = (id: string) => {
                    const newChoiceHistory = [...choiceHistory, id];

                    props.setBookmarkContractFilters({choiceID: id, choiceHistory: newChoiceHistory});

                    setChoiceHistory(newChoiceHistory);
                    setSelectedChoiceID(id);
                };

                return <ChoiceFilter choices={filteredChoices} selectChoiceID={selectChoiceID}/>;
            }

            const filteredContracts = selectedChoiceID != null && startableContracts.filter(
                (contract) => contract.choice === selectedChoiceID
            ) || [];

            if (filteredContracts.length < 1) {
                props.switchRootPage(RootPage.StartPage);
                return <></>;
            }

            return <List contracts={filteredContracts} onSubmit={props.onSubmit}/>;
        }
    }
;

export const StartableContractList = connect<TStateProps, TDispatchProps, IProps>(mapStateToProps, mapDispatchToProps)(InternalStartableContractList)
