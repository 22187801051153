import {
    FETCH_SERVER_INFO_BEGIN,
    FETCH_SERVER_INFO_ERROR,
    FETCH_SERVER_INFO_SUCCESS,
    GlobalsAction,
    IServerInfoState,
} from '..';

const initial: IServerInfoState = {
    isServerInfoLoaded: false,
    isFetching: false,
    serverVersion: 'unbekannt',
    database: 'unbekannt',
    isTestingEnvironment: false,
};

export function serverInfoReducer(
    state: IServerInfoState = initial,
    action: GlobalsAction
): IServerInfoState {
    switch (action.type) {
        case FETCH_SERVER_INFO_BEGIN:
            return {
                ...state,
                errorMessage: undefined,
                isServerInfoLoaded: false,
                isFetching: true,
            };

        case FETCH_SERVER_INFO_SUCCESS:
            return {
                ...state,
                errorMessage: undefined,
                isServerInfoLoaded: true,
                isFetching: false,
                serverVersion: action.serverInfo.serverVersion,
                database: action.serverInfo.resource,
                isTestingEnvironment: action.serverInfo.isTestingEnvironment,
            };

        case FETCH_SERVER_INFO_ERROR:
            return {
                ...state,
                errorMessage: action.errorMessage,
                isServerInfoLoaded: false,
                isFetching: false,
            };
    }

    return state;
}
