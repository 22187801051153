import * as React from 'react';
import InputLabel from '@material-ui/core/InputLabel';

export const textTotalGeruestet = 'Total gerüstet';

interface IProps {
    text: string;
}

type TStyles = {
    valueLabelRoot: string;
};

const styles: TStyles = require('./ValueLabel.less');

export const ValueLabel = ({ text }: IProps) => {
    return <InputLabel className={styles.valueLabelRoot}>{text}</InputLabel>;
};
