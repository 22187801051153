import { ILogger, LogLevel } from '.';
import { injectable } from 'inversify';

@injectable()
export class ConsoleLogger implements ILogger {
    log(level: LogLevel, name: string, message?: string, properties?: {}, exception?: Error): void {
        switch (level) {
            case LogLevel.Verbose:
                console.debug(`${name}: ${message}`);
                break;
            case LogLevel.Info:
                console.info(`${name}: ${message}`);
                break;
            case LogLevel.Event:
                console.debug(`[EVENT] ${name}: ${message}`);
                break;
            case LogLevel.Warning:
                console.warn(`${name}: ${message}`);
                break;
            case LogLevel.Error:
            case LogLevel.Critical:
                console.error(`${name}: ${message}`);
                break;
        }

        if (exception) {
            console.error(exception);
        }
    }
}
