import * as React from 'react';
import { useContext } from 'react';
import { EditDetailContext } from './EditDetailContext';
import { IGenericProperty } from '../../../../core/entities/IGenericProperty';
import { EditPropertiesWizard } from '../EditProperty/EditPropertiesWizard';

interface IProps extends IWizardStepProps {
    validateRequired: boolean;
}

export const EditPropertiesPage: React.FunctionComponent<IProps> = ({
    goToPreviousStep,
    goToNextStep,
    validateRequired
}) => {
    const [{ articleNumber, properties }, setDetailStore] = useContext(EditDetailContext);

    const saveProperties = (properties: IGenericProperty[]) => {
        setDetailStore((detailStore) => ({ ...detailStore, properties }));
        goToNextStep();
    };
    
    return (
        <EditPropertiesWizard
            save={saveProperties}
            goBack={goToPreviousStep}
            pageTitle={`Artikel "${articleNumber}": Eigenschaften bearbeiten`}
            properties={properties}
            validateRequired={validateRequired}
        />
    );
};
