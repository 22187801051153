export { IAplixUrlManager } from './api/IAplixUrlManager';
export { IAplixService } from './api/IAplixService';
export { IAplixApiManager } from './api/IAplixApiManager';
export { AplixApiManager, aplixApiManager } from './api/AplixApiManager';
export * from './entities';
export * from './logic';
export * from './logging';

const manifest = require('../manifest.json');

// noinspection TsLint
export const ClientVersion = manifest.version;
