import {
    StartContractInit,
    STARTCONTRACT_INIT,
    StartContractStartReview,
    STARTCONTRACT_START_REVIEW,
    StartContractCancelReview,
    STARTCONTRACT_CANCEL_REVIEW,
    StartContractError,
    STARTCONTRACT_ERROR,
    StartContractPostBegin,
    STARTCONTRACT_POST_BEGIN,
    StartContractPostSuccess,
    STARTCONTRACT_POST_SUCCESS,
    StartContractGoBackToReview,
    STARTCONTRACT_GOBACK_TO_REVIEW,
} from './types';
import {
    getStartableContractByBarcode,
    getStartableContractsState,
} from '../../../../redux/contracts';
import { getState } from '../../../../redux';

export function startContractInit(): StartContractInit {
    return {
        type: STARTCONTRACT_INIT,
    };
}

export function startContractStartReview(contractNumber: string): StartContractStartReview {
    const state = getState();
    const startbaleContractsState = getStartableContractsState(state);
    const contract = getStartableContractByBarcode(startbaleContractsState, contractNumber);

    return {
        type: STARTCONTRACT_START_REVIEW,
        contract: contract,
    };
}

export function startContractCancelReview(): StartContractCancelReview {
    return {
        type: STARTCONTRACT_CANCEL_REVIEW,
    };
}

export function startContractError(message: string): StartContractError {
    return {
        type: STARTCONTRACT_ERROR,
        message,
    };
}

export function startContractPostBegin(): StartContractPostBegin {
    return {
        type: STARTCONTRACT_POST_BEGIN,
    };
}

export function startContractPostSuccess(): StartContractPostSuccess {
    return {
        type: STARTCONTRACT_POST_SUCCESS,
    };
}

export function startContractGoBackToReview(): StartContractGoBackToReview {
    return {
        type: STARTCONTRACT_GOBACK_TO_REVIEW,
    };
}