import APLIX_SERVICE_IDENTIFIER from '../../../ioc_constants';
import { InputMode } from './IContractDetail';

import { inject, injectable } from 'inversify';
import { IQuantityHelper } from '../../../core/logic';
import { QuantityGrade } from './types';
import { IEditDetailStoreQueries } from './IEditDetailStoreQueries';
import * as _ from 'lodash';
import { IContractDetail } from '../../../core/entities/IContractDetail';
import { IPlace } from '../../../core/entities/IPlace';

@injectable()
export class EditDetailStoreQueries implements IEditDetailStoreQueries {
    constructor(
        @inject(APLIX_SERVICE_IDENTIFIER.IQUANTITYHELPER) public quantityHelper: IQuantityHelper
    ) {}

    canDecrementQuantity(detailStore: IContractDetail): boolean {
        return detailStore.currentQuantity > 0;
    }

    getRemaining(detailStore: IContractDetail): number {
        return detailStore.targetQuantity - detailStore.currentQuantity;
    }

    getInputMode(detailStore: IContractDetail): InputMode {
        return this.isUnitLoad(detailStore) ? InputMode.Discrete : InputMode.Continuous;
    }

    isUnitLoad(detailStore: IContractDetail): boolean {
        return detailStore.decimalPlaces === 0;
    }

    getQuantityGrade(detailStore: IContractDetail): QuantityGrade {
        return this.quantityHelper.getQuantityGrade(
            detailStore.currentQuantity,
            detailStore.targetQuantity,
            detailStore.allowedDeviation
        );
    }

    getLotsAmount(detailStore: IContractDetail): number {
        const result = detailStore.lots.reduce((sum, lot) => sum + lot.amount, 0);
        return this.roundAmount(detailStore, result);
    }

    getPlacesAmount(detailStore: IContractDetail): number {
        const result = detailStore.places.reduce((sum, place) => sum + place.amount, 0);
        return this.roundAmount(detailStore, result);
    }

    getMaxPlaceAmount(
        detailStore: IContractDetail,
        place: Partial<IPlace>
    ): { remainingAmount: number; amountPerPlace: number } {
        const amountInOtherPlaces = this.getPlacesAmount(detailStore) - (place.amount || 0);
        const remainingQuantity = detailStore.currentQuantity - amountInOtherPlaces;
        /**
         * If amountPerPlace is 0, treat it as as infinite, thus limit to remaining amount
         * @see https://secure.encodo.ch/jira/browse/BORHOS-14
         */
        const amountPerPlace = detailStore.amountPerPlace || remainingQuantity;

        return {
            remainingAmount: this.roundAmount(detailStore, remainingQuantity),
            amountPerPlace: this.roundAmount(detailStore, amountPerPlace),
        };
    }

    roundAmount({ decimalPlaces }: IContractDetail, amount: number): number {
        return _.round(amount, decimalPlaces);
    }
}
