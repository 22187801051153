import { combineReducers } from 'redux';
import * as fromStartContracts from '../../pages/StartContractPage/redux';
import { IPagesState } from '../store';
import * as fromFinishContract from '../../pages/FinishContractPage/redux';

export function getPagesReducer() {
    return combineReducers<IPagesState>({
        startContract: fromStartContracts.reducer,
        finishContract: fromFinishContract.getReducer(),
    });
}
