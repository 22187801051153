import { getQuinoContainer } from '../../quino/core/ioc';
import APLIX_SERVICE_IDENTIFIER from '../../ioc_constants';
import { IUpdater } from './IUpdater';

export { IManifest } from './IManifest';
export { IManifestDownloader } from './IManifestDownloader';
export { ManifestDownloader } from './ManifestDownloader';
export { IUpdater } from './IUpdater';
export { Updater } from './Updater';

export const getUpdater = () => {
    return getQuinoContainer().get<IUpdater>(APLIX_SERVICE_IDENTIFIER.IUPDATER);
};
