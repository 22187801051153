import { TimePicker } from '@material-ui/pickers';
import * as React from 'react';
import { DatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker';

interface IProps
    extends Pick<DatePickerProps, 'label' | 'required' | 'value' | 'onChange' | 'autoFocus'> {}

export const AplixTimePicker: React.FunctionComponent<IProps> = (props) => {
    return (
        <TimePicker
            label={props.label}
            value={props.value}
            required={props.required}
            onChange={props.onChange}
            clearable={!props.required}
            autoFocus={props.autoFocus}
            fullWidth
            ampm={false}
            format={'HH:mm'}
            cancelLabel={'Abbrechen'}
            invalidLabel={'Ungültig'}
            okLabel={'Ok'}
            todayLabel={'Heute'}
            invalidDateMessage={'Ungültge Zeit'}
            clearLabel={'Zurücksetzen'}
        />
    );
};
