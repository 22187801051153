/**
 * Describes the possible statuses for contracts.
 */
export enum SalesType {
    /**
     * Einkauf (Wareneingang)
     */
    Purchase = 0,

    /**
     * Produktion (Rüsten)
     */
    Production = 1,

    /**
     * Verkauf (Warenausgang)
     */
    Sales = 2,
}
