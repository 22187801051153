import { AplixErrorDialog } from '../../../../../components';
import * as React from 'react';
import Box from '@material-ui/core/Box';

export type TListError = {
    type: 'InvalidBarcode';
    barcode: string;
};

interface IProps {
    onClose: () => void;
    error: TListError;
}

export const ListError: React.FunctionComponent<IProps> = ({ onClose, error }) => {
    return (
        <AplixErrorDialog title="Ungültiger Barcode" onClose={onClose}>
            Der gescannte Barcode ist für diesen Auftrag nicht gültig! Bitte Lagerort prüfen und
            erneut scannen.
            <Box mt={1}>Gescannter Barcode:</Box>
            <div>
                <strong>{error.barcode}</strong>
            </div>
        </AplixErrorDialog>
    );
};
