import { IStorageService } from './IStorageSevice';
import { inject, injectable } from 'inversify';
import { LookupNormalizer } from './LookupNormalizer';
import SERVICE_IDENTIFIER from '../ioc/constants/identifiers';

@injectable()
export class LocalStorageService implements IStorageService {
    constructor(@inject(SERVICE_IDENTIFIER.ISTORAGE) private readonly storage: Storage) {}

    get<TPayload>(key: string): TPayload {
        const storageItem = this.storage.getItem(LookupNormalizer.Normalize(key));

        return storageItem && JSON.parse(storageItem);
    }

    set<TPayload>(key: string, value: TPayload): void {
        this.storage.setItem(LookupNormalizer.Normalize(key), JSON.stringify(value));
    }

    delete(key: string): void {
        this.storage.removeItem(LookupNormalizer.Normalize(key));
    }

    clear(): void {
        this.storage.clear();
    }
}
