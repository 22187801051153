import * as React from 'react';
import { AplixInput } from '../';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { IContract } from '../../core/entities';
import moment from 'moment';
import {
    InformationPanel
} from '../../pages/PrepareContractPage/scenes/EditDetail/components/ProductInfo/InformationPanel';

type TStyles = {
    contractInformationRoot: string;
    contractInformationLeft: string;
    contractInformationRight: string;
    contractInformationCustomer: string;
    contractInformationButtonBar: string;
};

const styles: TStyles = require('./ContractInformation.less');

export type TProps = {
    contract: IContract;
    confirmLabel: string;
    onConfirm: () => void;
};

export class ContractInformation extends React.Component<TProps> {
    constructor(props: TProps) {
        super(props);
    }

    public render() {
        const { contract, confirmLabel, onConfirm } = this.props;
        const lieferDatum = moment(contract.lieferdatum).isValid()
            ? moment(contract.lieferdatum).format('DD.MM.YYYY, HH:mm')
            : '';

        return (
            <div className={styles.contractInformationRoot}>
                <div className={styles.contractInformationLeft}>
                    <AplixInput
                        id="contractNumber"
                        label="Auftragsnummer"
                        value={contract.nummer}
                        disabled
                        fullWidth
                        placeholder="Keine"
                    />

                    <AplixInput
                        id="lieferdatum"
                        label="Datum"
                        value={lieferDatum}
                        disabled
                        fullWidth
                        placeholder="Kein Lieferdatum"
                    />
                    
                    {<InformationPanel id="information" information={contract.information || ''} />}
                </div>

                <div className={styles.contractInformationRight}>
                    <AplixInput
                        id="region"
                        label="Region"
                        value={contract.region}
                        disabled
                        fullWidth
                        placeholder="Keine"
                    />

                    <AplixInput
                        id="customer"
                        label="Kunde"
                        value={contract.kunde}
                        disabled
                        fullWidth
                        multiline
                        placeholder="Kein"
                        className={styles.contractInformationCustomer}
                    />

                    <div className={styles.contractInformationButtonBar}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={onConfirm}
                        >
                            {confirmLabel}
                            <Icon>send</Icon>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
