import { IBarcodeParser } from '../../../core/logic/BarcodeParser';
import { ICurrentContractService } from '../../../core/logic';
import {
    IBarcodeListenerCallbacks,
    IEditDetailBarcodeListener,
} from './IEditDetailBarcodeListener';
import { inject, injectable } from 'inversify';
import APLIX_SERVICE_IDENTIFIER from '../../../ioc_constants';
import { IContractDetail } from '../../../core/entities/IContractDetail';

@injectable()
export class EditDetailBarcodeListener implements IEditDetailBarcodeListener {
    constructor(
        @inject(APLIX_SERVICE_IDENTIFIER.ICURRENTCONTRACTSERVICE)
        private currentContractService: ICurrentContractService,
        @inject(APLIX_SERVICE_IDENTIFIER.IBARCODEPARSER) private barcodeParser: IBarcodeParser
    ) {}

    onScan(
        detailStore: IContractDetail,
        barcode: string,
        callbacks: IBarcodeListenerCallbacks
    ): void {
        const contractDetail = this.currentContractService.getContractDetailByPrimaryKey(
            detailStore.primaryKey
        );
        if (!contractDetail) {
            EditDetailBarcodeListener.handleInvalidBarcode(callbacks, barcode);
            return;
        }

        const parserResult = this.barcodeParser.parse([contractDetail], barcode);
        if (!parserResult) {
            EditDetailBarcodeListener.handleInvalidBarcode(callbacks, barcode);
            return;
        }

        const increment = parserResult.factor * (parserResult.amount || 0);
        if (increment === 0) {
            EditDetailBarcodeListener.handleNoAmount(callbacks);
        }

        callbacks.scanAmount(increment);
    }

    private static handleInvalidBarcode({ showError }: IBarcodeListenerCallbacks, barcode: string) {
        showError(
            `Der Barcode "${barcode}" gehört nicht zum aktuellen Artikel. Bitte schliessen Sie die Bearbeitung ab, bevor Sie weitere Artikel scannen.`
        );
    }

    private static handleNoAmount({ switchToEditMode, showError }: IBarcodeListenerCallbacks) {
        switchToEditMode();
        showError(
            'Der gescannte Barcode enthält keine Mengenangabe. Bitte editieren Sie die Menge manuell.'
        );
    }
}
