import * as React from 'react';
import { AplixInput } from '../../../../../../components';
import { IContractDetail } from '../../../../logic';
import { getService } from '../../../../../../ioc_config';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../ioc_constants';
import { IProductInfoViewHelper } from './IProductInfoViewHelper';
import { InformationPanel } from './InformationPanel';
import { IEditDetailStoreQueries } from '../../../../logic/IEditDetailStoreQueries';

type TStyles = {
    productInfoRoot: string;
    productInfoDescription: string;
    productInfoTargetQty: string;
    productInfoFactor: string;
    productInfoNotes: string;
};

const styles: TStyles = require('./ProductInfo.less');

export type TProps = {
    detailStore: IContractDetail;
};

export class ProductInfo extends React.Component<TProps, {}> {
    private viewHelper: IProductInfoViewHelper;
    private detailStoreQueries: IEditDetailStoreQueries;

    constructor(props: TProps) {
        super(props);
        this.viewHelper = getService<IProductInfoViewHelper>(
            APLIX_SERVICE_IDENTIFIER.IPRODUCTINFOVIEWHELPER
        );

        this.detailStoreQueries = getService<IEditDetailStoreQueries>(
            APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREQUERIES
        );
    }

    public render() {
        const store = this.props.detailStore;

        return (
            <div className={styles.productInfoRoot}>
                <AplixInput
                    id="product-descr"
                    label="Gescanntes Produkt"
                    value={store.itemName}
                    placeholder="Keine"
                    disabled
                    fullWidth
                    multiline
                    className={styles.productInfoDescription}
                />

                <div className={styles.productInfoNotes}>
                    {store.information.length > 0 && (
                        <InformationPanel information={store.information} />
                    )}
                </div>

                {this.viewHelper.showLastScannedAmount(store) && (
                    <AplixInput
                        id="factor"
                        label="Zuletzt gescannte Menge"
                        value={this.viewHelper.getLastScannedAmountLabel(store)}
                        placeholder="-"
                        disabled
                        fullWidth
                        className={styles.productInfoFactor}
                    />
                )}

                <AplixInput
                    id="target-qty"
                    label="Sollmenge"
                    value={this.viewHelper.getTargetQuantityLabel(store)}
                    placeholder="Keine"
                    disabled
                    fullWidth
                    className={styles.productInfoTargetQty}
                />

                {this.detailStoreQueries.isUnitLoad(store) && (
                    <AplixInput
                        id="factor"
                        label="Mengenfaktor"
                        value={this.viewHelper.getFactorLabel(store)}
                        placeholder="1"
                        disabled
                        fullWidth
                        className={styles.productInfoFactor}
                    />
                )}
            </div>
        );
    }
}
