import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import classNames from 'classnames';
import { withStyles, WithStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { getLoginState, LoginAction, logout } from '../../quino/redux';
import { IState } from '../../redux';
import { ClientVersion, IContract, User } from '../../core';
import { AplixErrorBox, AplixLogo } from '../../components';
import { InfoDialog, LoggedInUser, StartMenu, UpdateAvailableInfo } from './components';
import {
    fetchServerInfo,
    getCurrentUserInfoState,
    getServerInfoState,
    getUpdateState,
    GlobalsAction, IContractFilterState,
    RootPage, setBookmarkContractFilters, setBookmarkLastPage,
    switchRootPage,
} from '../../redux/globals';
import {
    ContractsAction,
    fetchContinuableContract,
    fetchStartableContract,
    getContinuableContractsState,
    getCurrentContractState,
    getStartableContractsState,
} from '../../redux/contracts';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import InfoIcon from '@material-ui/icons/Info';
import { CurrentContract } from './components/CurrentContract/CurrentContract';
import { getUpdater, IUpdater } from '../../core/updater';
import { unloadContinuableContracts, unloadStartableContracts } from '../../redux/contracts/actions';
import { AplixLoadingIndicator } from '../../components/AplixLoadingIndicator/AplixLoadingIndicator';

type TStyles = {
    startpageRoot: string;
    startpageColLeft: string;
    startpageColRight: string;
    startpageLogo: string;
    startpageColRightSpacer: string;
    startpageToolButtons: string;
    startpageToolButton: string;
    startpageTitle: string;
};

const styles: TStyles = require('./StartPage.less');

export type TOwnProps = {};

type TStateProps = {
    isLoadedStartableContracts: boolean;
    isLoadedContinuableContracts: boolean;
    currentUserName?: string;
    startableContracts?: IContract[];
    continuableContracts?: IContract[];
    currentContract?: IContract;
    errorMessage?: string;
    isFetching: boolean;
    serverVersion: string;
    database: string;
    currentUser: User;
    isUpdateAvailable: boolean;
    currentVersion: string;
    newVersion: string;
};

type TDispatchProps = {
    fetchServerInfo: (forceFetch?: boolean) => void;
    fetchStartableContracts: (forceFetch?: boolean) => void;
    fetchContinuableContracts: (forceFetch?: boolean) => void;
    logout: () => void;
    switchRootPage: (targetPage: RootPage) => void;
    setBookmarkLastPage: (lastActivePage: RootPage) => void;
    setBookmarkContractFilters: (contractFilters: IContractFilterState) => void;
};

export type TProps = TOwnProps & TStateProps & TDispatchProps;

type TPropsWithStyles = TProps &
    WithStyles<
        | 'columnLeft'
        | 'columnRight'
        | 'rightIcon'
        | 'columnRightContent'
        | 'columnRightContentStartMenu'
        | 'enableShrinking'
    >;

const style = withStyles(({ spacing }) => ({
    rightIcon: {
        marginLeft: spacing(1),
    },
    columnRightContentStartMenu: {
        marginTop: spacing(2),
    },
}));

type TState = {
    showInfo: boolean;
};

const StartPageWithStyle = style(
    class StartPageComponent extends React.Component<TPropsWithStyles, TState> {
        private aplixUpdater: IUpdater;
        private updateCheckTimer: any;

        constructor(props: TPropsWithStyles) {
            super(props);
            this.state = { showInfo: false };
        }

        async componentDidMount() {
            const {isLoadedStartableContracts, isLoadedContinuableContracts} = this.props;

            this.resetBookmark();

            this.aplixUpdater = getUpdater();
            await this.checkForUpdateAsync();

            if (!isLoadedStartableContracts || !isLoadedContinuableContracts) {
                this.handleRefresh();
            }
        }

        componentWillUnmount() {
            clearTimeout(this.updateCheckTimer);
        }

        handleScanContractClick = () => {
            this.props.setBookmarkLastPage(RootPage.ScanContractPage)
            this.props.fetchStartableContracts(true);
            this.props.switchRootPage(RootPage.ScanContractPage);
        };
        handleContinueContractClick = () => {
            this.props.fetchContinuableContracts(true);
            this.props.switchRootPage(RootPage.ContinueContractPage);
        };
        handleChooseContractClick = () => {
            this.props.setBookmarkLastPage(RootPage.ChooseContractPage)
            this.props.fetchStartableContracts(true);
            this.props.switchRootPage(RootPage.ChooseContractPage);
        };
        handleRefresh = () => this.reload(true);
        handleInfo = () => this.setState({ showInfo: true });
        handleCloseInfo = () => this.setState({ showInfo: false });
        handleLogout = () => this.props.logout();

        private checkForUpdateAsync = async () => {
            await this.aplixUpdater.checkForUpdateAsync();
            this.updateCheckTimer = setTimeout(() => {
                this.checkForUpdateAsync();
            }, 90000);
        };

        reload = (force = false) => {
            const {
                fetchServerInfo,
                fetchStartableContracts,
                fetchContinuableContracts,
            } = this.props;

            fetchServerInfo(force);
            fetchStartableContracts(force);
            !this.props.errorMessage && fetchContinuableContracts(force);
        };

        renderRefreshButton(title = ''): React.ReactNode {
            const { isFetching } = this.props;
            return (
                <Button
                    disabled={isFetching}
                    variant="contained"
                    size="medium"
                    onClick={this.handleRefresh}
                >
                    <RefreshIcon />
                    {title}
                </Button>
            );
        }

        handleUpdate = () => {
            this.aplixUpdater.executeUpdate();
        };

        resetBookmark = () => {
            this.props.setBookmarkLastPage(RootPage.StartPage);
            this.props.setBookmarkContractFilters({});
        };
        
        render() {
            const {
                errorMessage,
                isFetching,
                serverVersion,
                database,
                currentUser,
                startableContracts,
                continuableContracts,
                currentContract,
                isUpdateAvailable,
                newVersion,
                currentVersion,
            } = this.props;
            const canStartNewContracts =
                startableContracts !== undefined && startableContracts.length > 0;
            const canContinueContracts =
                continuableContracts !== undefined && continuableContracts.length > 0;
            const hasCurrentContract = currentContract !== undefined;
            const { showInfo } = this.state;
            
            return (
                <div id="startpage-root" className={classNames(styles.startpageRoot)}>
                    {showInfo && (
                        <InfoDialog
                            onCloseDialog={this.handleCloseInfo}
                            clientVersion={ClientVersion}
                            serverVersion={serverVersion}
                            database={database}
                        />
                    )}

                    <div id="startpage-column-right" className={styles.startpageColLeft}>
                        {isFetching && (
                            <AplixLoadingIndicator/>
                        )}

                        {errorMessage && (
                            <AplixErrorBox>
                                <h2>Server nicht erreichbar!</h2>
                                <p>
                                    <strong>Meldung: </strong>
                                    {errorMessage}
                                </p>
                                <p>Versuchen Sie es in Kürze erneut.</p>
                                {this.renderRefreshButton(' Erneut versuchen')}
                            </AplixErrorBox>
                        )}

                        {isUpdateAvailable && (
                            <div>
                                <UpdateAvailableInfo
                                    currentVersion={currentVersion}
                                    newVersion={newVersion}
                                    onUpdate={this.handleUpdate}
                                />
                            </div>
                        )}

                        {!errorMessage && !isFetching && (
                            <StartMenu
                                canStartNewContract={canStartNewContracts}
                                canContinueContract={canContinueContracts}
                                hasCurrentContract={hasCurrentContract}
                                countStartableContracts={
                                    startableContracts ? startableContracts.length : undefined
                                }
                                countContinuableContracts={
                                    continuableContracts ? continuableContracts.length : undefined
                                }
                                isFetching={isFetching}
                                onScanContractClick={this.handleScanContractClick}
                                onContinueContractClick={this.handleContinueContractClick}
                                onChooseContractClick={this.handleChooseContractClick}
                            />
                        )}
                    </div>

                    <div id="startpage-column-left" className={styles.startpageColRight}>
                        <div id="startpage-logo-container" className={styles.startpageLogo}>
                            <AplixLogo />
                        </div>

                        <div className={styles.startpageColRightSpacer}>
                            <div>
                                <LoggedInUser currentUser={currentUser} />
                                <CurrentContract
                                    contractNumber={
                                        (currentContract && currentContract.nummer) || 'n/v'
                                    }
                                />
                            </div>
                            <Button
                                disabled={isFetching}
                                variant="contained"
                                size="small"
                                onClick={this.handleInfo}
                            >
                                <InfoIcon />
                            </Button>
                        </div>

                        <div id="startpage-toolbuttons" className={styles.startpageToolButtons}>
                            <div id="startpage-refresh" className={styles.startpageToolButton}>
                                {this.renderRefreshButton()}
                            </div>
                            <div id="startpage-logout" className={styles.startpageToolButton}>
                                <Button
                                    disabled={isFetching}
                                    variant="contained"
                                    size="medium"
                                    onClick={this.handleLogout}
                                >
                                    <LogoutIcon />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
);

export function mapStateToProps(state: IState): TStateProps {
    const loginState = getLoginState(state);
    const startableState = getStartableContractsState(state);
    const continuableState = getContinuableContractsState(state);
    const currentContractState = getCurrentContractState(state);
    const serverInfoState = getServerInfoState(state);
    const currentUserInfoState = getCurrentUserInfoState(state);
    const updateState = getUpdateState(state);

    return {
        isLoadedStartableContracts: startableState.isLoaded,
        isLoadedContinuableContracts: continuableState.isLoaded,
        currentUserName: loginState.currentUserName,
        startableContracts: startableState.contracts,
        continuableContracts: continuableState.contracts,
        currentContract: currentContractState.contract,
        errorMessage: startableState.errorMessage
            ? startableState.errorMessage
            : continuableState.errorMessage
            ? continuableState.errorMessage
            : serverInfoState.errorMessage
            ? serverInfoState.errorMessage
            : '',
        isFetching:
            startableState.isFetching || continuableState.isFetching || serverInfoState.isFetching,
        serverVersion: serverInfoState.serverVersion,
        database: serverInfoState.database,
        currentUser: User.create(
            loginState.currentUserName,
            currentUserInfoState.currentUserFirstName,
            currentUserInfoState.currentUserLastName,
            currentUserInfoState.aplixEmployeeId
        ),
        isUpdateAvailable: updateState.isUpdateAvailable,
        currentVersion: ClientVersion,
        newVersion: updateState.newVersion,
    } as TStateProps;
}

export function mapDispatchToProps(
    dispatch: ThunkDispatch<IState, Dispatch, GlobalsAction | ContractsAction | LoginAction>
): TDispatchProps {
    return {
        fetchServerInfo: (forceFetch?: boolean) => dispatch(fetchServerInfo(forceFetch)),
        fetchStartableContracts: (forceFetch?: boolean) =>
            dispatch(fetchStartableContract(forceFetch)),
        fetchContinuableContracts: (forceFetch?: boolean) =>
            dispatch(fetchContinuableContract(forceFetch)),
        logout: () => {
            dispatch(unloadStartableContracts())
            dispatch(unloadContinuableContracts())
            dispatch(logout());
        },
        switchRootPage: (targetPage: RootPage) => dispatch(switchRootPage(targetPage)),
        setBookmarkLastPage: (lastActivePage: RootPage) => dispatch(setBookmarkLastPage(lastActivePage)),
        setBookmarkContractFilters: (contractFilters: IContractFilterState) => 
            dispatch(setBookmarkContractFilters(contractFilters))
    };
}

export const StartPage = connect<TStateProps, TDispatchProps, TOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(StartPageWithStyle);
