import * as React from 'react';
import { AplixErrorDialog, AplixPage, ContractInformation } from '../../../../components';
import { ContinueContractContext, IContinueContractContext } from '../../ContinueContractContext';

type TStyles = {
    continueContractPageRoot: string;
};

const styles: TStyles = require('./ContinueContractSingle.less');

export class ContinueContractSingle extends React.Component<{}> {
    private handleConfirm = (context: IContinueContractContext) => {
        if (context.selectedContract) {
            context.continueContract(context.selectedContract);
        }
    };

    render() {
        return (
            <ContinueContractContext.Consumer>
                {(context) => {
                    const { selectedContract, goToOverviewPage, acceptError } = context;
                    if (!selectedContract) {
                        throw new Error(
                            'Component ContinueContractSingle expects selectedContract to be set'
                        );
                    }
                    return (
                        <AplixPage
                            showBackButton
                            pageTitle={`Auftrag "${selectedContract.nummer}"`}
                            onBackClick={goToOverviewPage}
                        >
                            <div className={styles.continueContractPageRoot}>
                                <ContractInformation
                                    contract={selectedContract}
                                    confirmLabel={'Fortsetzen'}
                                    onConfirm={() => this.handleConfirm(context)}
                                />
                            </div>

                            {context.errorMessage && (
                                <AplixErrorDialog
                                    title="Fehler"
                                    errorMessage={context.errorMessage}
                                    onClose={acceptError}
                                />
                            )}
                        </AplixPage>
                    );
                }}
            </ContinueContractContext.Consumer>
        );
    }
}
