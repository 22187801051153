import * as React from 'react';
import { IContractDetail } from '../../../../../../logic';
import { IQuantityHelper } from '../../../../../../../../core/logic';
import { getService } from '../../../../../../../../ioc_config';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../../../ioc_constants';
import classNames from 'classnames';
import { IQuantityGradeViewHelper } from './IQuantityGradeViewHelper';
import * as _ from 'lodash';
import { IEditDetailStoreQueries } from '../../../../../../logic/IEditDetailStoreQueries';

type TStyles = {
    quantityInformationUnit: string;
    quantityInformationBundle: string;
    quantityInformationRemaining: string;
    quantityInformationRemainingValue: string;
};
const styles: TStyles = require('./QuantityInformation.less');

interface IProps {
    detailStore: IContractDetail;
}

export class QuantityInformation extends React.Component<IProps> {
    private quanitityHelper: IQuantityHelper;
    private viewHelper: IQuantityGradeViewHelper;
    private detailStoreQueries: IEditDetailStoreQueries;

    constructor(props: IProps) {
        super(props);
        this.quanitityHelper = getService<IQuantityHelper>(
            APLIX_SERVICE_IDENTIFIER.IQUANTITYHELPER
        );
        this.viewHelper = getService<IQuantityGradeViewHelper>(
            APLIX_SERVICE_IDENTIFIER.IQUANTITYGRADEVIEWHELPER
        );

        this.detailStoreQueries = getService<IEditDetailStoreQueries>(
            APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREQUERIES
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderUnit()}
                {this.props.detailStore.bundleSize > 1 && (
                    <div className={styles.quantityInformationBundle}>
                        {this.renderBundleCount()}
                        {this.renderBundleRemainder()}
                    </div>
                )}
                {this.renderRemaining()}
            </React.Fragment>
        );
    }

    private renderUnit() {
        return (
            <div
                className={classNames(
                    styles.quantityInformationUnit,
                    this.viewHelper.getGradeClassName(this.props.detailStore)
                )}
            >
                {this.props.detailStore.unit}
            </div>
        );
    }

    private renderBundleCount() {
        const { currentQuantity, bundleSize } = this.props.detailStore;
        const bundleCount = this.quanitityHelper.getBundleCount(currentQuantity, bundleSize);
        return `= ${bundleCount} Gebinde`;
    }

    private renderBundleRemainder() {
        const { currentQuantity, bundleSize, unit } = this.props.detailStore;
        const bundleRemainder = this.quanitityHelper.getBundleRemainder(
            currentQuantity,
            bundleSize
        );

        if (bundleRemainder === 0) {
            return null;
        }

        return (
            <React.Fragment>
                <br />+ {bundleRemainder} {unit}
            </React.Fragment>
        );
    }

    private renderRemaining() {
        return (
            <div className={styles.quantityInformationRemaining}>
                <div>Benötigt:</div>
                <div
                    className={classNames(
                        styles.quantityInformationRemainingValue,
                        styles.quantityInformationUnit,
                        this.viewHelper.getGradeClassName(this.props.detailStore)
                    )}
                >
                    {this.getRemainingQuantityFormatted()} {this.props.detailStore.unit}
                </div>
            </div>
        );
    }

    private getRemainingQuantityFormatted(): string {
        const { decimalPlaces } = this.props.detailStore;
        const remaining = this.detailStoreQueries.getRemaining(this.props.detailStore);
        return _.round(remaining, decimalPlaces).toString();
    }
}
