import * as React from 'react';
import { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { EditDetailContext } from '../../../../EditDetailContext';
import { getService } from '../../../../../../../../ioc_config';
import { IEditDetailStoreActions } from '../../../../../../logic/IEditDetailStoreActions';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../../../ioc_constants';

export const Increment: React.FunctionComponent = () => {
    const [detailStore, setDetailStore] = useContext(EditDetailContext);
    const storeActions = getService<IEditDetailStoreActions>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREACTIONS
    );

    const incrementQuantity = () => {
        setDetailStore(storeActions.incrementQuantity(detailStore, 1));
    };

    return (
        <Button size="medium" variant="contained" onClick={incrementQuantity}>
            <Icon>keyboard_arrow_up</Icon>
        </Button>
    );
};
