import * as React from 'react';
import { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { IGenericProperty } from '../../../../core/entities/IGenericProperty';

interface IProps {
    save: () => void;
    isValid: boolean;
    property: IGenericProperty;
}

export const EditValueGrid: FunctionComponent<IProps> = ({ children, save, isValid, property }) => {
    return (
        <Box width={1}>
            <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={10}>
                    {children}
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => save()}
                        size={'medium'}
                        disabled={!isValid}
                        id={'edit-property-value-submit'}
                    >
                        <Icon>done</Icon>
                    </Button>
                </Grid>
            </Grid>
            {property.information && <p>{property.information}</p>}
        </Box>
    );
};
