import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import { AnyAction, applyMiddleware, compose, createStore, Store } from 'redux';
import * as fromQuino from '../quino/redux';
import * as fromGlobals from './globals/';
import * as fromContracts from './contracts/';
import * as fromStartContracts from '../pages/StartContractPage/redux';
import * as fromFinishContract from '../pages/FinishContractPage/redux';
import { getRootReducer } from './reducers';

export const getPagesState = (appState: IState) => appState.pages;

export interface IState extends fromQuino.IQuinoEnabledStore {
    quino: fromQuino.IQuinoState;
    globals: fromGlobals.IGlobalState;
    contracts: fromContracts.IContractState;
    pages: IPagesState;
}

export interface IPagesState {
    startContract: fromStartContracts.IStartContractState;
    finishContract: fromFinishContract.IFinishContractState;
}
const tryGetDevTools = () => {
    if (typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        return (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        });
    }

    return undefined;
};

const composeEnhancers = tryGetDevTools() || compose;

const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));

export type TAplixAction = AnyAction;
export type TAplixStore = Store<IState> & {
    dispatch: ThunkDispatch<IState, {}, TAplixAction>;
};

let store: TAplixStore;
export function getStore(): TAplixStore {
    if (store === undefined) {
        store = createStore(getRootReducer(), enhancer);
    }
    return store;
}
