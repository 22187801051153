export class User {
    aplixEmployeeId?: number;
    firstName?: string;
    lastName?: string;
    userName?: string;

    static create(
        username?: string,
        firstName?: string,
        lastName?: string,
        aplixEmployeeId?: number
    ): User {
        const user = new User();
        user.aplixEmployeeId = aplixEmployeeId;
        user.firstName = firstName;
        user.lastName = lastName;
        user.userName = username;
        return user;
    }

    getDisplayName(): string {
        let displayName = '';

        const addWithSeparator = (stringToAdd: string, separator: string) => {
            if (displayName !== '') {
                displayName = displayName + separator;
            }

            displayName = displayName + stringToAdd;
        };

        if (this.lastName !== undefined) {
            addWithSeparator(this.lastName, ' ');
        }

        if (this.firstName !== undefined) {
            addWithSeparator(this.firstName, ' ');
        }

        if (displayName === '' && this.userName !== undefined) {
            addWithSeparator(this.userName, ' ');
        }

        return displayName;
    }
}
