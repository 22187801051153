import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { ListLots } from './components/EditLots/ListLots';
import { EditLotDetails } from './components/EditLots/EditLotDetails';
import { EditDetailContext } from './EditDetailContext';
import { useService } from '../../../../ioc/hook/useService';
import APLIX_SERVICE_IDENTIFIER from '../../../../ioc_constants';
import { IEditDetailStoreActions } from '../../logic/IEditDetailStoreActions';
import { EditLotAmount } from './components/EditLots/EditLotAmount';
import { ILot } from '../../../../core/entities/ILot';

interface IProps extends IWizardStepProps {}

enum SubPage {
    List,
    EditLotDetails,
    EditLotAmount,
}

export const EditLotsPage: React.FunctionComponent<IProps> = ({
    goToPreviousStep,
    goToNextStep,
}) => {
    const detailStoreActions = useService<IEditDetailStoreActions>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREACTIONS
    );

    const [detailStore, setDetailStore] = useContext(EditDetailContext);
    const [currentPage, setCurrentPage] = useState<SubPage>(SubPage.List);
    const [selectedLotNumber, setSelectedLotNumber] = useState<string>();
    const [currentLot, setCurrentLot] = useState<Partial<ILot>>({});

    useEffect(() => {
        if (currentPage === SubPage.List) {
            setSelectedLotNumber(undefined);
        }
    }, [currentPage]);

    const openEditor = (lotNumber?: string) => {
        const lot: Partial<ILot> = detailStore.lots.find((l) => l.number === lotNumber) || {
            number: lotNumber,
        };
        setSelectedLotNumber(lotNumber);
        setCurrentLot(lot);
        setCurrentPage(SubPage.EditLotDetails);
    };

    const saveLotDetails = (lot: Partial<ILot>) => {
        setCurrentLot(lot);
        setCurrentPage(SubPage.EditLotAmount);
    };

    const saveLotAmount = (lot: ILot) => {
        if (selectedLotNumber && selectedLotNumber !== lot.number) {
            deleteLot(selectedLotNumber);
        }
        setDetailStore((detailStore) => detailStoreActions.saveLot(detailStore, lot));
        setCurrentPage(SubPage.List);
    };

    const deleteLot = (lotNumber: string) => {
        setDetailStore((detailStore) => detailStoreActions.deleteLot(detailStore, lotNumber));
    };

    switch (currentPage) {
        case SubPage.EditLotDetails:
            return (
                <EditLotDetails
                    goBack={() => setCurrentPage(SubPage.List)}
                    lot={currentLot}
                    save={saveLotDetails}
                />
            );
        case SubPage.EditLotAmount:
            return (
                <EditLotAmount
                    goBack={() => setCurrentPage(SubPage.EditLotDetails)}
                    lot={currentLot}
                    save={saveLotAmount}
                />
            );
        case SubPage.List:
            return (
                <ListLots
                    goBack={goToPreviousStep}
                    openEditor={openEditor}
                    submit={goToNextStep}
                    deleteLot={deleteLot}
                />
            );
    }
};
