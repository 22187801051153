import * as React from 'react';
import { useState } from 'react';
import { ContractInputForm } from './ContractInputForm/ContractInputForm';

interface IProps {
    onSubmit: (contractNumber: string) => void;
    registerOnBackButtonClick: (callback: () => void) => void;
    navigateToHome: () => void;
    visible: boolean;
}

enum SubPage {
    ContractList,
    ContractInputForm,
}

export const ContractSelector: React.FunctionComponent<IProps> = ({
    onSubmit,
    registerOnBackButtonClick,
    navigateToHome,
    visible,
}) => {
    const [subPage, setSubPage] = useState<SubPage>(SubPage.ContractInputForm);

    const showContractList = () => setSubPage(SubPage.ContractList);

    return (
        <ContractInputForm
            onSubmit={onSubmit}
            showContractList={showContractList}
            registerOnBackButtonClick={registerOnBackButtonClick}
            navigateToHome={navigateToHome}
            visible={visible && subPage === SubPage.ContractInputForm}
        />
    );
};
