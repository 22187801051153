export type FinishContractAction =
    | FinishContractBegin
    | FinishContractSuccess
    | FinishContractError
    | FinishContractEnd;

export const FINISH_CONTRACT_BEGIN = 'FINISH_CONTRACT_BEGIN';
export type FINISH_CONTRACT_POST_BEGIN = typeof FINISH_CONTRACT_BEGIN;
export type FinishContractBegin = {
    type: FINISH_CONTRACT_POST_BEGIN;
    payload: { contractNumber: string };
};

export const FINISH_CONTRACT_SUCCESS = 'FINISH_CONTRACT_SUCCESS';
export type FINISH_CONTRACT_SUCCESS = typeof FINISH_CONTRACT_SUCCESS;
export type FinishContractSuccess = {
    type: FINISH_CONTRACT_SUCCESS;
};

export const FINISH_CONTRACT_ERROR = 'FINISH_CONTRACT_ERROR';
export type FINISH_CONTRACT_ERROR = typeof FINISH_CONTRACT_ERROR;
export type FinishContractError = {
    type: FINISH_CONTRACT_ERROR;
    payload: { errorMessage: string };
};

export const FINISH_CONTRACT_END = 'FINISH_CONTRACT_END';
export type FINISH_CONTRACT_END = typeof FINISH_CONTRACT_END;
export type FinishContractEnd = {
    type: FINISH_CONTRACT_END;
};
