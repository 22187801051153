import { inject, injectable } from 'inversify';
import { IAuthenticationTokenProvider, IDataService, IMetadataService } from '..';
import {
    IEntityIdentifier,
    IGenericObject,
    ILayout,
    IView,
    IViewIdentifier,
    TPrimaryKey,
} from '../..';
import { IFetcher, IUrlManager } from '.';
import { ILookupValue } from '../../data/ILookupValue';
import SERVICE_IDENTIFIER from '../../ioc/constants/identifiers';

@injectable()
export class QuinoServerService implements IDataService, IMetadataService {
    protected urlManager: IUrlManager;
    protected fetcher: IFetcher;
    protected authTokenProvider: IAuthenticationTokenProvider;

    constructor(
        @inject(SERVICE_IDENTIFIER.IURLMANAGER) urlManager: IUrlManager,
        @inject(SERVICE_IDENTIFIER.IFETCHER) fetcher: IFetcher,
        @inject(SERVICE_IDENTIFIER.IAUTHENTICATIONTOKENPROVIDER)
        authTokenProvider: IAuthenticationTokenProvider
    ) {
        this.urlManager = urlManager;
        this.fetcher = fetcher;
        this.authTokenProvider = authTokenProvider;
    }

    async logInAsync(username: string, password: string) {
        const url = this.urlManager.getLoginUrl();
        const token = await this.fetcher.postWithDynamicResponseAsync<string>(
            url,
            { username, password },
            (r) => r.text()
        );

        this.authTokenProvider.setToken(token);
    }

    logOut(): void {
        this.authTokenProvider.unsetToken();
    }

    async getListAsync<TPayload>(className: string, layoutName: string): Promise<TPayload> {
        const url = this.urlManager.getListUrl(className);
        const finalUrl = url.concat(`?layout=${layoutName}`);
        return await this.fetcher.fetchAsync<TPayload>(finalUrl);
    }

    async getObjectAsync<TPayload>(className: string, primaryKey: TPrimaryKey): Promise<TPayload> {
        const url = this.urlManager.getObjectUrl(className, primaryKey);
        return await this.fetcher.fetchAsync<TPayload>(url);
    }

    async getRelatedObjectsAsync<T extends IGenericObject>(
        className: string,
        primaryKey: TPrimaryKey,
        relationName: string
    ): Promise<T[]> {
        const url = this.urlManager.getRelatedObjectsForUrl(className, primaryKey, relationName);
        return await this.fetcher.fetchAsync<T[]>(url);
    }

    async getRelatedObjectAsync<T extends IGenericObject>(
        className: string,
        primaryKey: TPrimaryKey,
        relationName: string
    ): Promise<T> {
        const url = this.urlManager.getRelatedObjectForUrl(className, primaryKey, relationName);
        return await this.fetcher.fetchAsync<T>(url);
    }

    async saveObjectAsync(
        className: string,
        primaryKey: TPrimaryKey,
        data: IGenericObject
    ): Promise<any> {
        const url = this.urlManager.saveObjectUrl(className, primaryKey);
        return await this.fetcher.patchAsync<any>(url, data);
    }

    async getLookupValuesAsync(
        className: string,
        primaryKey: TPrimaryKey,
        relationName: string
    ): Promise<ILookupValue[]> {
        const url = this.urlManager.getLookupValuesUrl(className, primaryKey, relationName);
        return await this.fetcher.fetchAsync<any>(url);
    }

    async getEntities(): Promise<IEntityIdentifier[]> {
        const url = this.urlManager.getAllEntitiesUrl();
        return await this.fetcher.fetchAsync<any>(url);
    }

    async getViewsForEntity(className: string): Promise<IViewIdentifier[]> {
        const url = this.urlManager.getViewsForEntityUrl(className);
        return await this.fetcher.fetchAsync<any>(url);
    }

    async getView(
        className: string,
        listLViewName: string,
        detailViewName?: string
    ): Promise<IView> {
        const listViewUrl = this.urlManager.getViewUrl(className, listLViewName);
        const fetchListView = this.fetcher.fetchAsync<ILayout>(listViewUrl);

        let fetchDetailView: Promise<ILayout | undefined> = Promise.resolve(undefined);
        if (detailViewName) {
            const detailViewUrl = this.urlManager.getViewUrl(className, detailViewName);
            fetchDetailView = this.fetcher.fetchAsync<any>(detailViewUrl);
        }

        const [listLayout, detailLayout] = await Promise.all([fetchListView, fetchDetailView]);

        return {
            entityName: className,
            name: listLViewName,
            title: listLayout.caption,
            layout: listLayout,
            detailLayout: detailLayout,
        };
    }
}
