import * as React from 'react';
import InProgressCircle from '@material-ui/icons/ShoppingCart';

type TStyles = {
    startpageInfoLabel: string;
};

const styles: TStyles = require('../../StartPage.less');

export type TProps = {
    contractNumber?: string;
};

export const CurrentContract = (props: TProps) => (
    <div className={styles.startpageInfoLabel}>
        <InProgressCircle style={{ verticalAlign: 'middle' }} titleAccess={props.contractNumber} />
        <div style={{ verticalAlign: 'middle' }}>
            <span>{props.contractNumber}</span>
        </div>
    </div>
);
