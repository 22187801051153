import * as React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

export type TProps = {
    id: string;
    label?: string;
    value: boolean;
    className?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export class AplixSwitch extends React.PureComponent<TProps> {
    render() {
        const { props } = this;

        return (
            <FormControlLabel
                id={props.id}
                className={props.className}
                control={
                    <Switch
                        checked={props.value}
                        onChange={props.onChange}
                        value={props.label}
                        color="primary"
                    />
                }
                label={props.label}
            />
        );
    }
}
