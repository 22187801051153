import * as React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { IChoice } from '../../../../core/entities/IChoice';

type TStyles = {
    choiceButton: string;
    scrollY: string;
};

const styles: TStyles = require('./ChoiceFilter.less');

interface IProps {
    choices: IChoice[];
    selectChoiceID: (id: string) => void;
}

export const ChoiceFilter: React.FunctionComponent<IProps> = ({ choices, selectChoiceID }) => {
    choices.sort();

    return (
        <Box display={'flex'} flexDirection={'column'} height={1}>
            <Typography variant={'h5'}>Kategorie wählen</Typography>

            <Box py={2} flexGrow={1} className={styles.scrollY}>
                {choices.map((choice) => {
                    return (
                        <Button
                            key={choice.primaryKey}
                            className={styles.choiceButton}
                            size={'large'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => selectChoiceID(choice.primaryKey)}
                        >
                            {choice.label}
                        </Button>
                    );
                })}
            </Box>
        </Box>
    );
};
