import * as React from 'react';
import { AplixErrorBox } from '../../../../components';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

type TStyles = {
    contractstartingRoot: string;
    contractstartingProgress: string;
};

const styles: TStyles = require('./ContractStarting.less');

export type TProps = {
    contractNumber: string;
    isFetching: boolean;
    errorMessage?: string;
    onGoBack: () => void;
};

export const ContractStarting = (props: TProps) => (
    <div className={styles.contractstartingRoot}>
        {props.isFetching && !props.errorMessage && (
            <div className={styles.contractstartingProgress}>
                <CircularProgress
                    variant="indeterminate"
                    color="primary"
                    style={{ verticalAlign: 'middle', marginRight: 5 }}
                />
                <div>
                    Auftrag &lsquo;<em>{props.contractNumber}</em>&rsquo; wird gestartet...
                </div>
            </div>
        )}

        {props.errorMessage && (
            <AplixErrorBox>
                <h2>Auftrag konnte nicht gestartet werden!</h2>
                <p>
                    <strong>Meldung: </strong>
                    {props.errorMessage}
                </p>

                <Button color="default" variant="contained" onClick={props.onGoBack}>
                    Zurück
                </Button>
            </AplixErrorBox>
        )}
    </div>
);
