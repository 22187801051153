import * as React from 'react';
import { useContext } from 'react';
import { EditDetailContext } from '../../EditDetailContext';
import { useService } from '../../../../../../ioc/hook/useService';
import { IEditDetailStoreQueries } from '../../../../logic/IEditDetailStoreQueries';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../ioc_constants';
import { EditAmount } from '../EditAmount';
import { IPlace } from '../../../../../../core/entities/IPlace';

interface IProps {
    place: Partial<IPlace>;
    goBack: () => void;
    save: (place: IPlace) => void;
}

export const EditPlaceAmount: React.FunctionComponent<IProps> = ({ place, goBack, ...props }) => {
    const [detailStore] = useContext(EditDetailContext);

    const detailStoreQueries = useService<IEditDetailStoreQueries>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREQUERIES
    );

    const { remainingAmount, amountPerPlace } = detailStoreQueries.getMaxPlaceAmount(
        detailStore,
        place
    );

    const maxAmount = Math.min(remainingAmount, amountPerPlace);

    const save = (amount: number) => {
        props.save({ ...place, amount } as IPlace);
    };

    return (
        <EditAmount
            goBack={goBack}
            initialValue={place.amount || maxAmount}
            amountType={'Lagerplatz'}
            save={save}
            maxAmount={remainingAmount}
            capacity={amountPerPlace}
        />
    );
};
