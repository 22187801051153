import { IContract, IContractDetailDTO } from '../../core';
import { IState } from '../store';
import { IContractProperty } from '../../core/entities/IContractProperty';
import { ISaveDetailState } from './saveDetailState';

export const getContractState = (appState: IState): IContractState => appState.contracts;

export const getStartableContractsState = (appState: IState): IStartableContractsState =>
    getContractState(appState).startable;

export const getStartableContracts = (appState: IState): IContract[] =>
    getContractState(appState).startable.contracts;

export const getFirstStartableContract = (appState: IState): IContract | undefined =>
    getStartableContracts(appState)[0];

export const getContinuableContractsState = (appState: IState): IContinuableContractsState =>
    getContractState(appState).continuable;

export const getCurrentContractState = (appState: IState): ICurrentContractState =>
    getContractState(appState).current;

export const isSavingCurrentContract = (appState: IState): boolean =>
    getCurrentContractState(appState).isSaving;

export const isFetchingCurrentContract = (appState: IState): boolean =>
    getCurrentContractState(appState).isFetching;

export const getCurrentContract = (appState: IState): IContract | undefined =>
    getCurrentContractState(appState).contract;

export const getContractErrorMessage = (state: IState): string | undefined =>
    state.contracts.current.errorMessage;

export interface IContractState {
    startable: IStartableContractsState;
    continuable: IContinuableContractsState;
    current: ICurrentContractState;
}

export interface IStartableContractsState {
    contracts: IContract[];
    isLoaded: boolean;
    isFetching: boolean;
    errorMessage?: string;
}

export interface IContinuableContractsState {
    contracts: IContract[];
    isLoaded: boolean;
    isFetching: boolean;
    errorMessage?: string;
}

export interface ICurrentContractState {
    contract?: IContract;
    isLoaded: boolean;
    isPausing: boolean;
    isFetching: boolean;
    isSaving: boolean;
    errorMessage?: string;
    details?: IContractDetailDTO[];
    properties: IContractProperty[];
    saveDetail: ISaveDetailState;
}
