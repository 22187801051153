import { IGlobalUiState, RootPage, GlobalsAction, SWITCH_ROOT_PAGE } from '..';

const initial: IGlobalUiState = {
    rootPage: RootPage.StartPage,
};

export function globalUiReducer(
    state: IGlobalUiState = initial,
    action: GlobalsAction
): IGlobalUiState {
    switch (action.type) {
        case SWITCH_ROOT_PAGE:
            return {
                ...state,
                rootPage: action.targetPage,
            };
    }

    return state;
}
