import { IUpdateState } from '../state';
import { GlobalsAction, UPDATE_AVAILABLE } from '../actions';

const initial: IUpdateState = {
    isUpdateAvailable: false,
    newVersion: '',
};

export function updateReducer(state: IUpdateState = initial, action: GlobalsAction): IUpdateState {
    switch (action.type) {
        case UPDATE_AVAILABLE:
            return {
                ...state,
                isUpdateAvailable: true,
                newVersion: action.newVersion,
            };
    }

    return state;
}
