import { IContractDetailDTO } from '../../entities';
import { inject, injectable } from 'inversify';
import APLIX_SERVICE_IDENTIFIER from '../../../ioc_constants';
import { IBarcodeParserStrategy } from './IBarcodeParserStrategy';
import { IAmounts } from './IAmounts';

export interface IBarcodeStrategyRunner {
    run(
        barcode: string,
        detail: IContractDetailDTO,
        detailBarcode?: string,
        factor?: number
    ): IAmounts | null;
}

@injectable()
export class BarcodeStrategyRunner implements IBarcodeStrategyRunner {
    private readonly strategies: IBarcodeParserStrategy[];

    constructor(
        @inject(APLIX_SERVICE_IDENTIFIER.IEXACTSTRATEGY) exactStrategy: IBarcodeParserStrategy,
        @inject(APLIX_SERVICE_IDENTIFIER.IWEIGHTSTRATEGY) weightStrategy: IBarcodeParserStrategy,
        @inject(APLIX_SERVICE_IDENTIFIER.IPRICESTRATEGY) priceStrategy: IBarcodeParserStrategy
    ) {
        this.strategies = [exactStrategy, weightStrategy, priceStrategy];
    }

    run(
        barcode: string,
        detail: IContractDetailDTO,
        detailBarcode?: string,
        factor?: number
    ): IAmounts | null {
        for (const strategy of this.strategies) {
            const result = strategy.parseBarcode(barcode, detail, detailBarcode, factor);
            if (result) {
                return result;
            }
        }
        return null;
    }
}
