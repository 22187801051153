import {
    FETCH_CURRENT_USER_INFO_BEGIN,
    FETCH_CURRENT_USER_INFO_ERROR,
    FETCH_CURRENT_USER_INFO_SUCCESS,
    FETCH_SERVER_INFO_BEGIN,
    FETCH_SERVER_INFO_ERROR,
    FETCH_SERVER_INFO_SUCCESS,
    FetchCurrentUserInfoBegin,
    FetchCurrentUserInfoError,
    FetchCurrentUserInfoSuccess,
    FetchServerInfoBegin,
    FetchServerInfoError,
    FetchServerInfoSuccess,
    SET_BOOKMARK_CONTRACT_FILTERS,
    SET_BOOKMARK_LAST_PAGE,
    SetBookmarkContractFilters,
    SetBookmarkLastPage,
    SWITCH_ROOT_PAGE,
    SwitchRootPage,
    UPDATE_AVAILABLE,
    UpdateAvailable,
} from './types';

import {IContractFilterState, RootPage} from '..';
import {IServerInfo} from '../../../core/api/IServerInfo';
import {IEnvironmentInfoDTO} from '../../../core/api/DTOs/IEnvironmentInfoDTO';

export function switchRootPage(targetPage: RootPage): SwitchRootPage {
    return {
        type: SWITCH_ROOT_PAGE,
        targetPage,
    };
}

export const fetchServerInfoBegin = (): FetchServerInfoBegin => ({
    type: FETCH_SERVER_INFO_BEGIN,
});

export const fetchServerInfoSuccess = (
    serverInfo: IServerInfo & IEnvironmentInfoDTO
): FetchServerInfoSuccess => ({
    type: FETCH_SERVER_INFO_SUCCESS,
    serverInfo,
});

export const fetchServerInfoError = (errorMessage: string): FetchServerInfoError => ({
    type: FETCH_SERVER_INFO_ERROR,
    errorMessage,
});

export const fetchCurrentUserInfoBegin = (): FetchCurrentUserInfoBegin => ({
    type: FETCH_CURRENT_USER_INFO_BEGIN,
});

export const fetchCurrentUserInfoSuccess = (
    aplixmitarbeiterid?: number,
    currentUserFirstName?: string,
    currentUserLastName?: string
): FetchCurrentUserInfoSuccess => ({
    type: FETCH_CURRENT_USER_INFO_SUCCESS,
    aplixmitarbeiterid,
    currentUserFirstName,
    currentUserLastName,
});

export const fetchCurrentUserInfoError = (message: string): FetchCurrentUserInfoError => ({
    type: FETCH_CURRENT_USER_INFO_ERROR,
    message,
});

export const updateAvailable = (currentVersion: string, newVersion: string): UpdateAvailable => ({
    type: UPDATE_AVAILABLE,
    newVersion,
});

export const setBookmarkLastPage = (lastActivePage: RootPage): SetBookmarkLastPage => ({
    type: SET_BOOKMARK_LAST_PAGE,
    lastActivePage: lastActivePage,
})

export const setBookmarkContractFilters = (contractFilters: IContractFilterState): SetBookmarkContractFilters => ({
    type: SET_BOOKMARK_CONTRACT_FILTERS,
    contractFilters: contractFilters,
})