import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { getService } from '../../../../../../ioc_config';
import { IMarkdownConverter } from '../../../../../../quino';
import SERVICE_IDENTIFIER from '../../../../../../quino/core/ioc/constants/identifiers';

type TStyles = {
    informationPanelRoot: string;
};

const styles: TStyles = require('./InformationPanel.less');

export type TProps = {
    information: string;
    id?: string;
};

// noinspection TsLint
export const InformationPanel = ({ information, id }: TProps) => {
    const markdownConverter = getService<IMarkdownConverter>(
        SERVICE_IDENTIFIER.IMARKDOWNCONVERTER
    );
    
    return markdownConverter.isMarkdown(information) ? (
        <Paper id={id} elevation={1} className={styles.informationPanelRoot}
               dangerouslySetInnerHTML={{__html: markdownConverter.convertToHtml(information)}} />
    ) : (
        <Paper id={id} elevation={1} className={styles.informationPanelRoot}>
            <Typography component="p">{information}</Typography>
        </Paper>
    );
};
