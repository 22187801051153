import SERVICE_IDENTIFIER from '../../quino/core/ioc/constants/identifiers';
import { IMetadataService, IDataService, getQuinoContainer } from '../../quino/core';
import { inject, injectable } from 'inversify';
import { IAplixApiManager, IAplixService } from '..';
import { ApiManager } from '../../quino/core/api/ApiManager';
import APLIX_SERVICE_IDENTIFIER from '../../ioc_constants';

@injectable()
export class AplixApiManager extends ApiManager implements IAplixApiManager {
    aplixService: IAplixService;

    constructor(
        @inject(SERVICE_IDENTIFIER.IMETADATASERVICE) metadataService: IMetadataService,
        @inject(SERVICE_IDENTIFIER.IDATASERVICE) dataService: IDataService,
        @inject(APLIX_SERVICE_IDENTIFIER.IAPLIXSERVICE) aplixService: IAplixService
    ) {
        super(metadataService, dataService);
        this.aplixService = aplixService;
    }
}

export function aplixApiManager(): IAplixApiManager {
    return getQuinoContainer().get<IAplixApiManager>(APLIX_SERVICE_IDENTIFIER.IAPLIXAPIMANAGER);
}
