import { IContractDetailDTO } from '../../entities';
import { IAmounts } from './IAmounts';
import { injectable } from 'inversify';
import { IBarcodeParserStrategy } from './IBarcodeParserStrategy';
import * as _ from 'lodash';

@injectable()
export class PriceStrategy implements IBarcodeParserStrategy {
    parseBarcode(
        readBarcode: string,
        { kommastellen: decimalPlaces, preis: pricePerUnit = 0 }: IContractDetailDTO,
        detailBarcode?: string
    ): IAmounts | null {
        const matchWeightBarcode = /0*(\d+)P.*/.exec(detailBarcode || '');

        if (!matchWeightBarcode) {
            return null;
        }

        const productCode = matchWeightBarcode[1];

        if (!readBarcode.startsWith(productCode)) {
            return null;
        }

        const scannedRappenPrice = parseInt(readBarcode.slice(productCode.length, -1), 10);
        const scannedPrice = scannedRappenPrice / 100 || 0;
        const productWeight = pricePerUnit > 0 ? scannedPrice / pricePerUnit : 0;

        return {
            amount: _.round(productWeight, decimalPlaces),
            factor: 1,
        };
    }
}
