import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import ScannerIcon from '@material-ui/icons/CameraAlt';
import classNames from 'classnames';
import { getService } from '../../ioc_config';
import APLIX_SERVICE_IDENTIFIER from '../../ioc_constants';
import { IScannerService } from '../../core/scanner/IScannerService';

type TStyles = {
    aplixInputFullWidth: string;
    aplixInputDisabled: string;
};

const styles: TStyles = require('./AplixInput.less');

export type TProps = {
    id: string;
    autoFocus?: boolean;
    classes?: object;
    className?: string;
    defaultValue?: string | number;
    disabled?: boolean;
    disableEnterFocusNextControl?: boolean;
    errorMessage?: string;
    fullWidth?: boolean;
    inputProps?: { [arbitrary: string]: any };
    helpMessage?: string;
    label?: string;
    multiline?: boolean;
    placeholder?: string;
    required?: boolean;
    rows?: string | number;
    rowsMax?: string | number;
    showBarCodeAdornment?: boolean;
    showKeyboardAdornment?: boolean;
    type?: string;
    value?: string | number;

    // TODO: This is a bad API as it exposes raw event data. Instead a more concrete parameter would help.
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};

export class AplixInput extends React.PureComponent<TProps, { value?: string | number }> {
    private handleKeyPress = (event: React.KeyboardEvent<any>) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
            this.focusNextControl(event);
        }
    };

    private focusNextControl = (event: React.KeyboardEvent<any>) => {
        if (this.props.disableEnterFocusNextControl) {
            return;
        }

        const form = (event.target as any).form;

        if (!form) {
            return;
        }

        const index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1].focus();
    };

    constructor(props: TProps) {
        super(props);

        this.state = {
            value: props.value,
        };

        this.inputRef = React.createRef();
    }

    render() {
        const { props } = this;
        const scannerService = getService<IScannerService>(
            APLIX_SERVICE_IDENTIFIER.ISCANNERSERVICE
        );

        const onScan = (code: string) => {
            this.setState({ value: code });
            if (props.onChange && this.inputRef.current) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                props.onChange({ target: { value: code } });
            }
        };

        return (
            <FormControl
                required={props.required}
                autoCorrect={undefined}
                disabled={props.disabled}
                classes={props.classes}
                className={classNames(
                    props.className,
                    props.fullWidth ? styles.aplixInputFullWidth : '',
                    props.disabled === true ? styles.aplixInputDisabled : ''
                )}
                fullWidth={props.fullWidth}
                error={props.errorMessage !== undefined}
            >
                <InputLabel required={props.required} disabled={props.disabled}>
                    {props.label}
                </InputLabel>

                <Input
                    id={props.id}
                    lang={'en'}
                    type={props.type}
                    value={this.state.value}
                    autoFocus={props.autoFocus}
                    inputRef={this.inputRef}
                    defaultValue={props.defaultValue}
                    disabled={props.disabled}
                    multiline={props.multiline}
                    rows={props.rows}
                    rowsMax={props.rowsMax}
                    placeholder={props.placeholder}
                    fullWidth={props.fullWidth}
                    inputProps={props.inputProps}
                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    error={props.errorMessage !== undefined}
                    onChange={(event) => {
                        this.setState({ value: event.target.value });
                        if (props.onChange) {
                            props.onChange(event);
                        }
                    }}
                    onKeyPress={this.handleKeyPress}
                    endAdornment={
                        (props.showKeyboardAdornment || props.showBarCodeAdornment) && (
                            <InputAdornment position="end">
                                {props.showKeyboardAdornment && (
                                    <Tooltip title="Tastatureingabe">
                                        <KeyboardIcon color="disabled" />
                                    </Tooltip>
                                )}
                                {props.showBarCodeAdornment && scannerService.enabled() && (
                                    <Tooltip title="Barcode-Scannereingabe">
                                        <ScannerIcon onClick={() => scannerService.show(onScan)} />
                                    </Tooltip>
                                )}
                            </InputAdornment>
                        )
                    }
                    onKeyDown={(e) => {
                        if (
                            props.showBarCodeAdornment &&
                            e.keyCode === 0 &&
                            scannerService.enabled()
                        ) {
                            e.preventDefault();
                            scannerService.show(onScan);
                        }
                    }}
                />

                {props.errorMessage && (
                    <FormHelperText disabled={props.disabled} error>
                        {props.errorMessage}
                    </FormHelperText>
                )}
                {props.helpMessage && (
                    <FormHelperText disabled={props.disabled}>{props.helpMessage}</FormHelperText>
                )}
            </FormControl>
        );
    }

    private readonly inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
}
