import {GlobalsAction, IBookmarkState, RootPage, SET_BOOKMARK_CONTRACT_FILTERS, SET_BOOKMARK_LAST_PAGE} from '..';

const initial: IBookmarkState = {
    lastActivePage: RootPage.StartPage,
    contractFilters: {}
};

export function bookmarkReducer(
    state: IBookmarkState = initial,
    action: GlobalsAction
): IBookmarkState {
    switch (action.type) {
        case SET_BOOKMARK_LAST_PAGE:
            return {
                ...state,
                lastActivePage: action.lastActivePage
            };
        case SET_BOOKMARK_CONTRACT_FILTERS:
            return {
                ...state,
                contractFilters: action.contractFilters
            };
    }

    return state;
}
