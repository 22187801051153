import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {IContractDetail} from '../../logic';
import {PrepareContractContext} from '../../PrepareContractContext';
import {EditDetailContext} from './EditDetailContext';
import {EditQuantityPage} from './EditQuantityPage';
import {SavePage} from './SavePage';
import {EditLotsPage} from './EditLotsPage';
import {EditPlacesPage} from './EditPlacesPage';
import {EditPropertiesPage} from './EditPropertiesPage';
import {ReallyQuitPage} from './ReallyQuitPage';
import * as _ from 'lodash';
import {ScanType} from '../../../../core/entities/ScanType';
import {VerifyDetailPage} from './components/VerifyDetail/VerifyDetailPage';

enum WizardStep {
    ReallyQuit,
    VerifyDetail,
    EditFirstRoundProperties,
    EditQuantity,
    EditLots,
    EditPlaces,
    EditSecondRoundProperties,
    Save,
    ExitWizard,
}

interface IProps {
    detailStore: IContractDetail;
}

export const EditDetailWizard: React.FunctionComponent<IProps> = (props) => {
    const prepareContractContext = useContext(PrepareContractContext);

    const getInitialWizardStep = () => {
        const verificationNeeded = props.detailStore.scanType == ScanType.Product;
        return verificationNeeded ? WizardStep.VerifyDetail : WizardStep.EditFirstRoundProperties;
    };

    const [wizardStep, setWizardStep] = useState(getInitialWizardStep);

    const [detailStore, setDetailStore] = useState<IContractDetail>(props.detailStore);

    useEffect(() => {
        if (wizardStep === WizardStep.ExitWizard) {
            prepareContractContext.goToListPage();
        }
    }, [wizardStep]);

    const saveAndQuit = () => {
        setWizardStep(WizardStep.Save);
    };

    const isDetailChanged = () => !_.isEqual(detailStore, props.detailStore);
    const hasProperties = () => detailStore.properties.length > 0;
    
    const getNextStep = (wizardStep: WizardStep): WizardStep => {
        switch (wizardStep) {
            case WizardStep.ReallyQuit:
            case WizardStep.VerifyDetail:
                return hasProperties
                    ? WizardStep.EditFirstRoundProperties
                    : getNextStep(WizardStep.EditFirstRoundProperties);
            case WizardStep.EditFirstRoundProperties:    
                return WizardStep.EditQuantity;
            case WizardStep.EditQuantity:
                return detailStore.hasLots ? WizardStep.EditLots : getNextStep(WizardStep.EditLots);
            case WizardStep.EditLots:
                return detailStore.hasPlaces
                    ? WizardStep.EditPlaces
                    : getNextStep(WizardStep.EditPlaces);
            case WizardStep.EditPlaces:
                return hasProperties
                    ? WizardStep.EditSecondRoundProperties
                    : getNextStep(WizardStep.EditSecondRoundProperties);
            case WizardStep.EditSecondRoundProperties:
                return WizardStep.Save;
            case WizardStep.Save:
                return WizardStep.ExitWizard;
            case WizardStep.ExitWizard:
                return WizardStep.ExitWizard;
        }
    };

    const getPreviousStep = (wizardStep: WizardStep): WizardStep => {
        switch (wizardStep) {
            case WizardStep.ReallyQuit:
            case WizardStep.VerifyDetail:
            case WizardStep.EditFirstRoundProperties:    
                return WizardStep.ExitWizard;
            case WizardStep.EditQuantity:
                return isDetailChanged() ? WizardStep.ReallyQuit : WizardStep.ExitWizard;
            case WizardStep.EditLots:
                return WizardStep.EditQuantity;
            case WizardStep.EditPlaces:
                return detailStore.hasLots
                    ? WizardStep.EditLots
                    : getPreviousStep(WizardStep.EditLots);
            case WizardStep.EditSecondRoundProperties:
                return detailStore.hasPlaces
                    ? WizardStep.EditPlaces
                    : getPreviousStep(WizardStep.EditPlaces);
            case WizardStep.Save:
                return detailStore.properties.length > 0
                    ? WizardStep.EditSecondRoundProperties
                    : getPreviousStep(WizardStep.EditSecondRoundProperties);
            case WizardStep.ExitWizard:
                return WizardStep.ExitWizard;
        }
    };

    const goToPreviousStep = () => {
        setWizardStep(getPreviousStep);
    };

    const goToNextStep = () => {
        setWizardStep(getNextStep);
    };

    const wizardProps: IWizardStepProps = {
        goToPreviousStep,
        goToNextStep,
    };

    return (
        <EditDetailContext.Provider value={[detailStore, setDetailStore]}>
            {wizardStep === WizardStep.ReallyQuit && <ReallyQuitPage {...wizardProps} />}
            {wizardStep === WizardStep.VerifyDetail && (
                <VerifyDetailPage {...wizardProps} saveAndQuit={saveAndQuit}/>
            )}
            {wizardStep === WizardStep.EditFirstRoundProperties && <EditPropertiesPage {...wizardProps} validateRequired={false} />}
            {wizardStep === WizardStep.EditQuantity && <EditQuantityPage {...wizardProps} />}
            {wizardStep === WizardStep.EditLots && <EditLotsPage {...wizardProps} />}
            {wizardStep === WizardStep.EditPlaces && <EditPlacesPage {...wizardProps} />}
            {wizardStep === WizardStep.EditSecondRoundProperties && <EditPropertiesPage {...wizardProps} validateRequired={true} />}
            {wizardStep === WizardStep.Save && (
                <SavePage {...wizardProps} goToNextStep={prepareContractContext.goToListPage}/>
            )}
        </EditDetailContext.Provider>
    );
};
