import { combineReducers } from 'redux';
import { IState } from '../store';
import * as fromGlobals from '../globals';
import * as fromContracts from '../contracts';
import * as fromQuino from '../../quino/redux';
import { getPagesReducer } from './pagesReducer';

export function getRootReducer() {
    return combineReducers<IState>({
        quino: fromQuino.quinoReducer,
        globals: fromGlobals.reducer,
        contracts: fromContracts.reducer,
        pages: getPagesReducer(),
    });
}
