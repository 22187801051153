import * as React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { IEditQuantityContext } from '../../IEditQuantityContext';
import { EditQuantityMode } from '../../EditQuantity';

interface IProps {
    context: IEditQuantityContext;
}

export class EditModeToggle extends React.Component<IProps> {
    render() {
        let onClick: () => void;
        let iconName: string;

        switch (this.props.context.getMode()) {
            case EditQuantityMode.Readonly:
                onClick = () => {
                    this.props.context.startEdit();
                };
                iconName = 'keyboard';
                break;
            case EditQuantityMode.Edit:
            default:
                onClick = () => {
                    this.props.context.cancelEdit();
                };
                iconName = 'keyboard_hide';
                break;
        }

        return (
            <Button size="medium" variant="contained" onClick={onClick}>
                <Icon>{iconName}</Icon>
            </Button>
        );
    }
}
