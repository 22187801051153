import { getQuinoState, IQuinoEnabledStore } from '../..';

export const getLoginState = (appState: IQuinoEnabledStore) => getQuinoState(appState).login;

export interface ILoginState {
    isLoggedIn: boolean;
    isLoginInProgress: boolean;
    currentUserName?: string;
    errorMessage?: string;
    loginToken?: string;
}
