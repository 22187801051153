import { inject, injectable } from 'inversify';
import SERVICE_IDENTIFIER from '../../quino/core/ioc/constants/identifiers';
import { IFetcher } from '../../quino/core/api/QuinoServer';
import { IManifest } from './IManifest';
import APLIX_SERVICE_IDENTIFIER from '../../ioc_constants';
import { IAplixUrlManager } from '..';
import { IManifestDownloader } from './IManifestDownloader';

@injectable()
export class ManifestDownloader implements IManifestDownloader {
    constructor(
        @inject(SERVICE_IDENTIFIER.IFETCHER) private fetcher: IFetcher,
        @inject(APLIX_SERVICE_IDENTIFIER.IAPLIXURLMANAGER) private aplixUrlManager: IAplixUrlManager
    ) {}

    async downloadAsync(): Promise<IManifest | null> {
        try {
            const url = this.aplixUrlManager.getManifestUrl();
            return await this.fetcher.fetchAsync<IManifest>(url);
        } catch (ex) {
            console.error(`Error downloading manifest.json! ${JSON.stringify(ex)}`, ex);
        }

        return Promise.reject();
    }
}
