import { IEditPropertyProps } from './IEditPropertyProps';
import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import { EditValueGrid } from './EditValueGrid';
import { AplixSwitch } from '../../../../components';

export const EditBooleanValue: FunctionComponent<IEditPropertyProps> = ({ save, property }) => {
    enum CustomBoolean {
        true = 'Ja',
        false = 'Nein',
    }

    const [value, setValue] = useState(() => {
        switch (property.value) {
            case CustomBoolean.true:
                return true;
            case CustomBoolean.false:
            default:
                return false;
        }
    });

    const submit = () => {
        save(value ? CustomBoolean.true : CustomBoolean.false);
    };

    return (
        <EditValueGrid save={submit} isValid={true} property={property}>
            <AplixSwitch
                id="value"
                label={property.label}
                value={value}
                onChange={(event, checked) => setValue(checked)}
            />
        </EditValueGrid>
    );
};
