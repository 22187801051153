import * as React from 'react';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ContractDetailStatus, IContractDetailDTO } from '../../../../../../../../core';
import { useDispatch, useSelector } from 'react-redux';
import { isSavingDetail } from '../../../../../../../../redux/contracts/saveDetailState';
import { IState } from '../../../../../../../../redux';
import {
    pauseContractDetailAsync,
    saveDetailAsync,
    unpauseContractDetailAsync,
} from '../../../../../../../../redux/contracts/logic';
import { getService } from '../../../../../../../../ioc_config';
import { IEditDetailStoreActions } from '../../../../../../logic/IEditDetailStoreActions';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../../../ioc_constants';
import { useService } from '../../../../../../../../ioc/hook/useService';
import { IEditDetailStoreBuilder } from '../../../../../../logic';

export interface IProps {
    contractDetail: IContractDetailDTO;
    disabled: boolean;
    onEdit: () => void;
}

export const ContractDetailActions: React.FunctionComponent<IProps> = ({
    contractDetail,
    onEdit,
    disabled,
}) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const storeActions = getService<IEditDetailStoreActions>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREACTIONS
    );

    const detailStoreBuilder = useService<IEditDetailStoreBuilder>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREBUILDER
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event: React.MouseEvent<any>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const isPausable =
        contractDetail.status === ContractDetailStatus.New ||
        contractDetail.status === ContractDetailStatus.PartiallyPrepared;
    const isPaused = contractDetail.status === ContractDetailStatus.Paused;
    const isBusy = useSelector((state: IState) => isSavingDetail(state, contractDetail.primaryKey));

    const handleMenuClick = (action: () => void) => (event: React.MouseEvent<any>) => {
        handleClose(event);
        action();
    };

    const onPause = () => {
        dispatch(pauseContractDetailAsync(contractDetail.primaryKey));
    };

    const onUnpause = () => {
        dispatch(unpauseContractDetailAsync(contractDetail.primaryKey));
    };

    const onNotInStock = () => {
        if (contractDetail) {
            const detailStore = detailStoreBuilder.fromContractDetail(contractDetail);
            dispatch(saveDetailAsync(storeActions.setNotInStock(detailStore)));
        }
    };

    return (
        <>
            <Button
                disabled={disabled}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MenuIcon />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {!isPaused && (
                    <MenuItem disabled={isBusy} onClick={handleMenuClick(onEdit)}>
                        Bearbeiten
                    </MenuItem>
                )}
                {isPausable && (
                    <MenuItem disabled={isBusy} onClick={handleMenuClick(onPause)}>
                        Pausieren
                    </MenuItem>
                )}
                {isPaused && (
                    <MenuItem disabled={isBusy} onClick={handleMenuClick(onUnpause)}>
                        Fortsetzen
                    </MenuItem>
                )}
                <MenuItem disabled={isBusy} onClick={handleMenuClick(onNotInStock)}>
                    Nicht auf Lager
                </MenuItem>
            </Menu>
        </>
    );
};
