import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export type TProps = {
    onCancel: () => void;
    onAcceptCompleted: () => void;
    onAcceptNotCompleted: () => void;
};

// noinspection TsLint
export const ConfirmQtyNotOkDialog = ({
    onCancel,
    onAcceptCompleted,
    onAcceptNotCompleted,
}: TProps) => {
    return (
        <Dialog
            open
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{'Zielmenge nicht erfüllt!'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Die Sollmenge wurde nicht noch nicht erreicht! Sie können den Vorgang abbrechen,
                    die Position als &lsquo;teilweise gerüstet&rsquo; melden und später weiter
                    bearbeiten oder die Position als komplett gerüstet melden.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="default" variant="contained" autoFocus>
                    Zurück
                </Button>
                <Button onClick={onAcceptNotCompleted} color="primary" variant="contained">
                    Teilweise gerüstet
                </Button>
                <Button onClick={onAcceptCompleted} color="default" variant="contained">
                    Position fertig gerüstet
                </Button>
            </DialogActions>
        </Dialog>
    );
};
