import * as React from 'react';
import classNames from 'classnames';
import CheckCircle from '@material-ui/icons/Done';
import InProgressCircle from '@material-ui/icons/ShoppingCart';
import PauseIcon from '@material-ui/icons/Pause';
import {
    ContractDetailStatus, 
    IContractDetailDTO,
    isDetailCompleted,
} from '../../../../../../../../core/entities';
import { QuantityGrade } from '../../../../../../logic';

type TStyles = {
    preparedCellDecoratorRoot: string;
    preparedCellDecoratorChildren: string;
    preparedCellDecoratorCompleted: string;
    preparedCellDecoratorPerfect: string;
    preparedCellDecoratorOk: string;
    preparedCellDecoratorNotAcceptable: string;
};

const styles: TStyles = require('./PreparedCellDecorator.less');

export type TProps = {
    qtyState: QuantityGrade;
    contractDetail: IContractDetailDTO;
    children: any;
};

function getQtyStateClassName(qtyState: QuantityGrade) {
    let childrenClassName = styles.preparedCellDecoratorNotAcceptable;
    switch (qtyState) {
        case QuantityGrade.Perfect:
            childrenClassName = styles.preparedCellDecoratorPerfect;
            break;
        case QuantityGrade.Ok:
            childrenClassName = styles.preparedCellDecoratorOk;
            break;
        default:
            childrenClassName = styles.preparedCellDecoratorNotAcceptable;
            break;
    }
    return childrenClassName;
}

// noinspection TsLint
export const PreparedCellDecorator = ({ qtyState, contractDetail, children }: TProps) => {
    const childrenClassName = getQtyStateClassName(qtyState);
    const isComplete = isDetailCompleted(contractDetail);

    return (
        <div className={styles.preparedCellDecoratorRoot}>
            <div className={classNames(styles.preparedCellDecoratorChildren, childrenClassName)}>
                {children}
            </div>
            <div className={styles.preparedCellDecoratorCompleted}>
                {isComplete && <CheckCircle color="primary" />}
                {contractDetail.status === ContractDetailStatus.PartiallyPrepared && <InProgressCircle />}
                {contractDetail.status === ContractDetailStatus.Paused && <PauseIcon />}
            </div>
        </div>
    );
};
