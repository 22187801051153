import { IContract, IContractDetailDTO } from '../../../core';

import { getCurrentContractState } from '../state';
import { IState } from '../../store';
import { IContractDetail } from '../../../core/entities/IContractDetail';
import { IFullContractDTO } from '../../../core/api/DTOs/IFullContractDTO';
import { IContractProperty } from '../../../core/entities/IContractProperty';
import { TPrimaryKey } from '../../../quino/core/data';
import { ISaveContractDetailDTO } from '../../../core/api/DTOs/ISaveContractDetailDTO';

export function fetchStartableContractsBegin() {
    return {
        type: 'FETCH_STARTABLE_CONTRACTS_BEGIN',
    } as const;
}

export function fetchStartableContractsSuccess(startableContracts?: IContract[]) {
    return {
        type: 'FETCH_STARTABLE_CONTRACTS_SUCCESS',
        startableContracts,
    } as const;
}

export function fetchStartableContractsError(errorMessage?: string) {
    return {
        type: 'FETCH_STARTABLE_CONTRACTS_ERROR',
        errorMessage,
    } as const;
}

export function unloadStartableContracts() {
    return {
        type: 'UNLOAD_STARTABLE_CONTRACTS'
    } as const;
}

export function fetchContinuableContractsBegin() {
    return {
        type: 'FETCH_CONTINUABLE_CONTRACTS_BEGIN',
    } as const;
}

export function fetchContinuableContractsSuccess(continuableContracts?: IContract[]) {
    return {
        type: 'FETCH_CONTINUABLE_CONTRACTS_SUCCESS',
        continuableContracts,
    } as const;
}

export function fetchContinuableContractsError(errorMessage?: string) {
    return {
        type: 'FETCH_CONTINUABLE_CONTRACTS_ERROR',
        errorMessage,
    } as const;
}

export function selectCurrentContract(currentContract: IContract) {
    return {
        type: 'SELECT_CURRENT_CONTRACT',
        currentContract,
    } as const;
}

export function unselectCurrentContract(currentState: IState) {
    const currentContract: IContract | undefined = getCurrentContractState(currentState).contract;
    if (!currentContract) {
        throw new Error(
            'No current contract to unselect! Unselect can only be used if there is a current contract.'
        );
    }

    return {
        type: 'UNSELECT_CURRENT_CONTRACT',
        currentContract,
    } as const;
}

export function finishCurrentContract() {
    return {
        type: 'FINISH_CURRENT_CONTRACT',
    } as const;
}

export function fetchDetailsBegin() {
    return {
        type: 'FETCH_DETAILS_BEGIN',
    } as const;
}

export function fetchDetailsSuccess({ details, properties }: IFullContractDTO) {
    return {
        type: 'FETCH_DETAILS_SUCCESS',
        details,
        properties,
    } as const;
}

export function fetchDetailsError(errorMessage?: string) {
    return {
        type: 'FETCH_DETAILS_ERROR',
        errorMessage,
    } as const;
}

export function pauseBegin() {
    return {
        type: 'PAUSE_BEGIN',
    } as const;
}

export function pauseSuccess() {
    return {
        type: 'PAUSE_SUCCESS',
    } as const;
}

export function pauseError(errorMessage?: string) {
    return {
        type: 'PAUSE_ERROR',
        errorMessage,
    } as const;
}

export function saveBegin() {
    return {
        type: 'SAVE_BEGIN',
    } as const;
}

export function saveSuccess() {
    return {
        type: 'SAVE_SUCCESS',
    } as const;
}

export function saveError(errorMessage?: string) {
    return {
        type: 'SAVE_ERROR',
        errorMessage,
    } as const;
}

export function queueSaveDetailRequest(primaryKey: TPrimaryKey, model: ISaveContractDetailDTO) {
    return {
        type: 'QUEUE_SAVE_DETAIL_REQUEST',
        primaryKey,
        model,
    } as const;
}

export function saveDetailStart(primaryKey: TPrimaryKey) {
    return {
        type: 'SAVE_DETAIL_START',
        primaryKey,
    } as const;
}

export function saveDetailSuccess(primaryKey: TPrimaryKey) {
    return {
        type: 'SAVE_DETAIL_SUCCESS',
        primaryKey,
    } as const;
}

export function saveDetailError(primaryKey: TPrimaryKey, errorMessage: string) {
    return {
        type: 'SAVE_DETAIL_ERROR',
        primaryKey,
        errorMessage,
    } as const;
}

export function setContractProperties(contractProperties: IContractProperty[]) {
    return {
        type: 'SET_CONTRACT_PROPERTIES',
        contractProperties,
    } as const;
}

export function setContractDetails(contractDetails: IContractDetailDTO[]) {
    return {
        type: 'SET_CONTRACT_DETAILS',
        contractDetails,
    } as const;
}

export function acceptError() {
    return {
        type: 'ACCEPT_ERROR',
    } as const;
}

export function continueContractError(errorMessage: string) {
    return {
        type: 'CONTINUE_CONTRACT_ERROR',
        errorMessage,
    } as const;
}

export function clearContinuableError() {
    return {
        type: 'CLEAR_CONTINUABLE_ERROR',
    } as const;
}

export function unloadContinuableContracts() {
    return {
        type: 'UNLOAD_CONTINUABLE_CONTRACTS',
    } as const;
}

export const updateDetail = (contractDetail: IContractDetail) =>
    ({
        type: 'UPDATE_DETAIL',
        contractDetail,
    } as const);

export const pauseDetail = (primaryKey: TPrimaryKey) =>
    ({
        type: 'PAUSE_DETAIL',
        primaryKey,
    } as const);

export const unpauseDetail = (primaryKey: TPrimaryKey) =>
    ({
        type: 'UNPAUSE_DETAIL',
        primaryKey,
    } as const);

export const pauseDetailError = (errorMessage: string) =>
    ({
        type: 'PAUSE_DETAIL_ERROR',
        errorMessage,
    } as const);
