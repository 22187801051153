import { IEditPropertyProps } from './IEditPropertyProps';
import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import { EditValueGrid } from './EditValueGrid';
import { AplixSignaturePad } from "../../../../components/AplixSignaturePad/AplixSignaturePad";

export const EditSignatureValue: FunctionComponent<IEditPropertyProps> = ({
                                                                              save,
                                                                              property,
                                                                              validateRequired,
                                                                          }) => {
    const [value, setValue] = useState<string | null>(property.value);

    const isValid = !validateRequired || !property.isRequired || (!!value);

    const submit = () => {
        save(value);
    };

    return (
        <EditValueGrid save={submit} isValid={isValid} property={property}>
            <AplixSignaturePad
                label={property.label}
                value={value}
                required={validateRequired && property.isRequired}
                onChange={setValue}
            />
        </EditValueGrid>
    );
};
