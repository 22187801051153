import {
    LOGIN_BEGIN,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LogInBegin,
    LogInError,
    LogInSuccess,
    LOGOUT,
    LogOut,
    SET_LOGIN_TOKEN,
    SetLoginToken,
    Unauthorized,
    UNAUTHORIZED,
} from './types';
import { apiManager, TToken } from '../../../../core';

export function loginBegin(): LogInBegin {
    return {
        type: LOGIN_BEGIN,
    };
}

export function loginSuccess(currentUserName: string): LogInSuccess {
    return {
        type: LOGIN_SUCCESS,
        currentUserName,
    };
}

export function loginError(errorMessage: string): LogInError {
    return {
        type: LOGIN_ERROR,
        errorMessage: errorMessage,
    };
}

export function logout(): LogOut {
    apiManager().metadataService.logOut();

    return {
        type: LOGOUT,
    };
}

export const setLoginToken = (loginToken?: TToken): SetLoginToken => ({
    type: SET_LOGIN_TOKEN,
    loginToken: loginToken,
});

export function unauthorized(): Unauthorized {
    // TODO: This is hacky as this code gets executed as part of creating an action - not executing an action
    apiManager().metadataService.logOut();

    return {
        type: UNAUTHORIZED,
    };
}
