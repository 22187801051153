import { IContractDetail } from '../../../../logic';
import { IProductInfoViewHelper } from './IProductInfoViewHelper';
import { inject, injectable } from 'inversify';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../ioc_constants';
import { IQuantityHelper } from '../../../../../../core/logic';
import { IEditDetailStoreQueries } from '../../../../logic/IEditDetailStoreQueries';

@injectable()
export class ProductInfoViewHelper implements IProductInfoViewHelper {
    constructor(
        @inject(APLIX_SERVICE_IDENTIFIER.IQUANTITYHELPER) private quantityHelper: IQuantityHelper,
        @inject(APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREQUERIES)
        private detailStoreQueries: IEditDetailStoreQueries
    ) {}

    getFactorLabel(store: IContractDetail) {
        if (store.bundleSize === 1) {
            return '1';
        }
        return `${store.bundleSize} ${store.unit} / Gebinde`;
    }

    getLastScannedAmountLabel(store: IContractDetail): string {
        const amount = store.lastScannedAmount;
        return amount === null ? '' : `${amount} ${store.unit}`;
    }

    getTargetQuantityLabel(store: IContractDetail) {
        const { targetQuantity, unit, bundleSize } = store;
        const label = `${targetQuantity} ${unit}`;
        if (bundleSize === 1 || !this.detailStoreQueries.isUnitLoad(store)) {
            return label;
        }

        const bundleCount = this.quantityHelper.getBundleCount(targetQuantity, bundleSize);
        const bundleRemainder = this.quantityHelper.getBundleRemainder(targetQuantity, bundleSize);

        if (bundleRemainder > 0) {
            return `${label} = ${bundleCount} Gebinde + ${bundleRemainder} ${unit}`;
        } else {
            return `${label} = ${bundleCount} Gebinde`;
        }
    }

    showLastScannedAmount(store: IContractDetail): boolean {
        return !this.detailStoreQueries.isUnitLoad(store) && store.lastScannedAmount !== null;
    }
}
