import * as React from 'react';
import { StartableContractList } from './StartableContractList/StartableContractList';

interface IProps {
    onSubmit: (contractNumber: string) => void;
    registerOnBackButtonClick: (callback: () => void) => void;
    navigateToHome: () => void;
    visible: boolean;
}

export const ContractSelector: React.FunctionComponent<IProps> = ({
    onSubmit,
    registerOnBackButtonClick,
    navigateToHome,
    visible,
}) => {
    return (
        <StartableContractList
            onSubmit={onSubmit}
            registerOnBackButtonClick={registerOnBackButtonClick}
            navigateToContractInputForm={navigateToHome}
            visible={visible}
        />
    );
};
