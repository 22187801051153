import { ConsoleLogger } from './ConsoleLogger';
import { IApplicationInsightsLogger } from './IApplicationInsightsLogger';
import { MultiplexLogger } from './MultiplexLogger';
import { inject, injectable } from 'inversify';
import APLIX_SERVICE_IDENTIFIER from '../../ioc_constants';

@injectable()
export class AplixLogger extends MultiplexLogger {
    private consoleLogger: ConsoleLogger = new ConsoleLogger();

    constructor(
        @inject(APLIX_SERVICE_IDENTIFIER.IAPPLICATIONINSIGHTSLOGGER)
        appInsightsLogger: IApplicationInsightsLogger
    ) {
        super();
        this.loggers = [this.consoleLogger, appInsightsLogger];
    }
}
