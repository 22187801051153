/**
 * The result of the quantity validator
 */
export enum QtyValidationState {
    /**
     * The quantity has not yet been validated
     */
    None,

    /**
     * The quantity is being validated
     */
    Pending,

    /**
     * The quantity has been validated.
     * The quantity does not match the target quantity
     */
    NotCompleted,

    /**
     * The quantity has been validated.
     * Either, the quantity matches the target quantity or the user manually flagged the item as complete
     */
    Completed,
}

/**
 * Describes how well the actual quantity matches the target quantity
 */
export enum QuantityGrade {
    /**
     * The actual quantity is way off the target quantity
     */
    NotAcceptable,

    /**
     * The acutal quantity is near the target quantity.
     * "near" currently means within 10%
     * @see QuantityHelper.QUANTITY_OK_WINDOW
     */
    Ok,

    /**
     * The actual quantity matches the target quantity at a precision of allowedDeviation
     * @see IContractDetail.allowedDeviation
     */
    Perfect,
}
