import * as React from 'react';
import { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../../redux';
import Button from '@material-ui/core/Button';
import ContractInfoIcon from '@material-ui/icons/PersonPin';
import PauseIcon from '@material-ui/icons/Pause';
import {
    ContractDetailStatus,
    IContractDetailDTO,
    isDetailCompleted,
} from '../../../../core/entities';
import { AplixPage, AplixSwitch, BarcodeListener } from '../../../../components';
import { RootPage, switchRootPage } from '../../../../redux/globals';
import { getCurrentContractState, pauseAsync } from '../../../../redux/contracts';
import { ContractDetailTable, ContractInfoDialog, EditConfirmationDialog } from './components';
import { getService } from '../../../../ioc_config';
import { getFinishContractService } from '../../../FinishContractPage/redux/logic';
import APLIX_SERVICE_IDENTIFIER from '../../../../ioc_constants';
import { PrepareContractContext } from '../../PrepareContractContext';
import { getFirstAvailableBarcode, hasEmptyRequiredProperties } from './utils';
import { IEditDetailStoreBuilder } from '../../logic';
import { ScanType } from '../../../../core/entities/ScanType';
import { IEditDetailStoreActions } from '../../logic/IEditDetailStoreActions';
import { ListError, TListError } from './components/ListError';
import { IBarcodeParser } from '../../../../core/logic/BarcodeParser';
import { TEditConfirmationModel } from './components/EditConfirmationDialog/EditConfirmationDialog';
import { ScannableDetails } from '../../../../core/entities/ScannableDetails';
import { isDoneSavingForAll } from '../../../../redux/contracts/saveDetailState';
import { CameraAlt } from '@material-ui/icons';
import { IScannerService } from '../../../../core/scanner/IScannerService';

type TStyles = {
    editRoot: string;
    editInfoButton: string;
    editPauseButton: string;
    editErrorData: string;
    editFinishButton: string;
    showAllContractDetailsSwitch: string;
};

const styles: TStyles = require('./List.less');

export function detailCompareFunction(a: IContractDetailDTO, b: IContractDetailDTO) {
    function compare(mapFunction: (detail: IContractDetailDTO) => string | number) {
        const mappedA = mapFunction(a);
        const mappedB = mapFunction(b);

        if (mappedA < mappedB) {
            return -1;
        } else if (mappedA > mappedB) {
            return 1;
        } else {
            return 0;
        }
    }

    const statusPriority = (detail: IContractDetailDTO) => {
        if (
            detail.scantyp === ScanType.Product &&
            detail.status === ContractDetailStatus.PartiallyPrepared
        ) {
            return ContractDetailStatus.New;
        }

        return detail.status ? detail.status : ContractDetailStatus.New;
    };

    const lagerplatz = (detail: IContractDetailDTO) => detail.lagerplatz || '';

    return compare(statusPriority) || compare(lagerplatz);
}

export function getSortedDetails(details: IContractDetailDTO[], showAllContractDetailsEnabled: boolean) {
    return details.sort(detailCompareFunction).filter((d) => showAllContractDetailsEnabled || !d.istFakultativ);
}

function mapStateToProps(state: IState) {
    const currentContractState = getCurrentContractState(state);

    return {
        isBusy:
            currentContractState.isFetching ||
            currentContractState.isPausing ||
            !isDoneSavingForAll(state),
        errorMessageCurrentContract: currentContractState.errorMessage,
        contract: currentContractState.contract,
        details: currentContractState.details || [],
    };
}

export const List: React.FunctionComponent = () => {
    const barcodeParser = getService<IBarcodeParser>(APLIX_SERVICE_IDENTIFIER.IBARCODEPARSER);

    const detailStoreBuilder = getService<IEditDetailStoreBuilder>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREBUILDER
    );

    const detailStoreActions = getService<IEditDetailStoreActions>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREACTIONS
    );

    const scannerService = getService<IScannerService>(APLIX_SERVICE_IDENTIFIER.ISCANNERSERVICE);

    const stateProps = useSelector(mapStateToProps);
    const dispatch = useDispatch();

    const [showContractInfos, setShowContractInfos] = useState(false);
    const [confirmViewModel, setConfirmViewModel] = useState<TEditConfirmationModel>();
    const [error, setError] = useState<TListError>();

    const { goToDetailPage, saveDetailStore, goToEditPropertiesPage, goToNextStep, setDetailScrollIndex, showAllContractDetailsEnabled, setShowAllContractDetailsEnabled } = useContext(
        PrepareContractContext
    );

    const sortedDetails = useMemo(() => getSortedDetails(stateProps.details, showAllContractDetailsEnabled), [stateProps.details, showAllContractDetailsEnabled]);

    const { contract, isBusy } = stateProps;

    const handleConfirmError = (): void => setError(undefined);

    const navigateToHome = () => {
        if (isBusy) {
            return;
        }

        dispatch(switchRootPage(RootPage.StartPage));
    };

    const getCheatCodeBarcode = () => getFirstAvailableBarcode(stateProps.details);

    const isScannable = (scannedDetail: IContractDetailDTO) => {
        if (!stateProps.contract) {
            throw new Error('Internal error: stateProps.contract must be set in list');
        }
        if (!sortedDetails[0]) {
            throw new Error('Internal error: List must have details');
        }

        switch (stateProps.contract.scannableDetails) {
            case ScannableDetails.First:
                return scannedDetail.primaryKey === sortedDetails[0].primaryKey;
            case ScannableDetails.Unprepared:
            case ScannableDetails.All:
            default:
                return true;
        }
    };

    const handleBarcode = (barcode: string) => {
        if (error || confirmViewModel) {
            return;
        }

        const barcodeParserResult = barcodeParser.parse(stateProps.details, barcode);

        if (!barcodeParserResult) {
            setError({
                type: 'InvalidBarcode',
                barcode,
            });
            return;
        }

        const detailStore = detailStoreBuilder.fromBarcodeParserResult(barcodeParserResult);

        if (isDetailCompleted(barcodeParserResult.contractDetail)) {
            setConfirmViewModel({ type: 'AlreadyPrepared', detail: detailStore });
            return;
        }

        if (barcodeParserResult.contractDetail.status === ContractDetailStatus.Paused) {
            setConfirmViewModel({ type: 'Paused', detail: detailStore });
            return;
        }

        if (!isScannable(barcodeParserResult.contractDetail)) {
            setConfirmViewModel({ type: 'NotInOrder', detail: detailStore });
            return;
        }

        const index = sortedDetails.findIndex((x) => x.primaryKey === detailStore.primaryKey);
        setDetailScrollIndex(index > 0 ? index : 0)
        
        if (detailStore.scanType === ScanType.Product) {
            const requiresProperties = hasEmptyRequiredProperties(detailStore);
            if (requiresProperties) {
                goToEditPropertiesPage(detailStore);
            } else if (detailStore.properties.length > 0 && detailStore.currentQuantity === detailStore.targetQuantity) {
                goToEditPropertiesPage(detailStore);
            } else {
                saveDetailStore(detailStoreActions.forceAutoAccept(detailStore));
            }
        } else {
            goToDetailPage(detailStore);
        }
    };

    const handleConfirmEditDetail = () => {
        const detailStore = confirmViewModel && confirmViewModel.detail;
        if (detailStore) {
            setConfirmViewModel(undefined);
            goToDetailPage(detailStore);
        }
    };

    const contractNumber: string = (contract && contract.nummer) || '';

    return (
        <AplixPage
            showHomeButton
            pageTitle={`Auftrag "${contractNumber}"`}
            onHomeClick={navigateToHome}
            showLoadingIndicator={isBusy}
            advancedHeaderElement={
                <React.Fragment>
                    <Button
                        color="primary"
                        variant="contained"
                        className={styles.editInfoButton}
                        disabled={isBusy}
                        onClick={() => setShowContractInfos(true)}
                    >
                        <ContractInfoIcon />
                    </Button>
                    {scannerService.enabled() && (
                        <Button
                            color="primary"
                            variant="contained"
                            className={styles.editPauseButton}
                            disabled={isBusy}
                            onClick={() => scannerService.show((barcode) => handleBarcode(barcode))}
                        >
                            <CameraAlt />
                        </Button>
                    )}
                    <Button
                        color="primary"
                        variant="contained"
                        className={styles.editPauseButton}
                        disabled={isBusy}
                        onClick={() => dispatch(pauseAsync())}
                    >
                        <PauseIcon />
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={getFinishContractService().checkForOpenDetails() || isBusy}
                        className={styles.editFinishButton}
                        onClick={goToNextStep}
                        id={'finish-contract-button'}
                    >
                        Abschliessen
                    </Button>
                    <AplixSwitch
                        id={'show-all-contract-details'}
                        className={styles.showAllContractDetailsSwitch}
                        value={showAllContractDetailsEnabled}
                        label={'alle'}
                        onChange={(_, checked) => setShowAllContractDetailsEnabled(checked)}
                    />
                </React.Fragment>
            }
        >
            <BarcodeListener
                cheatCodeBarcode={getCheatCodeBarcode()}
                onScan={(barcode) => handleBarcode(barcode)}
                scannerService={scannerService}
            />
            <div className={styles.editRoot}>
                <ContractDetailTable details={sortedDetails} disabled={isBusy} />

                {error && <ListError onClose={handleConfirmError} error={error} />}

                {showContractInfos && (
                    <ContractInfoDialog
                        show
                        kunde={contract ? contract.kunde : ''}
                        information={contract ? contract.information : undefined}
                        onClose={() => setShowContractInfos(false)}
                    />
                )}

                {confirmViewModel && (
                    <EditConfirmationDialog
                        show
                        onConfirm={() => handleConfirmEditDetail()}
                        onAbort={() => {
                            setConfirmViewModel(undefined);
                        }}
                        viewModel={confirmViewModel}
                    />
                )}
            </div>
        </AplixPage>
    );
};
