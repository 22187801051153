import * as React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { uniq } from 'lodash';
import Box from '@material-ui/core/Box';
import { IContract } from '../../../../core';

type TStyles = {
    regionButton: string;
    scrollY: string;
};

const styles: TStyles = require('./RegionFilter.less');

interface IProps {
    contracts: IContract[];
    selectRegion: (region: string) => void;
}

export const RegionFilter: React.FunctionComponent<IProps> = ({ contracts, selectRegion }) => {
    const regions: string[] = uniq(
        contracts
            .filter((contract) => !!contract.region)
            .map((contract) => contract.region as string)
    );
    regions.sort();

    return (
        <Box display={'flex'} flexDirection={'column'} height={1}>
            <Typography variant={'h5'}>Region wählen</Typography>

            <Box py={2} flexGrow={1} className={styles.scrollY}>
                {regions.map((region) => {
                    return (
                        <Button
                            key={region}
                            className={styles.regionButton}
                            size={'large'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => selectRegion(region)}
                        >
                            {region}
                        </Button>
                    );
                })}
            </Box>
        </Box>
    );
};
