/* eslint-disable react/display-name */
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import Table from '@material-ui/core/Table';
import {IContractDetailDTO} from '../../../../../../core/entities';
import {TableComponents, TableVirtuoso, TableVirtuosoHandle} from 'react-virtuoso';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {ContractDetailActions} from './components/ContractDetailActions/ContractDetailActions';
import {IEditDetailStoreBuilder, QuantityGrade} from '../../../../logic';
import {getService} from '../../../../../../ioc_config';
import {IQuantityHelper} from '../../../../../../core';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../ioc_constants';
import {PrepareContractContext} from '../../../../PrepareContractContext';
import {PreparedCellDecorator} from './components/PrepareCellDecorator/PreparedCellDecorator';
import {ContractDetailInformation} from './components/ContractDetailInformation/ContractDetailInformation';
import {TableHead} from '@material-ui/core';

type TStyles = {
    contractDetailRoot: string;
    tableRoot: string;
    tableHeadRoot: string;
    tableBodyLagerort: string;
    tableBodyProdukt: string;
    tableBodyMenge: string;
    tableBodyPrepared: string;
    tableBodyActions: string;
};

const styles: TStyles = require('./ContractDetailTable.less');

export interface IProps {
    details?: IContractDetailDTO[];
    index?: number;
    disabled: boolean;
}

const getQtyState = (detail: IContractDetailDTO): QuantityGrade => {
    const quantityHelper = getService<IQuantityHelper>(
        APLIX_SERVICE_IDENTIFIER.IQUANTITYHELPER
    );
    return quantityHelper.getQuantityGrade(
        detail.mengeGeruestet ? detail.mengeGeruestet : 0,
        detail.menge ? detail.menge : 0,
        detail.erlaubteAbweichung ? detail.erlaubteAbweichung : 0
    );
};

export const ContractDetailTable = ({details, disabled}: IProps) => {
    const context = useContext(PrepareContractContext);
    const detailStoreBuilder = getService<IEditDetailStoreBuilder>(
        APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREBUILDER
    );

    const virtuosoRef = useRef<TableVirtuosoHandle>(null);

    useEffect(() => {
        window.setTimeout(() => {
            virtuosoRef.current && virtuosoRef.current.scrollToIndex(context.detailScrollIndex)
        }, 250)
    }, [virtuosoRef.current, context.detailScrollIndex])

    const openEdit = useCallback((contractDetail: IContractDetailDTO, index: number) => {
        if (!disabled) {
            const detailStore = detailStoreBuilder.fromContractDetail(contractDetail);
            context.setDetailScrollIndex(index);
            context.goToDetailPage(detailStore);
        }
    }, [disabled, detailStoreBuilder, context.goToDetailPage]);

    const tableComponents: TableComponents<IContractDetailDTO> = useMemo(() => {
        return {
            Scroller: React.forwardRef((props, ref) => (
                <div className={styles.contractDetailRoot} {...props} ref={ref}/>
            )),
            Table: React.forwardRef((props, ref) => (
                <Table {...props} innerRef={ref} className={styles.tableRoot}/>)),
            TableHead: React.forwardRef((props, ref) => (
                <TableHead {...props} innerRef={ref} className={styles.tableHeadRoot}/>)),
            TableBody: React.forwardRef((props, ref) => (
                <TableBody {...props} ref={ref}/>)),
            TableRow: React.forwardRef((props, ref) => (
                <TableRow 
                    {...props}
                    innerRef={ref}
                    onClick={() => openEdit(details![props['data-index']], props['data-index'])}
                />))
        }
    }, [styles, openEdit]);

    const stickyHeader = useMemo(() => {
        return (
            <TableRow>
                <TableCell variant="head">Lagerort</TableCell>
                <TableCell variant="head">Produkt</TableCell>
                <TableCell variant="head">Soll</TableCell>
                <TableCell variant="head">Gerüstet</TableCell>
                <TableCell variant="head"/>
            </TableRow>
        );
    }, []);

    const rowContent = useCallback((_index: number, data: IContractDetailDTO) => {
            return (
                <>
                    <TableCell size="small" className={styles.tableBodyLagerort}>
                        {data.lagerplatz}
                    </TableCell>
                    <TableCell size="small" className={styles.tableBodyProdukt}>
                        <span>#{data.nummer}</span> {data.bezeichnung}
                        {data.information && (
                            <ContractDetailInformation information={data.information}/>
                        )}
                    </TableCell>
                    <TableCell size="small" className={styles.tableBodyMenge}>
                        {data.menge} {data.einheit}
                    </TableCell>
                    <TableCell size="small" className={styles.tableBodyPrepared}>
                        <PreparedCellDecorator
                            qtyState={getQtyState(data)}
                            contractDetail={data}
                        >
                            {data.mengeGeruestet} {data.einheit}
                        </PreparedCellDecorator>
                    </TableCell>
                    <TableCell size="small" padding="none" className={styles.tableBodyActions}>
                        <ContractDetailActions
                            contractDetail={data}
                            onEdit={() => openEdit(data, _index)}
                            disabled={disabled}
                        />
                    </TableCell>
                </>
            );
        },
        [
            context,
            openEdit,
            getQtyState,
            disabled,
            styles
        ]
    );

    return (
        details != null ? (
            <TableVirtuoso
                ref={virtuosoRef}
                data={details}
                overscan={{main: 15, reverse: 15}}
                increaseViewportBy={{top: 200, bottom: 200}}
                scrollSeekConfiguration={false}
                components={tableComponents}
                itemContent={rowContent}
                fixedHeaderContent={() => stickyHeader}>
            </TableVirtuoso>
        ) : (<div>Keine Auftragsdetails vorhanden!</div>))
};
