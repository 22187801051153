import { Container, ContainerModule, interfaces } from 'inversify';
import {
    getQuinoContainer,
    quinoCoreModule,
    quinoServerModule,
    setQuinoContainer,
} from './quino/core/ioc';
import { DEFAULT_SERVER_BASEURL } from './quino/core/api/QuinoServer';
import { ISettings } from './ISettings';
import {
    AplixApiManager,
    AplixLogger,
    IAplixApiManager,
    IAplixService,
    IAplixUrlManager,
    ILogger,
    IQuantityHelper,
    QuantityHelper,
} from './core';
import { AplixServerService } from './core/api/AplixServerService';
import { AplixUrlManager } from './core/api/AplixUrlManager';
import APLIX_SERVICE_IDENTIFIER from './ioc_constants';
import { getStore, IState } from './redux';
import { FinishContractReducer } from './pages/FinishContractPage/redux/reducers/FinishContractReducer';
import { IFinishContractReducer } from './pages/FinishContractPage/redux/reducers/IFinishContractReducer';
import { Store } from 'redux';
import {
    FinishContractService,
    IFinishContractService,
} from './pages/FinishContractPage/redux/logic';
import {
    BarcodeParser,
    CurrentContractService,
    IBarcodeParser,
    ICurrentContractService,
} from './core/logic';
import { ContinueContractService } from './pages/ContinueContractPage/redux/logic/ContinueContractService';
import { IContinueContractService } from './pages/ContinueContractPage/redux/logic/IContinueContractService';
import {
    EditDetailBarcodeListener,
    EditDetailStoreBuilder,
    IEditDetailBarcodeListener,
    IEditDetailStoreBuilder,
} from './pages/PrepareContractPage/logic';
import { ExactStrategy } from './core/logic/BarcodeParser/ExactStrategy';
import { WeightStrategy } from './core/logic/BarcodeParser/WeightStrategy';
import { PriceStrategy } from './core/logic/BarcodeParser/PriceStrategy';
import {
    BarcodeStrategyRunner,
    IBarcodeStrategyRunner,
} from './core/logic/BarcodeParser/BarcodeStrategyRunner';
import { IBarcodeParserStrategy } from './core/logic/BarcodeParser/IBarcodeParserStrategy';
import { IManifestDownloader, IUpdater, ManifestDownloader, Updater } from './core/updater';
import { IProductInfoViewHelper } from './pages/PrepareContractPage/scenes/EditDetail/components/ProductInfo/IProductInfoViewHelper';
import { ProductInfoViewHelper } from './pages/PrepareContractPage/scenes/EditDetail/components/ProductInfo/ProductInfoViewHelper';
import { QuantityGradeViewHelper } from './pages/PrepareContractPage/scenes/EditDetail/components/EditQuantity/components/Value/QuantityGradeViewHelper';
import { IQuantityGradeViewHelper } from './pages/PrepareContractPage/scenes/EditDetail/components/EditQuantity/components/Value/IQuantityGradeViewHelper';
import { IApplicationInsightsLogger } from './core/logging/IApplicationInsightsLogger';
import { ApplicationInsightsLogger } from './core/logging/ApplicationInsightsLogger';
import { IEditDetailStoreQueries } from './pages/PrepareContractPage/logic/IEditDetailStoreQueries';
import { EditDetailStoreQueries } from './pages/PrepareContractPage/logic/EditDetailStoreQueries';
import { IEditDetailStoreActions } from './pages/PrepareContractPage/logic/IEditDetailStoreActions';
import { EditDetailStoreActions } from './pages/PrepareContractPage/logic/EditDetailStoreActions';
import { ScannerService } from './core/scanner/ScannerService';
import { IScannerService } from './core/scanner/IScannerService';
import ServiceIdentifier = interfaces.ServiceIdentifier;

let settings: ISettings = {
    backendServerUrl: DEFAULT_SERVER_BASEURL,
};

if (typeof window !== 'undefined') {
    settings = {
        ...settings,
        ...((window as any).aplixConfig as ISettings),
    };
}

/**
 * Configure the IoC container for the application including Quino stuff.
 */
export const configureIocContainer = () => {
    const container = new Container();
    const backendUrl = settings.backendServerUrl;

    container.load(quinoCoreModule(getStore));
    container.load(quinoServerModule(backendUrl));
    container.load(aplixCore());
    container.load(aplixServerModule());

    setQuinoContainer(container);
};

export const aplixCore = () =>
    new ContainerModule((bind: interfaces.Bind) => {
        bind<ILogger>(APLIX_SERVICE_IDENTIFIER.ILOGGER)
            .to(AplixLogger)
            .inSingletonScope();
        bind<Store<IState>>(APLIX_SERVICE_IDENTIFIER.STORE).toDynamicValue(getStore);
        bind<IBarcodeStrategyRunner>(APLIX_SERVICE_IDENTIFIER.IBARCODESTRATEGYRUNNER)
            .to(BarcodeStrategyRunner)
            .inSingletonScope();
        bind<ICurrentContractService>(APLIX_SERVICE_IDENTIFIER.ICURRENTCONTRACTSERVICE)
            .to(CurrentContractService)
            .inSingletonScope();
        bind<IContinueContractService>(APLIX_SERVICE_IDENTIFIER.ICONTINUECONTRACTSERVICE)
            .to(ContinueContractService)
            .inSingletonScope();
        bind<IEditDetailStoreBuilder>(APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREBUILDER)
            .to(EditDetailStoreBuilder)
            .inSingletonScope();
        bind<IBarcodeParserStrategy>(APLIX_SERVICE_IDENTIFIER.IEXACTSTRATEGY)
            .to(ExactStrategy)
            .inSingletonScope();
        bind<IFinishContractReducer>(APLIX_SERVICE_IDENTIFIER.IFINISHCONTRACTREDUCER)
            .to(FinishContractReducer)
            .inSingletonScope();
        bind<IFinishContractService>(APLIX_SERVICE_IDENTIFIER.IFINISHCONTRACTSERVICE)
            .to(FinishContractService)
            .inSingletonScope();
        bind<IBarcodeParser>(APLIX_SERVICE_IDENTIFIER.IBARCODEPARSER)
            .to(BarcodeParser)
            .inSingletonScope();
        bind<IBarcodeParserStrategy>(APLIX_SERVICE_IDENTIFIER.IPRICESTRATEGY)
            .to(PriceStrategy)
            .inSingletonScope();
        bind<IQuantityHelper>(APLIX_SERVICE_IDENTIFIER.IQUANTITYHELPER)
            .to(QuantityHelper)
            .inSingletonScope();
        bind<IBarcodeParserStrategy>(APLIX_SERVICE_IDENTIFIER.IWEIGHTSTRATEGY)
            .to(WeightStrategy)
            .inSingletonScope();
        bind<IManifestDownloader>(APLIX_SERVICE_IDENTIFIER.IMANIFESTDOWNLOADER)
            .to(ManifestDownloader)
            .inSingletonScope();
        bind<IProductInfoViewHelper>(APLIX_SERVICE_IDENTIFIER.IPRODUCTINFOVIEWHELPER)
            .to(ProductInfoViewHelper)
            .inSingletonScope();
        bind<IUpdater>(APLIX_SERVICE_IDENTIFIER.IUPDATER)
            .to(Updater)
            .inSingletonScope();
        bind<IQuantityGradeViewHelper>(APLIX_SERVICE_IDENTIFIER.IQUANTITYGRADEVIEWHELPER)
            .to(QuantityGradeViewHelper)
            .inSingletonScope();
        bind<IApplicationInsightsLogger>(APLIX_SERVICE_IDENTIFIER.IAPPLICATIONINSIGHTSLOGGER)
            .to(ApplicationInsightsLogger)
            .inSingletonScope();
        bind<IEditDetailBarcodeListener>(APLIX_SERVICE_IDENTIFIER.IEDITDETAILBARCODELISTENER)
            .to(EditDetailBarcodeListener)
            .inSingletonScope();
        bind<IScannerService>(APLIX_SERVICE_IDENTIFIER.ISCANNERSERVICE)
            .to(ScannerService)
            .inSingletonScope();
        bind<IEditDetailStoreQueries>(APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREQUERIES)
            .to(EditDetailStoreQueries)
            .inRequestScope();
        bind<IEditDetailStoreActions>(APLIX_SERVICE_IDENTIFIER.IEDITDETAILSTOREACTIONS)
            .to(EditDetailStoreActions)
            .inRequestScope();
    });

export const aplixServerModule = () =>
    new ContainerModule((bind: interfaces.Bind) => {
        bind<IAplixApiManager>(APLIX_SERVICE_IDENTIFIER.IAPLIXAPIMANAGER)
            .to(AplixApiManager)
            .inSingletonScope();
        bind<IAplixUrlManager>(APLIX_SERVICE_IDENTIFIER.IAPLIXURLMANAGER)
            .to(AplixUrlManager)
            .inSingletonScope();
        bind<IAplixService>(APLIX_SERVICE_IDENTIFIER.IAPLIXSERVICE)
            .to(AplixServerService)
            .inSingletonScope();
    });

/**
 * Retrieve a service by service locator.
 * Use this function as fallback only. Try to use constructor injection wherever possible.
 */
export function getService<T>(serviceIdentifier: ServiceIdentifier<T>): T {
    return getQuinoContainer().get<T>(serviceIdentifier);
}
