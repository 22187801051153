import * as React from 'react';
import { useContext } from 'react';
import classNames from 'classnames';
import APLIX_SERVICE_IDENTIFIER from '../../../../../../../../ioc_constants';
import { ValueInput } from './ValueInput';
import * as _ from 'lodash';
import { QuantityInformation } from './QuantityInformation';
import { IEditQuantityContext } from '../../IEditQuantityContext';
import { EditQuantityMode } from '../../EditQuantity';
import { IQuantityGradeViewHelper } from './IQuantityGradeViewHelper';
import { textTotalGeruestet, ValueLabel } from './ValueLabel';
import { useService } from '../../../../../../../../ioc/hook/useService';
import { EditDetailContext } from '../../../../EditDetailContext';

type TStyles = {
    editQtyValue: string;
    editQtyCurrentQty: string;
    editQtyCurrentQtyUnit: string;
};

const styles: TStyles = {
    ...require('./Value.less'),
};

interface IProps {
    context: IEditQuantityContext;
}

export const Value: React.FunctionComponent<IProps> = (props) => {
    const [detailStore] = useContext(EditDetailContext);

    const viewHelper = useService<IQuantityGradeViewHelper>(
        APLIX_SERVICE_IDENTIFIER.IQUANTITYGRADEVIEWHELPER
    );

    const getStateClassName = () => {
        return viewHelper.getGradeClassName(detailStore);
    };

    const getCurrentQuantityFormatted = () => {
        const { decimalPlaces, currentQuantity } = detailStore;
        return _.round(currentQuantity, decimalPlaces).toString();
    };

    const renderCurrentQuantity = () => {
        switch (props.context.getMode()) {
            case EditQuantityMode.Readonly:
                return (
                    <div className={classNames(styles.editQtyCurrentQty, getStateClassName())}>
                        {getCurrentQuantityFormatted()}
                    </div>
                );
            case EditQuantityMode.Edit:
            default:
                return <ValueInput context={props.context} />;
        }
    };

    return (
        <div>
            <ValueLabel text={textTotalGeruestet} />
            <div className={styles.editQtyValue}>{renderCurrentQuantity()}</div>
            <QuantityInformation detailStore={detailStore} />
        </div>
    );
};
