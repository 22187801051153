import * as React from 'react';
import { ChangeEvent, useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import { AplixInput, AplixPage } from '../../../../../../components';
import { EditDetailContext } from '../../EditDetailContext';
import { Grid } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import { ILot } from '../../../../../../core/entities/ILot';
import Box from '@material-ui/core/Box';
import { AplixDatePicker } from '../../../../../../components/AplixPickers/AplixDatePicker';

interface IProps {
    lot: Partial<ILot>;
    goBack: () => void;
    save: (lot: ILot) => void;
}

export const EditLotDetails: React.FunctionComponent<IProps> = ({ lot, goBack, save }) => {
    const [{ articleNumber }] = useContext(EditDetailContext);

    const [expiryDate, setExpiryDate] = useState(() => {
        const expiryDate = moment(lot.expiryDate);
        return expiryDate.isValid() ? expiryDate : moment();
    });

    const [lotNumber, setLotNumber] = useState(lot.number ? lot.number.toString() : '');

    const setLotNumberValue = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setLotNumber(event.target.value);
    };

    const data: Pick<ILot, 'expiryDate' | 'number'> = {
        number: String(lotNumber),
        expiryDate: expiryDate.toDate(),
    };

    const isValid = data.expiryDate && data.number != null && data.number != '';

    const saveIfValid = () => {
        if (isValid) {
            save({ ...lot, ...data } as ILot);
        }
    };

    return (
        <AplixPage
            showBackButton
            pageTitle={`Artikel "${articleNumber}": Charge Bearbeiten`}
            onBackClick={goBack}
        >
            <Box width={1}>
                <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={5}>
                        <AplixInput
                            id="lotNumber"
                            label="Chargennummer"
                            type={'text'}
                            value={lotNumber}
                            fullWidth
                            autoFocus={!lot.number}
                            required
                            showKeyboardAdornment
                            showBarCodeAdornment
                            onChange={setLotNumberValue}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <AplixDatePicker
                            value={expiryDate}
                            onChange={(value) => setExpiryDate(value!)}
                            label="Ablaufdatum"
                            autoFocus={!!lot.number}
                            required
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            disabled={!isValid}
                            onClick={saveIfValid}
                        >
                            <Icon>done</Icon>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </AplixPage>
    );
};
