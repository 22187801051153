import { IUrlManager, IQuinoServerServiceConfiguration } from '.';
import { TPrimaryKey } from '../..';
import { injectable, inject } from 'inversify';
import SERVICE_IDENTIFIER from '../../ioc/constants/identifiers';
import { toPairs } from 'lodash';

export const DEFAULT_SERVER_BASEURL = 'http://localhost:9000/api/v1';

@injectable()
export class QuinoUrlManager implements IUrlManager {
    protected configuration: IQuinoServerServiceConfiguration;
    protected authenticationControllerName = 'auth';
    protected metadataControllerName = 'metadata';
    protected genericControllerName = 'generic';

    constructor(
        @inject(SERVICE_IDENTIFIER.IQUINOSERVERSERVICECONFIGURATION)
        configuration: IQuinoServerServiceConfiguration
    ) {
        if (!configuration) {
            throw new Error('Parameter "configuration" is required!');
        }
        if (!configuration.baseUrl) {
            throw new Error('Parameter "configuration.baseUrl" is required!');
        }

        this.configuration = configuration;
    }

    getLoginUrl() {
        return this.combineUrlParts(this.authenticationControllerName);
    }

    getAllEntitiesUrl() {
        return this.combineUrlParts(this.metadataControllerName, 'class');
    }

    getViewsForEntityUrl(className: string) {
        return this.combineUrlParts(this.metadataControllerName, 'class', className, 'view');
    }

    getViewUrl(className: string, viewName: string) {
        return this.combineUrlParts(
            this.metadataControllerName,
            'class',
            className,
            'view',
            viewName
        );
    }

    getListUrl(className: string) {
        return this.combineUrlParts(this.genericControllerName, 'class', className);
    }

    getObjectUrl(className: string, primaryKey: TPrimaryKey) {
        return this.combineUrlParts(
            this.genericControllerName,
            'class',
            className,
            'primaryKey',
            primaryKey
        );
    }

    saveObjectUrl(className: string, primaryKey: TPrimaryKey) {
        return this.combineUrlParts(
            this.genericControllerName,
            'class',
            className,
            'primaryKey',
            primaryKey
        );
    }

    getLookupValuesUrl(className: string, primaryKey: TPrimaryKey, relationName: string) {
        return this.combineUrlParts(
            this.genericControllerName,
            'class',
            className,
            'primaryKey',
            primaryKey,
            'lookupObjectsFor',
            relationName
        );
    }

    getRelatedObjectsForUrl(className: string, primaryKey: TPrimaryKey, relationName: string) {
        return this.combineUrlParts(
            this.genericControllerName,
            'class',
            className,
            'primaryKey',
            primaryKey,
            'relatedObjectsFor',
            relationName
        );
    }

    getRelatedObjectForUrl(className: string, primaryKey: TPrimaryKey, relationName: string) {
        return this.combineUrlParts(
            this.genericControllerName,
            'class',
            className,
            'primaryKey',
            primaryKey,
            'relatedObjectFor',
            relationName
        );
    }

    protected combineUrlParts(...parts: string[]) {
        return [this.configuration.baseUrl, ...parts].join('/');
    }

    protected static combineQueryParams(params: object): string {
        const result = toPairs(params)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');

        return result.length > 0 ? '?' + result : '';
    }
}
