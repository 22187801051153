import { IDataService, IMetadataService, IApiManager, IViewAndData } from './';
import { getQuinoContainer } from '..';
import { injectable, inject } from 'inversify';
import SERVICE_IDENTIFIER from '../ioc/constants/identifiers';

/**
 * This is the entry-point for all Quino-Server communitcation stuff. Its a high-level API which
 * provides high-level API's like getViewAndDataAsync() as well as provides access to lower level
 * API's like the IDataService and IMetadataService.
 * @see IApiManager
 */
@injectable()
export class ApiManager implements IApiManager {
    dataService: IDataService;
    metadataService: IMetadataService;

    constructor(
        @inject(SERVICE_IDENTIFIER.IMETADATASERVICE) metadataService: IMetadataService,
        @inject(SERVICE_IDENTIFIER.IDATASERVICE) dataService: IDataService
    ) {
        this.metadataService = metadataService;
        this.dataService = dataService;
    }

    async getViewAndDataAsync(
        entityName: string,
        listViewName: string,
        detailViewName?: string
    ): Promise<IViewAndData> {
        const fetchView = this.metadataService.getView(entityName, listViewName, detailViewName);
        const fetchData = this.dataService.getListAsync(entityName, listViewName);

        const [view, data] = await Promise.all([fetchView, fetchData]);

        return {
            view,
            data,
        };
    }
}

export function apiManager(): IApiManager {
    return getQuinoContainer().get<IApiManager>(SERVICE_IDENTIFIER.IAPIMANAGER);
}
