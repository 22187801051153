import * as React from 'react';
import { useEffect, useState } from 'react';
import { ChoiceFilter } from './ChoiceFilter';
import { List } from './List';
import { useSelector } from 'react-redux';
import { getStartableContracts } from '../../../../redux/contracts';
import { apiManager } from '../../../../quino';
import { IChoice } from '../../../../core/entities/IChoice';
import { SalesType } from '../../../../core/entities/SalesType';
import { RegionFilter } from './RegionFilter';
import { SalesTypeFilter } from './SalesTypeFilter';
import { uniq } from 'lodash';

interface IProps {
    onSubmit: (contractNumber: string) => void;
    registerOnBackButtonClick: (callback: () => void) => void;
    navigateToContractInputForm: () => void;
    visible: boolean;
}

export const StartableContractList: React.FunctionComponent<IProps> = ({
    onSubmit,
    registerOnBackButtonClick,
    navigateToContractInputForm,
    visible,
}) => {
    const [selectedChoiceID, setSelectedChoiceID] = useState<string | null>(null);
    const [regionFilter, setRegionFilter] = useState<string>();
    const [salesTypeFilter, setSalesTypeFilter] = useState<SalesType>();
    const [choices, setChoices] = useState<IChoice[]>();
    const [choiceHistory, setChoiceHistory] = useState<(string | null)[]>([null]);

    const startableContracts = useSelector(getStartableContracts);
    const useChoiceFiltering = choices && choices.length > 0;

    const navigateBack = () => {
        if (!useChoiceFiltering) {
            if (salesTypeFilter != undefined) {
                setSalesTypeFilter(undefined);
                const availableSalesTypes: SalesType[] = uniq(
                    startableContracts
                        .filter(
                            (contract) =>
                                regionFilter === undefined || contract.region === regionFilter
                        )
                        .map((contract) => contract.umsatzart)
                );
                // skip sales type filtering if there is just one option and return directly to region filter (Bug #10555)
                if (availableSalesTypes.length === 1) {
                    setRegionFilter(undefined);
                }
            } else if (regionFilter != undefined) {
                setRegionFilter(undefined);
            } else {
                navigateToContractInputForm();
            }
        } else {
            if (choiceHistory.length > 1) {
                const newChoiceHistory = choiceHistory;
                newChoiceHistory.pop();
                setSelectedChoiceID(newChoiceHistory[newChoiceHistory.length - 1]);
                setChoiceHistory(newChoiceHistory);
            } else {
                navigateToContractInputForm();
            }
        }
    };

    useEffect(() => {
        (async () => {
            setChoices(
                await apiManager().dataService.getListAsync<IChoice[]>('choice', 'ActiveChoices')
            );
        })();
    }, []);

    useEffect(() => {
        visible && registerOnBackButtonClick(navigateBack);
    }, [choices, choiceHistory, regionFilter, salesTypeFilter, visible]);

    if (!visible) {
        return <></>;
    }

    if (!choices) {
        return <div>Auswahlliste wird geladen...</div>;
    }

    if (!useChoiceFiltering) {
        const filteredContracts = startableContracts
            .filter((contract) => regionFilter === undefined || contract.region === regionFilter)
            .filter(
                (contract) =>
                    salesTypeFilter === undefined || contract.umsatzart === salesTypeFilter
            );

        if (regionFilter === undefined) {
            return <RegionFilter contracts={filteredContracts} selectRegion={setRegionFilter} />;
        }

        if (salesTypeFilter === undefined) {
            return (
                <SalesTypeFilter
                    contracts={filteredContracts}
                    selectSalesType={setSalesTypeFilter}
                />
            );
        }

        return <List contracts={filteredContracts} onSubmit={onSubmit} />;
    } else {
        const filteredChoices = choices.filter(
            (choice) => choice.isActive && choice.parent === selectedChoiceID
        );
        if (filteredChoices.length > 0) {
            const selectChoiceID = (id: string) => {
                setChoiceHistory([...choiceHistory, id]);
                setSelectedChoiceID(id);
            };

            return <ChoiceFilter choices={filteredChoices} selectChoiceID={selectChoiceID} />;
        }

        const filteredContracts = selectedChoiceID != null && startableContracts.filter(
            (contract) => contract.choice === selectedChoiceID
        ) || [];

        return <List contracts={filteredContracts} onSubmit={onSubmit} />;
    }
};
