import { TToken } from '../../../../core/api/QuinoServer';

export type LoginAction =
    | LogInBegin
    | LogInSuccess
    | LogInError
    | LogOut
    | SetLoginToken
    | Unauthorized;

export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export type LOGIN_BEGIN = typeof LOGIN_BEGIN;
export type LogInBegin = {
    type: LOGIN_BEGIN;
};

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export type LOGIN_SUCCESS = typeof LOGIN_SUCCESS;
export type LogInSuccess = {
    type: LOGIN_SUCCESS;
    currentUserName: string;
};

export const LOGIN_ERROR = 'LOGIN_ERROR';
export type LOGIN_ERROR = typeof LOGIN_ERROR;
export type LogInError = {
    type: LOGIN_ERROR;
    errorMessage: string;
};

export const LOGOUT = 'LOGOUT';
export type LOGOUT = typeof LOGOUT;
export type LogOut = {
    type: LOGOUT;
};

export const SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN';
export type SET_LOGIN_TOKEN = typeof SET_LOGIN_TOKEN;
export type SetLoginToken = {
    type: SET_LOGIN_TOKEN;
    loginToken?: TToken;
};

export const UNAUTHORIZED = 'UNAUTHORIZED';
export type UNAUTHORIZED = typeof UNAUTHORIZED;
export type Unauthorized = {
    type: UNAUTHORIZED;
};
