import * as React from 'react';
import { useEffect } from 'react';
import { getFinishContractService } from '../../FinishContractPage/redux/logic';

export const FinishContractPage: React.FunctionComponent = () => {
    useEffect(() => {
        getFinishContractService().finishContractAsync();
    }, []);

    return null;
};
