import { IContractDetailDTO } from '../../entities';
import { inject, injectable } from 'inversify';
import { IBarcodeParserResult } from './IBarcodeParserResult';
import APLIX_SERVICE_IDENTIFIER from '../../../ioc_constants';
import { IBarcodeStrategyRunner } from './BarcodeStrategyRunner';

export interface IBarcodeParser {
    parse(contractDetails: IContractDetailDTO[], barcode: string): IBarcodeParserResult | null;
}

@injectable()
export class BarcodeParser implements IBarcodeParser {
    constructor(
        @inject(APLIX_SERVICE_IDENTIFIER.IBARCODESTRATEGYRUNNER)
        private strategyRunner: IBarcodeStrategyRunner
    ) {}

    public parse(contractDetails: IContractDetailDTO[], barcode = ''): IBarcodeParserResult | null {
        if (!barcode) {
            return null;
        }

        for (const detail of contractDetails) {
            const result = this.checkDetail(detail, barcode);
            if (result) {
                return result;
            }
        }

        return null;
    }

    private checkDetail(
        contractDetail: IContractDetailDTO,
        barcode: string
    ): IBarcodeParserResult | null {
        const finalAmounts =
            (contractDetail.barcodes &&
                contractDetail.barcodes
                    .map((barcodeDTO) =>
                        this.strategyRunner.run(
                            barcode,
                            contractDetail,
                            barcodeDTO.barcode,
                            barcodeDTO.faktor
                        )
                    )
                    .find((amounts) => amounts != null)) ||
            this.strategyRunner.run(
                barcode,
                contractDetail,
                contractDetail.barcode001,
                contractDetail.faktor001
            ) ||
            this.strategyRunner.run(
                barcode,
                contractDetail,
                contractDetail.barcode002,
                contractDetail.faktor002
            ) ||
            this.strategyRunner.run(
                barcode,
                contractDetail,
                contractDetail.barcode003,
                contractDetail.faktor003
            ) ||
            this.strategyRunner.run(
                barcode,
                contractDetail,
                contractDetail.nummer,
                contractDetail.faktor001
            ) ||
            null;

        if (!finalAmounts) {
            return null;
        }

        return {
            ...finalAmounts,
            contractDetail,
        };
    }
}
