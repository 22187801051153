import { inject, injectable } from 'inversify';
import APLIX_SERVICE_IDENTIFIER from '../../ioc_constants';
import { IManifestDownloader } from './IManifestDownloader';
import { IUpdater } from './IUpdater';
import { Store } from 'redux';
import { IState } from '../../redux';
import { ClientVersion, IAplixUrlManager } from '../index';
import { updateAvailable } from '../../redux/globals/actions';

@injectable()
export class Updater implements IUpdater {
    constructor(
        @inject(APLIX_SERVICE_IDENTIFIER.IMANIFESTDOWNLOADER)
        private manifestDownloader: IManifestDownloader,
        @inject(APLIX_SERVICE_IDENTIFIER.STORE) private store: Store<IState>,
        @inject(APLIX_SERVICE_IDENTIFIER.IAPLIXURLMANAGER) private aplixUrlManager: IAplixUrlManager
    ) {}

    public async checkForUpdateAsync(): Promise<boolean> {
        const manifest = await this.manifestDownloader.downloadAsync();
        if (manifest && manifest.version) {
            const currentVersion = ClientVersion;
            if (currentVersion !== manifest.version) {
                this.store.dispatch(updateAvailable(currentVersion, manifest.version));
                return Promise.resolve(true);
            }
        }

        return Promise.resolve(false);
    }

    public async checkAndExecuteUpdateAsync(): Promise<void> {
        if (await this.checkForUpdateAsync()) {
            this.executeUpdate();
        }
    }

    public executeUpdate() {
        const reloadUrl = this.aplixUrlManager.getReloadUrl();
        window.location.assign(reloadUrl);
    }
}
