import * as React from 'react';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContractErrorMessage } from '../../../redux/contracts';
import { getFirstSaveDetailError } from '../../../redux/contracts/saveDetailState';
import { AplixErrorDialog } from '../../../components';
import { acceptError } from '../../../redux/contracts/actions';
import { PrepareContractContext } from '../PrepareContractContext';

export const PrepareContractErrors: React.FunctionComponent = () => {
    const { reload } = useContext(PrepareContractContext);

    const contractErrorMessage = useSelector(getContractErrorMessage);
    const saveDetailError = useSelector(getFirstSaveDetailError);

    const dispatch = useDispatch();

    if (contractErrorMessage) {
        const closeContractError = () => {
            dispatch(acceptError());
        };

        return (
            <AplixErrorDialog
                key={'contractError'}
                errorMessage={contractErrorMessage}
                onClose={closeContractError}
                title={'Fehler'}
            />
        );
    }

    if (saveDetailError) {
        const primaryKey = saveDetailError.primaryKey;

        return (
            <AplixErrorDialog
                key={primaryKey}
                errorMessage={saveDetailError.errorMessage}
                onClose={reload}
                title={'Fehler'}
            />
        );
    }

    return null;
};
