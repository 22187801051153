import * as React from 'react';
import { UIEventHandler, useState } from 'react';
import Table from '@material-ui/core/Table';
import { ListBody, ListHeader, NoDetails } from './components';
import { IContract } from '../../core/entities';
import {
    ContractTableContext,
    IAdditionalContractTableColumn,
    IContractTableContext,
} from './ContractTableContext';

type TStyles = {
    contractTableRoot: string;
};

const styles: TStyles = require('./ContractTable.less');

export interface IProps {
    contracts: IContract[];
    selectedContract?: IContract | null;
    onRowClick?: (contract: IContract) => void;
    additionalColumns?: IAdditionalContractTableColumn[];
    emptyLabel?: string;
}

export const ContractTable: React.FunctionComponent<IProps> = ({
    contracts,
    selectedContract,
    ...props
}) => {
    const contextValue: IContractTableContext = {
        selectedContract: selectedContract || null,
        onRowClick: props.onRowClick || (() => null),
        additionalColumns: props.additionalColumns || [],
        emptyLabel: props.emptyLabel || 'Keine Aufträge gefunden',
    };

    const hasDetails = contracts && contracts.length > 0;

    const [limit, setLimit] = useState(
        selectedContract ? contracts.indexOf(selectedContract) + 50 : 50
    );

    const onScroll: UIEventHandler<HTMLTableSectionElement> = (event) => {
        const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
        const distanceToBottom = scrollHeight - scrollTop - clientHeight;

        if (distanceToBottom < 3 * clientHeight && limit < contracts.length) {
            setLimit((limit) => limit + 50);
        }
    };

    return (
        <ContractTableContext.Provider value={contextValue}>
            <div className={styles.contractTableRoot} onScroll={onScroll}>
                {!hasDetails && <NoDetails />}
                {hasDetails && (
                    <Table>
                        <ListHeader />
                        <ListBody contracts={contracts.slice(0, limit)} />
                    </Table>
                )}
            </div>
        </ContractTableContext.Provider>
    );
};
